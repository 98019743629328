<p-dialog [(visible)]="showUpdateLocationLink" header="Update Location" modal="true" draggable="false" styleClass="app-copy-link-modal-dialog">
    <ng-template pTemplate="header">
        <div class="app-copy-link-modal-dialog-header">
            <h2 class="text-xl font-bold">{{info().header}}</h2>
            <p class="text-gray-600">{{info().subHeader}}</p>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="copy-container">
            <div class="copy-container-header">
                <div class="copy-container-header-title">
                    {{info().copyHeader}}
                </div>
                <div class="copy-container-header-actions">
                    <div class="copy-action" (click)="copyAction()">
                        <i [ngClass]="isCopied() ? 'pi pi-check' : 'pi pi-copy'"></i>
                        <span>{{isCopied() ? 'Copied' : 'Copy Link'}}</span>
                    </div>
                </div>
            </div>
            <div class="copy-container-body">
                <a [href]="info().link" target="_blank">
                    {{info().openLinkText}}
                </a>
            </div>
        </div>
          
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="action-buttons">
            @for(btnConfig of info().actionButtons;track btnConfig.key){
                <p-button [icon]="btnConfig.icon" [label]="btnConfig.label" (click)="emitAction.emit(btnConfig.key)"></p-button>
            }
        </div>
    </ng-template>
</p-dialog>