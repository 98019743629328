import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges } from '@angular/core';
import { MapsService } from '../../../services/maps.service';
import { cloneDeep, forEach } from 'lodash';
import { DateFormatterService } from '../../../services/dateformat';
import { EChartDataType, EChartSeriesUpdate } from '../e-charts/e-charts-data-type';
import moment from 'moment';
import { LangUtilService } from '../../util/lang-util.service';
import { TrackingService } from '../../../services/tracking.service';
import { MenuItem, MessageService } from 'primeng/api';
import { GeoFenceObject, TrackerIcons } from '../../../state/Fleet/models/fleet.models';
import { CommonService } from '../../../services/common.service';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { visualMaps } from '../../../state/dashboard/dashboard/dashboard.helper.service';
import { Actions, Subject } from '../../../auth/rules';
import {
  getPopUpHeader,
  showPopupData
} from '../../../app/delivery-managenment-system/services/common.service';
import { Ability } from '@casl/ability';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SliderModule } from 'primeng/slider';
import 'l.movemarker'
import { isMobileViewSelector } from '../../../state/Assets/assets.selector';
import { ConsignmentEventGeofenceGraphService } from '../../../app/delivery-managenment-system/services/consignment-event-geofence-graph.service';


declare let $: any;

@Component({
  selector: 'app-prime-map-journey-modal',
  templateUrl: './prime-map-journey-modal.html',
  styleUrls: ['./prime-map-journey-modal.scss'],
  providers: [MessageService, ConfirmPopupModule, SliderModule],
})
export class PrimeMapJourneyModal implements OnInit, OnChanges, OnDestroy {

  @Input() displayModal = true;
  @Output() sendModalClosed: EventEmitter<any> = new EventEmitter();
  @Output() sendSideBarClosedEvent: EventEmitter<any> = new EventEmitter();
  @Input() modalBodyData: any = {};
  @Input() routesDetails: any = null;
  @Input() eventsToPlot: any = [];
  eventsToPlotCopy: any = [];
  @Input() mapMarkers: any = [];
  deliveryTempModal = false;
  @Input() header
  @Input() loaderForSidebarGraph: boolean
  @Input() liveDataFromSocket: any = {};
  @Input() rowData: any;
  @Input() agentDetails: any;
  @Input() isRightSidebarClosed: boolean;
  @Input() reloadMapNumber: number;
  @Input() dataViewType: any;
  @Input() displayPills = true;
  @Input() pageName = '';
  @Input() selectedOrderDeliveryGroup: any;
  @Input() carGpsHistoricalData: any
  @Input() geoFences: GeoFenceObject[];
  @Input() trackIcons: TrackerIcons;


  @Output() activeRouteChanged: EventEmitter<any> = new EventEmitter();
  @Output() routeLegendChanged: EventEmitter<any> = new EventEmitter();
  @Output() sendChartExpansionData: EventEmitter<any> = new EventEmitter();

  @Output() sendLatLngValues: EventEmitter<ILocationPayload> = new EventEmitter();
  @Output() emittGeoFenceRadiusPayload: EventEmitter<ILocationPayload> = new EventEmitter();
  @Output() emittchangeNonComplianceDialogVisibilityNumber: EventEmitter<any> = new EventEmitter();


  ACTIONS = Actions;
  SUBJECT = Subject;

  chartModalOpen = false;
  moduleName: string = null;


  public varyingEChartGraphData: EChartDataType = {
    series: [],
    legend: [],
    type: 'line',
    title: 'Stats',
    xAxis: [],
    visualMap: visualMaps,
    isDataZoomRequired: true
  };

  mapToView: any;

 
  public selectedMap: any;
  public selectedZoom: number;
  public selectedLatitude: number;
  public selectedLongitude: number;
  public center: any;
  public defaultMapDetails: any;
  public defaultPanelSize: Array<number> = [50, 50];
  routes = [];
  legendList: RouteLegend[] = [
    {
      'label': 'Actual Path',
      'color': '#00A3E3',
      'isActive': true,
      'type': 'actual'
    },
    {
      'label': 'Expected Path',
      'color': '#E34000',
      'isActive': false,
      'type': 'expected'
    }
  ];
  legendListCopy: RouteLegend[] = [];
  startMarkerLocation;
 

  _liveInfoTab = {
    isFirstTimeAgentDataReceived: true,
    tabLabel: 'Live',
    isGraphDisplayReq: false
  };

  agentRouteArray = [];
  newDataForAgentPolylineCount: number;

  eventMarkersArray = [];
  actionMessage: string;

  allDeliveryMarkers = [];

  allDeliveryLocMarkers = {};

  userMapOptionsSub$: Subscription;

  routeArray = [];
  test = 'test var';

  public boxStats = {
    show: false,
    showLoader: true,
  };

  enableLocationUpdate: boolean;
  enableRoutePoints = signal<boolean>(false);
  showPlayActualPanel = signal<boolean>(false);
  actualPathMarkers = [];
  showLoader = false;
  showGeofences = false;
  showEvents = false;
  eventsMarkers = {}
  geoFencesById = {}
  items: MenuItem[] | undefined;
  isMobileView$ = this.store.pipe(select(isMobileViewSelector));




  constructor(public mapsService: MapsService, public dateFormatter: DateFormatterService, private lang: LangUtilService,
    public trackingService: TrackingService,
    private messageService: MessageService,
    private commonService: CommonService, private ability: Ability, private store: Store,
    private consignmentEventGeofenceGraphService:ConsignmentEventGeofenceGraphService
  ) {
    this.legendListCopy = cloneDeep(this.legendList)
  }


  ngOnInit(): void {
    this.provideLocationUpdateAccess()
 
    this.items = [
      {
        icon: 'play'
      },
      {
        icon: 'geofenceAndEventsList'
      },
      {
        icon: 'pointsOnRouteToggleButon'
      },
    ];
    if (this.accessLocationUpdate) {
      this.items.push(
        {
          icon: 'locationUpdateToggleButton'
        }
      )
    }
  }

  eventCardClick(event) {
    this.focusedEvent = event
    if (!this.showEvents) {
      this.plotEvents(this.eventsToPlotCopy);
      this.showEvents = true
    }
   
    setTimeout(() => {
      this.eventsMarkers[event?._id]?.openPopup()
    }, 500);
  }

  geofenceCardClick(geofence: GeoFenceObject) {
    this.focusedGeoFence = geofence
    this.showGeofences = true

  }

  focusedGeoFence: GeoFenceObject
  focusedEvent
  searchGeoFence: string
  searchEvent: string


  showGeofencesList: boolean
  showEventsList: boolean
  showListSearchBar: boolean

  showEventsListData(): void {
    if (!this.showEventsList) {
      this.showEventsList = true
      this.showGeofencesList = false
      this.focusedEvent = null
    } else {
      this.showEventsList = false
    }
  }

  onPlayPathDropDownClick(op) {
    this.showPlayActualPanel.set(!this.showPlayActualPanel())
    op.toggle(event);
    this.showGeofencesList = false
    this.showEventsList = false
  }

  showGeofencesListData(): void {
    if (!this.showGeofencesList) {
      this.showGeofencesList = true;
      this.showEventsList = false;
      this.focusedGeoFence = null;
    } else {
      this.showGeofencesList = false
    }
  }



  ngOnChanges(changes: SimpleChanges) {
    try {
      for (const propName of Object.keys(changes)) {
        const change = changes[propName];

    
        if (propName === 'reloadMapNumber') {
          if (change.currentValue !== change.previousValue) {
            this.messageService.clear();
            setTimeout(() => {
              this.messageService.clear();
            }, 600);
          }
        }
        if (propName === 'loaderForSidebarGraph') {
          if (change.currentValue !== change.previousValue) {
            this.boxStats.showLoader = this.loaderForSidebarGraph
          }
        }
        if (propName === 'routesDetails') {
          if (change.currentValue !== change.previousValue) {
            this.defaultPanelSize = [70, 30];
            this.removeLayer();
      
          }
        }
        if (propName === 'liveDataFromSocket') {
          if (change.currentValue !== change.previousValue) {
            
            if (this.liveDataFromSocket && this.liveDataFromSocket.sensorData) {
              this.plotSocketDataGraph(this.liveDataFromSocket);
            }
          }
        }
        if (propName === 'agentDetails') {
          if (change.currentValue !== change.previousValue) {
            this.legendList = cloneDeep(this.legendListCopy);
            this.resetLegendActiveVaues();
            this.resetAgentRouteVariables();
            if (this.agentDetails && this.pageName === 'progress' || this.pageName === 'mmd-consignment-group') {
          
            }

          }
        }
        if (propName === 'rowData') {
          if (change.currentValue !== change.previousValue) {
            if (this.rowData) {
              this.legendList = cloneDeep(this.legendListCopy)
              this.moduleName = this.rowData?.moduleType;
              this.defaultPanelSize = [50, 50];
              this.boxStats.show = true;
              this.varyingEChartGraphData = this.consignmentEventGeofenceGraphService.buildGraph(this.rowData?.assetObj?.deliveryPerson?._id,this.carGpsHistoricalData,this.moduleName);
              this.resetLegendActiveVaues();
            }
          }
        }
        if (propName === 'carGpsHistoricalData') {
          if (change.currentValue !== change.previousValue) {
            if (this.carGpsHistoricalData?.length) {
              this.boxStats.show = true;
              this.varyingEChartGraphData = this.consignmentEventGeofenceGraphService.buildGraph(this.rowData?.assetObj?.deliveryPerson?._id,this.carGpsHistoricalData,this.moduleName);
            }
          }
        }
  
        if (propName === 'isRightSidebarClosed') {
          if (change.currentValue !== change.previousValue) {
            if (!this.isRightSidebarClosed) {
              this.removeLayer();
              this.resetAgentRouteVariables();
              this.removeGeoFences();
              this.showGeofences = false;
            } else {
              this.clearMapStatus.minimizedMap=true
              this.enableLocationUpdate = false;
              this.showEvents = false;
              this.showGeofences = false;
              this.showEventsList = false
              this.showGeofencesList = false;
              this.enableRoutePoints.set(false);
              this.removeGeoFences();


              this.routesDetails = null;
            }
          }
        }

      

        if (propName === 'eventsToPlot') {
          if (change.currentValue && change.currentValue !== change.previousValue) {
            if (this.eventsToPlot && this.eventsToPlot.length > 0) {
              this.eventsToPlotCopy = [...this.eventsToPlot];
              this.showEvents && this.plotEvents(this.eventsToPlot);
              setTimeout(() => {
                this.showEvents && this.removeEvents();
              }, 0);
            } else {
              this.eventsToPlotCopy = [];
              this.removeplottedEvents();
              this.showEvents && this.removeEvents();
            }
          }
        }

      }
    } catch (e) {
      console.error(e);
    }
  }

 

  public geoFenceFormDialog: boolean;
  public geoFenceRadius: number
  public accessLocationUpdate: boolean
 

  provideLocationUpdateAccess() {
    if ((this.ability.can(Actions.UPDATE_LOCATION, Subject.MMD_MANAGEMENT))) {
      this.accessLocationUpdate = true;
    } else {
      this.accessLocationUpdate = false;
    }
  }






  removeGeoFences() {
    this.showGeofences = !this.showGeofences;
    this.showGeofencesList = false
    this.showEventsList = false
  
  }

  removeEvents() {
    this.showEvents = !this.showEvents;
    if (this.showEvents) {
      this.plotEvents(this.eventsToPlotCopy);
    } else {
      this.removeplottedEvents();
    }
  }

  onModalClose() {
    this.displayModal = false;
    this.showGeofences = false;
    this.showEvents = false;
    this.sendModalClosed.emit(true);
    this.removeLayer();
    this.resetAgentRouteVariables();
  }

  

  setActiveRoute(type) {
  
    this.dataViewType = type;
  }

  

  foucusedLatLng
  focusedAssetId: string


  setAgentLastLocTooltup(agentDetail) {
    const subheader = this.dateFormatterToText(agentDetail.locationLastUpdated)
    return getPopUpHeader('Name : ', agentDetail.name || '', (subheader || '')) + showPopupData([]);
  }

  resetAgentRouteVariables() {
    this.agentRouteArray = [];
    this.newDataForAgentPolylineCount = 0;
  }

 

  camelCaseFormatter(name) {
    const text = name;
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }



  dateFormator(date, format?) {
    let dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
    if (format) {
      if (format === 'date') {
        dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
      } else if (format === 'time') {
        dateFormate = moment(date).format('h:mm:ss a');
      } else if (format === 'lll') {
        dateFormate = moment(date).format('LLL');
      }
    }
    return dateFormate;
  }

  plotSocketDataGraph(result) {
    if (result.generatedTimeStamp) {

      const update: EChartSeriesUpdate = {
        index: null,
        label: null,
        value: null,
        labelIndex: 0,
        values: []
      };

      update.label = (this.dateFormator(result.generatedTimeStamp, 'lll'));
      result.sensorData.temperature || result.sensorData.temperature == 0 ? update.values.push((result.sensorData.temperature).toFixed(2)) : update.values.push(null);
      result.sensorData.humidity || result.sensorData.humidity == 0 ? update.values.push((result.sensorData.humidity).toFixed(2)) : update.values.push(null);
      this.graphUpdateValue = cloneDeep(update);

    }
  }



  public graphUpdateValue: EChartSeriesUpdate = {
    index: null,
    label: null,
    value: null,
    labelIndex: 0,
    values: []
  };

 




  sendDataToPlotMarker(_) { }




  rightSideBarModalClicked() {
    this.varyingEChartGraphData = cloneDeep(this.varyingEChartGraphData);
    this.boxStats = cloneDeep(this.boxStats);
    const modalOptions = {
      eChartGraphData: this.varyingEChartGraphData,
      boxStats: this.boxStats
    };
    this.sendChartExpansionData.emit({
      showModal: true,
      modalOptions: modalOptions
    });
  }

  onDialogHide() {
   
    this.showEvents && this.plotEvents(this.eventsToPlotCopy);
    !this.showEvents && this.removeplottedEvents();
    this.routeLegendChanged.emit(this.legendList);
    this.clearMapStatus.maximizedMap = true;
    this.clearMapStatus.minimizedMap = false;

  }
  onDialogShow() {
    this.showEvents && this.plotEvents(this.eventsToPlotCopy);
    !this.showEvents && this.removeplottedEvents();
    this.routeLegendChanged.emit(this.legendList);
    this.clearMapStatus.maximizedMap = false;
    this.clearMapStatus.minimizedMap = true;
 
  }

  getDateConvertor(date) {
    let cDate = this.dateFormatter.convertDateToSelectedTimezone(date, 'DD/MM/YY');
    return cDate
  }







  legendClicked(l) {
    l.isActive = !l.isActive;
    this.routeLegendChanged.emit(this.legendList);
  }

  

  showInfo(msg) {
    this.messageService?.clear();
    this.messageService.add({ severity: 'info', summary: 'Info', detail: msg });
  }

  dragEnd() {
    this.reloadMapNumber = Math.random();
  }

  dateFormatterToText(date) {
    this.lang.updateMomentLanguage(moment);
    return moment(date).calendar();
  }


  resetLegendActiveVaues() {
    const isAgent = this.agentDetails ? true : false
    if (isAgent) {
      forEach(this.legendList, (leg) => {
        if (leg.type === 'actual') {
          leg.isActive = true;
        } else {
          leg.isActive = false;
        }
      });
    } else {
      this.legendList.splice(0, 1);
      this.legendList[0].isActive = true;
    }

  }

  plotEvents(events) {
    forEach(events, (eventObj) => {
      this.bindEventIconForTableData(eventObj);
    });
  }

  bindEventIconForTableData(data) {
    data['eventIcon'] = this.commonService.getIconsForEvents({ name: data.name, status: data.status });
  }

  removeplottedEvents() {
    for (let id in (this.eventsMarkers || {})) {
      this.eventsMarkers[id]?.remove()
    }
    this.eventsMarkers = {};
  }

  private removeLayer() {
    forEach(this.routes, r => {
      r?.route?.remove();
    });

    this.allDeliveryMarkers.forEach(markerObj => {
      markerObj.remove();
    });
    this.allDeliveryMarkers = [];
    this.allDeliveryLocMarkers = {};

    this.routes = [];
  }

  

  addEventListenerForPopup() {
    document.querySelector(".reject-popup-btn")?.addEventListener("click", () => {
      this.popupAction('reject')
    });
    document.querySelector(".accept-popup-btn")?.addEventListener("click", () => {
      this.popupAction('accept')
    });
  }

  popupAction(action) {
    if (action === 'accept') {
      this.sendLatLngValues.emit({ lat: this.foucusedLatLng?.lat, lng: this.foucusedLatLng?.lng, assetId: this.focusedAssetId });
    }
  }

  


 
 

  ngOnDestroy() {
    this.userMapOptionsSub$?.unsubscribe();
  }



  findEarliestTimestamp(obj: Record<string, string>): { key: string; timestamp: string } {
    let earliestKey: string | null = null;
    let earliestTimestamp: Date | null = null;

    for (const [key, value] of Object.entries(obj)) {
      const currentTimestamp = new Date(value);

      // Check if the date is valid
      if (isNaN(currentTimestamp.getTime())) {
        console.error(`Invalid timestamp: '${value}' for key '${key}'`);
        continue;
      }

      if (earliestTimestamp === null || currentTimestamp < earliestTimestamp) {
        earliestTimestamp = currentTimestamp;
        earliestKey = key;
      }
    }

    if (earliestTimestamp && earliestKey) {
      return { key: earliestKey, timestamp: earliestTimestamp.toISOString() };
    }

    return null; // Return null if no valid timestamps found
  }


  onRoadLogisticsOptions = [
    { name: 'All', value: '' },
    { name: 'Forward Logistics', value: 'forwardLogistics' },
    { name: 'Reverse Logistics', value: 'reverseLogistics' },
  ];
  selectedOnRoadLogistic = null;

  rightActionButtonsForMaximizedView = [
    { icon: 'pi pi-window-minimize', action: 'minimize', tooltip: 'Minimize' },
  ]

  rightActionButtonsForMinimizedView = [
    { icon: 'pi pi-th-large', action: 'non-compliance', tooltip: 'Non-compliance Events' },
    { icon: 'pi pi-window-maximize', action: 'maximize', tooltip: 'Maximize' },
  ]

  clearMapStatus = {
    maximizedMap: false,
    minimizedMap: false
  }

  recieveRightButtonsAction(action) {
    if (action === 'maximize') {
      this.deliveryTempModal = true
      this.clearMapStatus.maximizedMap = false;
      this.clearMapStatus.minimizedMap = true
    } else if (action === 'minimize') {
      this.deliveryTempModal = false
      this.clearMapStatus.maximizedMap = true;
      this.clearMapStatus.minimizedMap = false
    } else if (action === 'non-compliance') {
      this.clearMapStatus.maximizedMap = true;
      this.clearMapStatus.minimizedMap = true
      this.emittchangeNonComplianceDialogVisibilityNumber.emit(
        Math.random()
      )
    }
  }



}



export interface RouteLegend {
  'label': string,
  'color': string,
  'isActive': boolean,
  'type': string
}

export interface ILocationPayload {
  lat: number;
  lng: number;
  assetId: string;
  geoFenceRadius?: number;
  label?: String;
}

export interface IComplianceEvent {
  subType: string;
  _id: string;
  coordinates: Array<number>;
  generatedTimeStamp: string;
  sensorData: {
    temperature: number
  }
}