import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateFormatterService } from './dateformat';
import { ConfigService } from './config.service';
import { CONSTANT } from '../config/constant';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private dateFormatterService: DateFormatterService,
    private http: HttpClient,
    private configService: ConfigService
  ) { }
  getSchedulsDateByType(schedules, key: string, cities, locations, rplans, vehicles, clients, scheduleFor: TScheduleFor) {
    if (key === 'all') {
      return schedules.map(sc => this.formatSchedulesData(sc, cities, locations, rplans, vehicles, clients, scheduleFor))
    }
    if (key === CONSTANT.PLANNER.VEHICLE_TODAY_ACTIVITY) {
      return schedules.map(sc => this.formatSchedulesData(sc, cities, locations, rplans, vehicles, clients, scheduleFor))
    }
    if (key === CONSTANT.PLANNER.VEHICLE_MAINTENANCE_ACTIVITY) {
      return schedules.map(sc => this.formatSchedulesData(sc, cities, locations, rplans, vehicles, clients, scheduleFor))
    } if (key === CONSTANT.PLANNER.VEHICLE_OVERALL_ACTIVITY) {
      return schedules.map(sc => this.formatSchedulesData(sc, cities, locations, rplans, vehicles, clients, scheduleFor))
    }
    if (key === 'today') {
      const todaySchedules = [];
      (schedules || []).forEach(schedulesWithDate => {
        const formattedDate = schedulesWithDate.formattedDate + ` (${this.dateFormatterService.getDateComponents(schedulesWithDate['date'])?.['dayOfWeek']})`;
        (schedulesWithDate?.scheduleData || []).forEach(sch => {
          todaySchedules.push({
            ...sch,
            formattedDate,
            startDateForPause: schedulesWithDate['date'],
            endDateForPause: schedulesWithDate['date'],
            combinedCount: schedulesWithDate.count,
            upcomingScheduledTimeLabel: schedulesWithDate.formattedDate + ' - ' + this.dateFormatterService.convertTo12HourFormat(sch.scheduledTime)
          })
        })
      })
      return todaySchedules.map(sc => this.formatSchedulesData(sc, cities, locations, rplans, vehicles, clients, scheduleFor))
    }
    if (key === CONSTANT.PLANNER.CONFLICTING_SCHEDULES_BY_VEHICLE) {
      const todaySchedules = [];
      (schedules || []).forEach(schedulesWithDate => {
        // const formattedDate = schedulesWithDate.formattedDate + ` (${this.dateFormatterService.getDateComponents(schedulesWithDate['date'])?.['dayOfWeek']})`;
        (schedulesWithDate?.data || []).forEach(sch => {
          todaySchedules.push({
            ...(sch.scheduleData[0] || {}),
            // formattedDate,
            startDateForPause: schedulesWithDate['date'],
            endDateForPause: schedulesWithDate['date'],
            combinedCount: schedulesWithDate.count,
            upcomingScheduledTimeLabel: schedulesWithDate.formattedDate + ' - ' + this.dateFormatterService.convertTo12HourFormat(sch.scheduledTime)
          })
        })
      })
      return todaySchedules.map(sc => this.formatSchedulesData(sc, cities, locations, rplans, vehicles, clients, scheduleFor))
    }
  }


  formatSchedulesData(schedule, citiesList, locationsList, routePlansList, vehicles, clients, scheduleFor: TScheduleFor) {
    const startDateLabel = schedule.startDate ? this.getDateConvertor(schedule.startDate) : ''
    const endDateLabel = schedule.scheduledDate ? '' : (schedule.endDate && (schedule.endDate !== 'Until Cancel')) ? this.getDateConvertor(schedule.endDate) : 'Until Cancel'

    const recurringDateRange = startDateLabel + ' - ' + endDateLabel;
    const monthLabel = months[(schedule.scheduledMonth || schedule.month) - 1];
    const scheduleTypeLabel = (schedule.scheduleType !== CONSTANT.PLANNER.RECURRING && scheduleFor === CONSTANT.PLANNER.VEHICLE) ? (`One Time ${monthLabel ? ('(' + monthLabel + ')') : ''}`) :
      (schedule.scheduleType !== CONSTANT.PLANNER.RECURRING) ? 'One Time' :
        (this.toTitleCase(schedule.scheduleType) + ` (${this.toTitleCase(schedule?.recurringType)})`)

    const scheduledDateLabel = schedule.scheduledDate ? (this.getDateConvertor(schedule.scheduledDate) + ' - ' + this.dateFormatterService.convertTo12HourFormat(schedule.scheduledTime)) : this.dateFormatterService.convertTo12HourFormat(schedule.scheduledTime);
    const vehicleLabels = (scheduleFor === CONSTANT.PLANNER.VEHICLE) ? vehicles.filter(v => (schedule.vehicleIds || []).includes(v._id)).map(({ name }) => name).join(', ') :
      scheduleFor === CONSTANT.PLANNER.CONSIGNMENT ? vehicles.find(c => c._id === schedule.agentId)?.name :
        (
          scheduleFor === CONSTANT.PLANNER.VEHICLE_ACTIVITY ||
          scheduleFor === CONSTANT.PLANNER.VEHICLE_MAINTENANCE_ACTIVITY ||
          scheduleFor === CONSTANT.PLANNER.VEHICLE_OVERALL_ACTIVITY

        ) ? vehicles.find(c => c._id === schedule.vehicleId)?.name :
          ''


    return ({
      ...schedule,
      scheduleType: (schedule.scheduleType === 'single') ? 'One Time' : schedule.scheduleType,
      recurringType: (schedule.scheduleType === CONSTANT.PLANNER.RECURRING) ? (schedule?.recurringType) : '',
      servicesTypeLabel: schedule.servicesType,

      recurringDateRange: (schedule.scheduleType !== CONSTANT.PLANNER.RECURRING && scheduleFor === CONSTANT.PLANNER.VEHICLE) ? '' : recurringDateRange,
      recurringTimeRange:
        this.dateFormatterService.convertTo12HourFormat(schedule.startTime) + ' - ' +
        this.dateFormatterService.convertTo12HourFormat(schedule.endTime),
      scheduleTypeLabel: scheduleTypeLabel,
      scheduledDateLabel: scheduledDateLabel,
      scheduledTimeLabel: this.dateFormatterService.convertTo12HourFormat(schedule.scheduledTime),

      startDate: schedule.startDate ? this.dateFormatterService.convertDateToSelectedTimezone(schedule.startDate) : '',

      created: schedule.created ? (this.isISOFormat(schedule.created) ? this.getDateConvertor(schedule.created, true) : schedule.created) : '',

      endDate: schedule.scheduledDate ? '' : (schedule.endDate && (schedule.endDate !== 'Until Cancel')) ? this.dateFormatterService.convertDateToSelectedTimezone(schedule.endDate, 'll') : '',

      scheduledDate: schedule.scheduledDate ? this.dateFormatterService.convertDateToSelectedTimezone(schedule.scheduledDate) : '',
      activityTypeLabel: schedule.activityType && this.toTitleCase(schedule.activityType),

      pickupCity: citiesList.find(city => city._id === schedule.pickupCityId)?.city || '',
      pickupLocation: locationsList.find(location => location._id === schedule.pickupLocationId)?.name || '',
      routePlans: this.getRoutePlansLabels(schedule.routePlanIds, routePlansList) || schedule.routePlans,
      carrier: vehicleLabels,
      client: clients.find(c => c._id === schedule.clientId)?.name || '',
      editAsset: 'Edit',
      status: {
        header: schedule.isExpired ? 'Expired' : schedule.isPaused ? 'Paused' : 'Active',
        subheader: schedule.isExpired ? '' : schedule.isPaused ?
          ((this.getDateConvertor(schedule.pauseStartDate) || '-')
            + ' - ' + (this.getDateConvertor(schedule.pauseEndDate) || 'Until Cancel')) : ''
      },
      actionMethods: this.getActionMethods(schedule.isPaused, schedule.isExpired),

      daysLabels:
        (scheduleFor === CONSTANT.PLANNER.VEHICLE && schedule.scheduleType?.startsWith('single')) ? schedule.dates :
          schedule?.recurringType === 'monthly' ?
            schedule.dates : schedule?.recurringType === 'weekly' ? schedule.days : []
    })
  }

  getDateConvertor(date, showTime = false) {
    let cDate = this.dateFormatterService.convertDateToSelectedTimezone(date, 'DD/MM/YY');
    if (showTime)
      cDate = cDate + ',' + ' ' + this.dateFormatterService.getFormattedTimeSlotForSingleTime(date, 'LTS');
    return cDate
  }

  getActionMethods(isPaused: boolean, isExpired: boolean) {
    const actions = ['Edit', 'Delete']
    !isExpired && actions.push(isPaused ? 'Resume' : 'Pause')
    return actions
  }

  getRoutePlansLabels(routePlanIds: string[], routePlansList) {
    const categories = {}
    routePlansList.forEach(rPlan => {
      if (routePlanIds?.includes(rPlan._id)) {
        categories[rPlan.category] = categories[rPlan.category] ? (categories[rPlan.category] + ',' + rPlan.label) : rPlan.label
      }
    })
    let label = '';
    for (const cat in categories) {
      label += cat + ': ' + categories[cat] + ';'
    }
    return label
  }

  isISOFormat = (dateString) => {
    const parsedDate = Date.parse(dateString);
    return !isNaN(parsedDate) && dateString === new Date(parsedDate).toISOString();
  };

  toTitleCase(str: string) {
    const words = str.toLowerCase().split(' ');
    const titleCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);

    });
    return titleCaseWords?.join(' ') || '';
  }

  addNewScheduleForDelivery(schedule) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule', schedule);
  }

  addNewScheduleForVehicle(schedule) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'fleet/vehicleActivityPlanning/planning', schedule);
  }

  addNewActivityForVehicle(activity) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'fleet/vehicleActivity', activity);
  }

  editDeliverySchedule(scheduleId: string, schedule) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule/id/' + scheduleId, schedule);
  }

  editVehicleSchedule(scheduleId: string, schedule) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'fleet/vehicleActivityPlanning/planning/id/' + scheduleId, schedule);
  }

  deleteDeliverySchedule(scheduleId: string) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule/id/' + scheduleId);
  }

  deleteVehicleSchedule(scheduleId: string) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'fleet/vehicleActivityPlanning/planning/id/' + scheduleId);
  }

  pauseDeliverySchedule(scheduleIds: string, duration, pauseUpcoming = false) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule/pause/id/' + scheduleIds + `?pauseUpcoming=${pauseUpcoming}`, duration);
  }
  unPauseDeliverySchedule(scheduleId: string) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/schedule/unpause/id/' + scheduleId, {});
  }

  unPauseVehicleSchedule(scheduleId: string) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'fleet/vehicleActivityPlanning/planning/unPause/id/' + scheduleId, {});
  }


  pauseVehicleSchedule(scheduleIds: string, duration, pauseUpcoming = false) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'fleet/vehicleActivityPlanning/planning/pause/id/' + scheduleIds + `?pauseUpcoming=${pauseUpcoming}`, duration);
  }

  getAvailableVehicles(startDate, endDate, groupBy: string, queries) {
    let url = this.configService.appConfig.appBaseUrl
      + `fleet/vehicleActivityPlanning/planning/startDate/${startDate.valueOf()}/endDate/${endDate.valueOf()}?groupBy=${groupBy}`
    for (const qry in queries) {
      if (queries[qry] || typeof queries[qry] === 'boolean') {
        url = `${url}&${qry}=${queries[qry]}`;
      }
    }
    return this.http.get(url);
  }
}


const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export type TScheduleFor =
  | 'vehicle'
  | 'consignment'
  | 'vehicle-activity'
  | 'vehicle-maintenance-activity'
  | 'vehicle-maintenance-and-assignment-activity';