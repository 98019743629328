import { Component, input } from '@angular/core';

@Component({
  selector: 'app-multiple-values-in-table',
  standalone: true,
  imports: [],
  templateUrl: './multiple-values-in-table.component.html',
  styleUrl: './multiple-values-in-table.component.scss'
})
export class MultipleValuesInTableComponent {
  content = input<any>(null);
}
