import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, output, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copy-link-modal',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    CommonModule
  ],
  templateUrl: './copy-link-modal.component.html',
  styleUrl: './copy-link-modal.component.scss'
})
export class CopyLinkModalComponent {

  clipboard = inject(Clipboard)

  info = input<Record<string,any>>({});
  triggerVisibilityNumber = input<number>(0);

  emitAction = output<string>();

  showUpdateLocationLink = signal<boolean>(false);
  isCopied = signal<boolean>(false);


  constructor() {
    effect(() => {
      if (this.triggerVisibilityNumber() > 0 && this.info().link) {
        this.showUpdateLocationLink.set(true);
        this.isCopied.set(false)
      }
    }, { allowSignalWrites: true });
  }

  copyAction() {
    this.clipboard.copy(this.info().link);
    this.isCopied.set(!this.isCopied());
  }

}
