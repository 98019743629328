<div class="route-bar flex p-2">
  <div class="route-bar-breadcrumb">
    <i class="pi pi-home"></i>
    <span> / </span>
    @for (item of (items$ | async); track item?.label; let last = $last) {
      @if (item.routerLink) {
        <a [routerLink]="item.routerLink">{{ item.label }}</a>
      }
      @if (!item.routerLink) {
        {{ item.label }}
      }
      @if (!last) {
        <span> / </span>
      }
    }
    @if(isDisplayHelpDeskIcon){
      <i class="pi pi-question" style="color: blue; margin-left: 10px; cursor: pointer;font-size: 14px;" (click)="openSidebar()"></i>
    }
    <!-- <p-button icon="pi pi-user" [rounded]="true" [text]="true" severity="info " (click)="openSidebar()"></p-button> -->
  </div>

  @if ((name$ | async); as name) {
    <p class="store-name">{{ name }}</p>
  }

  <ul class="route-bar-menu">
    <li class="search-item">
      @if (showSearchBox$ | async) {
        <span class="searchBoxGrid">
          <app-search-box [resetSearchTextToSearchBox]="resetSearchTextToHeader">
          </app-search-box>
        </span>
      }
    </li>
    <!-- <li>
    <a (click)="showDialog()" onclick="return false;" href="#" id="sidebar-profile-button" pTooltip="Help" tooltipPosition="bottom">
      <i class="pi pi-question-circle" ></i>
    </a>

  </li> -->
  </ul>
</div>

<p-dialog header="Support" [modal]="true" [(visible)]="visible" [style]="{width: '65vw'}">
  <div class="grid fields-group-grid mt-3">

    <div  class="py-3 col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText type="text" [(ngModel)]="support.name" id="name" class="w-full" />
        <label for="name">Name</label>
      </span>
    </div>

    <div  class="py-3 col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText type="text" [(ngModel)]="support.email" id="email" class="w-full" />
        <label for="email">Email</label>
      </span>
    </div>

    <div  class="py-3 col-12 md:col-6">
      <span class="p-float-label">
        <p-inputNumber [(ngModel)]="support.phone" mode="decimal" inputId="phNumber" class="w-full" [useGrouping]="false"> </p-inputNumber>
        <label for="phNumber">Phone Number</label>
      </span>
    </div>

    <div  class="py-3 col-12 md:col-6">
      <span class="p-float-label">
        <p-cascadeSelect [(ngModel)]="support.type" (onChange)="setSubject()" [options]="supportTypes"
                         optionLabel="oname" optionGroupLabel="name"
                         [optionGroupChildren]="['options']" [style]="{ width: '100%' }"
        placeholder="Select a City"></p-cascadeSelect>
        <label for="cs-city">Support Type</label>
      </span>
    </div>

    <div  class="py-3 col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText type="text" [(ngModel)]="support.subject" id="subject" class="w-full" />
        <label for="subject">Subject</label>
      </span>
    </div>

    <div  class="py-3 col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText type="text" [(ngModel)]="support.url" id="url" class="w-full" disabled="true" />
        <label for="url">URL</label>
      </span>
    </div>

  </div>

  <p-editor [(ngModel)]="support.content" [style]="{ height: '320px' }"></p-editor>

  <p-button  class="FR mt-3" (onClick)="submitFeedback()" [disabled]="submitDisable">
    {{'sys.submit' | translate}}
  </p-button>
</p-dialog>

<p-sidebar class="info-prime-sidebar" position="right" [dismissible]="false" (onShow)="isSidebarClosed = false"
  (onHide)="closeBtnClicked()" [fullScreen]="(sidebarIsFullScreen$ | async)" [(visible)]="isCurrentIdActive">
  <ng-template pTemplate="header">
    <div class="custom-modal-header">
      <h5 class="custom-modal-title uppercase">Help Desk</h5>
    </div>
  </ng-template>
  <div class="custom-modal create-modal" [ngStyle]="{'display':'block'}" [ngClass]="{'custom-modal-view':true}"
    [id]="sidebarId">
    <div class="custom-modal-content">
      <div class="custom-modal-body" id="markdown" [innerHTML]="getHtmlContent()">
      </div>
    </div>
  </div>
</p-sidebar>


@if (showLoader) {
  <div>
    <app-loader></app-loader>
  </div>
}
