import { createSelector } from "@ngrx/store";
import { AppState } from "../../app.state";
import {DashboardState} from "./dashboard.reducer";

export const dashboard = (state : AppState) => state.dashboard
export const getO2dOrderStats = createSelector(
    dashboard,
    (state)=> state.o2d.orderStateData
);
export const o2dDashboardGraph = createSelector(
    dashboard,
    (state)=>{
        let dataObj = {}
        if(state.o2d.o2dGraphs){
        Object.entries(state?.o2d?.o2dGraphs).forEach(([key, value]) => {
            if (key.includes('_dash')) {
              dataObj[key] = {...value}
            }
        });
        }
        return dataObj
    }
);
export const o2dAnalyticsGraph = createSelector(
    dashboard,
    (state)=>{
        let dataObj = {}
        if(state.o2d.o2dGraphs){
        Object.entries(state?.o2d?.o2dGraphs).forEach(([key, value]) => {
            if (key.includes('_analytics')) {
                dataObj[key] = {...value}
              }
        });
          }
          return dataObj
      }
  );
// export const o2dOrderByTimeSlot = createSelector(
//     dashboard,
//     (state)=> state.o2d.o2dDashboardGraph
// );
// export const o2dOrderByAssignStatus = createSelector(
//     dashboard,
//     (state)=> state.o2d.o2dDashboardGraph
// );
// export const o2dOrderByDeliveryStatus = createSelector(
//     dashboard,
//     (state)=> state.o2d.o2dDashboardGraph
// );
// export const o2dOrderByQueueStatus = createSelector(
//     dashboard,
//     (state)=> state.o2d.o2dDashboardGraph
// );
// export const o2dOrderCountInQueueStatus = createSelector(
//     dashboard,
//     (state)=> state.o2d.o2dQueueCount
// );
// export const o2dOrderDeliveryPersonInQueue = createSelector(
//     dashboard,
//     (state)=> state.o2d.o2dDashboardGraph
// );
// export const o2dOrderRiderWisePerformance = createSelector(
//     dashboard,
//     (state)=> state.o2d.o2dDashboardGraph
// );
// export const o2dTatOfOrder = createSelector(
//     dashboard,
//     (state)=> state.o2d.o2dDashboardGraph
// );
// export const o2dDeliveryTemperature = createSelector(
//     dashboard,
//     (state)=> state.o2d.o2dDashboardGraph
// );
//mmd-dashboard-selector
export const getMmdOrderStats = createSelector(
    dashboard,
    (state)=> state.mmd.orderStateData
);
export const showGraphLoader = createSelector(
  dashboard,
  (state)=> state.showLoader
);
export const getLmdOrderStats = createSelector(
    dashboard,
    (state)=> state.lmd.orderStateData
)
export const mmdDashboardGraph = createSelector(
    dashboard,
    (state)=>{
        let dataObj = {}
        if(state.mmd.mmdGraphs){
        Object.entries(state?.mmd?.mmdGraphs).forEach(([key, value]) => {
            if (key.includes('_dash')) {
              dataObj[key] = {...value}
            }
        });
        }
        return dataObj
    }
);
export const lmdDashboardGraph = createSelector(
    dashboard,
    (state)=>{
        let dataObj = {}
        if(state.lmd.lmdGraphs){
        Object.entries(state?.lmd?.lmdGraphs).forEach(([key, value]) => {
            if (key.includes('_dash')) {
              dataObj[key] = {...value}
            }
        });
        }
        return dataObj
    }
);
//wwarehouse dashboard
export const warehouseDashboardZoneList = createSelector(
    dashboard,
    (state)=> state.warehouseDashboard
)
//warehouse analytics graph
export const warehouseAnalyticsGraph = createSelector<AppState, DashboardState, any>(
  dashboard,
  (state)=> {
    let dataObj = {}
      if(state.warehouseDashboard.warehouseGraphs){
      Object.entries(state?.warehouseDashboard?.warehouseGraphs).forEach(([key, value]) => {
          if (key.includes('_analytics')) {
              dataObj[key] = value;
            }
      });
        }
        return dataObj
  }
);
export const getKioskCountCards = createSelector(
    dashboard,
    (state)=> state.kiosk.countCards
);
export const kioskDashboardGraph = createSelector(
    dashboard,
    (state)=> {
      let dataObj = {}
        if(state.kiosk.kioskGraphs){
        Object.entries(state?.kiosk?.kioskGraphs).forEach(([key, value]) => {
            if (key.includes('_dash')) {
                dataObj[key] = {...value}
              }
        });
          }
          return dataObj
    }
);
export const kioskAnalyticsGraphs = createSelector(
  dashboard,
  (state)=> {
    let dataObj = {}
        if(state.kiosk.kioskGraphs){
        Object.entries(state?.kiosk?.kioskGraphs).forEach(([key, value]) => {
            if (key.includes('_analytics')) {
              dataObj[key] = {...value}
            }
        });
        }
        return dataObj
  }
)
export const mmdAnalyticsGraph = createSelector(
    dashboard,
    (state)=> {
        let dataObj = {}
        if(state.mmd.mmdGraphs){
        Object.entries(state?.mmd?.mmdGraphs).forEach(([key, value]) => {
            if (key.includes('_analytics')) {
              dataObj[key] = {...value}
            }
        });
        }
        return dataObj
    }
);
export const lmdAnalyticsGraph = createSelector(
    dashboard,
    (state)=> {
        let dataObj = {}
        if(state.lmd.lmdGraphs){
        Object.entries(state?.lmd?.lmdGraphs).forEach(([key, value]) => {
            if (key.includes('_analytics')) {
              dataObj[key] = {...value}
            }
        });
        }
        return dataObj
    }
);

export const mmdDeliveredAnalytics = createSelector(
       dashboard,
       (state)=> state.mmdDeliveryAnalytics
 );

export const o2dOverviewAnalytics = createSelector(
  dashboard,
  (state)=> state.o2dOverviewAnalytics
);

export const o2dOverviewAnalyticsDataForPagination = createSelector(
  dashboard,
  (state)=> state.o2dOverviewAnalyticsPaginatedData
);

export const o2dOverviewAnalyticsDataCount = createSelector(
  dashboard,
  (state)=> state.o2dOverviewAnalyticsDataCount
);

export const dmsVehicleAnalyticsGraph = createSelector(
  dashboard,
  (state)=> state.dmsVehicleAnalytics
);

export const dmsLogisticsCostAnalytics = createSelector(
  dashboard,
  (state)=> state.dmsLogisticsCostAnalytics
);

export const dmsLogisticsCostAnalyticsDataCount = createSelector(
  dashboard,
  (state)=> state.dmsLogisticsCostAnalyticsDataCount
);

export const dmsLogisticsCostCardResponse = createSelector(
  dashboard,
  (state)=> state.dmsLogisticsCostCardResponse
);