import {AppMainComponent} from '../../../app/core/main/app.main.component';
import {Component, OnInit,} from '@angular/core';
import {Actions, Subject} from '../../../auth/rules';
import {Ability} from '@casl/ability';
import {Store} from '@ngrx/store';
import {LangUtilService} from '../../../app/util/lang-util.service';
import {TranslateService} from '@ngx-translate/core';
import {ProfileSelectors} from '../../../state/Profile';
import {CONSTANT} from '../../../config/constant';
import { getLogisticProviderLinkedCities, loadAssetCountByType, } from '../../../state/Assets/assets.action';
import {assetLogisticProviderLabelSelector,} from '../../../state/Assets/assets.selector';
import {ILogisticProvider} from '../../../state/Assets/interfaces/IAsset.types.modal';
import {loadMarkerIcons} from '../../../state/Fleet/fleet.action';
import {SocketActions} from '../../../state/Socket';
import { IAssetStatusSocketData } from '../../../state/Socket/interfaces/custome.interfaces';


@Component({
  selector: 'app-menu',
  template: `
    <ul class="layout-menu">
      @for (item of model; track item; let i = $index) {
        <li
          app-menuitem
          [item]="item"
          [index]="i"
          [root]="true"
        ></li>
      }
      @if (this.app.isHorizontal()) {
        @for (item of lower; track item; let i = $index) {
          <li
            app-menuitem
            [item]="item"
            [index]="i"
            [root]="true"
            style="float: right;"
          ></li>
        }
      }
    </ul>
    `,
  styleUrls: ['./app.menu.component.scss']
})
export class AppMenuComponent implements OnInit {
  model: any[];
  lower: any[];
  assetTypes = [];
  MMD_LP_NAME = CONSTANT.DMS_MODULES.MMD.UNIQUE_ID;
  LMD_LP_NAME = CONSTANT.DMS_MODULES.LMD.UNIQUE_ID;


  constructor(
    public app: AppMainComponent,
    private ability: Ability,
    private store: Store,
    private lang: LangUtilService,
    private translate: TranslateService,
  ) {
    if (sessionStorage.getItem('token')) {
      const accountDetails = sessionStorage.getItem('account') ? JSON.parse(sessionStorage.getItem('account')) : null;
      if (accountDetails && accountDetails?.['assetTypes']) {

        this.assetTypes = accountDetails['assetTypes'];
      }
    }
  }

  ngOnInit() {
    let id;
    this.store
      .select(ProfileSelectors.getUserLanguage)
      .subscribe((details: { language: string }) => {
        const userLanguage = details?.language ?? 'en';
        console.log('getUserLanguage', details);
        this.translate.reloadLang(userLanguage).subscribe(r => {
          console.log('UserLanguage reloaded', r)
          if (r?._id && (id !== r?._id)) {
            id = r?._id;
            this.buildSideMenu();
          }
        });
      });

      if (sessionStorage.getItem('token')) {
        const accountDetails = sessionStorage.getItem('account') ? JSON.parse(sessionStorage.getItem('account')) :  null;
        if (accountDetails?._id) {
          this.store.dispatch(SocketActions.updateSocketDataRequired({
            nameSpace: `${CONSTANT.ASSET_TYPES.SOCKET.NAMESPACE.UPDATE_ASSET_STATE}/${accountDetails['_id']}`,
            topicName: CONSTANT.ASSET_TYPES.SOCKET.TOPICS.LIVE,
            key: 'asset_status',
            callback: this.updateAssetStatus
          }));
        }
      }

  }

  updateAssetStatus = (res: IAssetStatusSocketData) => {
    if (res?.assetType === 'category') {
      return;
    }
    if (res?.event === 'deleted' || res?.event === 'added' || res?.assetType) {
      this.store.dispatch(SocketActions.assetStatusChange({ res: res }));
      this.store.dispatch(loadAssetCountByType({ assetType: res['assetType'] }));
    }
  }

  buildSideMenu() {
    this.model = [];
    this.lower = [];

    // ---------------discover--------------
    if (this.ability.can(Actions.READ, Subject.DISCOVER)) {
      const menuObj = {
        label: this.getLangLabel('app.sidemenu.discover.discover'),
        icon: 'custom-icon  custom-icon-img  icon-color discover-icon',
        routerLink: ['/nearable'],
        title: this.getLangLabel('app.sidemenu.discover.discover'),

      };
      this.model.push(menuObj);
    }

    //fleet
    if (this.isTrackerListRequired()) {
      const menuObj = {
        label: this.getLangLabel('app.sidemenu.fleet.fleet'),
        icon: 'custom-icon custom-icon-img fleet-icon icon-color',
        title: this.getLangLabel('app.sidemenu.fleet.fleet'),
        items: [],
        routerLink: ['/fleet'],
      };

      if (this.ability.can(Actions.LIVE, Subject.TRACKER)) {
  
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.track'),
          routerLink: ['fleet/track'],
          icon: 'custom-icon pi pi-fw pi-map-marker',
          title: this.getLangLabel('app.sidemenu.track'),
        };
        menuObj.items.push(nestedObj);
      }

      /* if (this.ability.can(Actions.READ, Subject.GEOFENCE)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.geo-fence'),
          routerLink: ['fleet/geoFence'],
          icon: 'custom-icon fa fa-dot-circle',
          title: this.getLangLabel('app.sidemenu.geo-fence'),
        };
        menuObj.items.push(nestedObj);
      } */

      if (this.assetTypes.includes('car') && (this.ability.can(Actions.READ, Subject.ASSET))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.fleet.vehicle'),
          routerLink: ['fleet/car'],
          icon: 'custom-icon custom-icon-img car-icon-solid',
          title: this.getLangLabel('app.sidemenu.fleet.vehicle'),
        };
        menuObj.items.push(nestedObj);
      }
      if (this.assetTypes.includes('driver') && (this.ability.can(Actions.READ, Subject.ASSET))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.fleet.driver'),
          routerLink: ['fleet/driver'],
          icon: 'custom-icon  custom-icon-img  icon-color fleet-driver-icon',
          title: this.getLangLabel('app.sidemenu.fleet.driver'),
        };
        menuObj.items.push(nestedObj);
      }
      if (this.ability.can(Actions.ANALYTICS, Subject.TRACKER)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.analytics.analytics'),
          routerLink: ['fleet/gpsAnalytics'],
          icon: 'pi pi-fw pi-chart-line',
          title: this.getLangLabel('app.sidemenu.analytics.analytics'),
          preventExact : true,
        };
        menuObj.items.push(nestedObj);
      }
      if (this.ability.can(Actions.VEHICLE_ACTIVITY, Subject.TRACKER)) {
        const nestedObj1 = {
          label: 'Activity',
          routerLink: ['fleet/activityLog'],
          icon: 'custom-icon icon-color custom-icon-img fleet-maintenance-icon',
          title: 'Activity',
        };
        menuObj.items.push(nestedObj1);
      }
      if (this.ability.can(Actions.READ, Subject.TRACKER_MAINTENANCE)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.fleet.maintenance'),
          routerLink: ['fleet/maintenanceLog'],
          icon: 'custom-icon icon-color custom-icon-img fleet-maintenance-icon',
          title: this.getLangLabel('app.sidemenu.fleet.maintenance'),
        };
        menuObj.items.push(nestedObj);
      }
      if (this.ability.can(Actions.VEHICLE_PLAN, Subject.TRACKER)) {
        const nestedObj = {
          label: 'Vehicle Plan',
          routerLink: ['fleet/vehiclePlan'],
          icon: 'custom-icon pi pi-calendar',
          title: 'Vehicle Plan',
          preventExact: true
        };
        menuObj.items.push(nestedObj);
      }
      if (this.ability.can(Actions.REPORTS, Subject.TRACKER)) {
        const nestedObj = {
          label: 'Reports',
          icon: 'custom-icon pi pi-file-excel',
          title: 'Reports',
          routerLink: ['fleet/reports'],
        };

        menuObj.items.push(nestedObj);
      }
      if (this.ability.can(Actions.SETTINGS, Subject.TRACKER)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.settings'),
          routerLink: ['fleet/tracker/settings/notification'],
          icon: 'custom-icon pi pi-fw pi-cog',
          title: this.getLangLabel('app.sidemenu.security.settings'),
        };
        menuObj.items.push(nestedObj);
      }

     

      this.model.push(menuObj);

    }


    // //fleet
    // if(this.isTrackerListRequired()){
    //   let menuObj= {
    //     label: this.getLangLabel('app.sidemenu.fleet.fleet'),
    //     icon: 'custom-icon custom-icon-img fleet-icon icon-color',
    //     title: this.getLangLabel('app.sidemenu.fleet.fleet'),
    //     items: [],
    //     routerLink:['/new-fleet'],
    //   };

    //   if(this.ability.can(Actions.LIVE, Subject.TRACKER)){
    //     let nestedObj = {
    //       label: this.getLangLabel('app.sidemenu.track'),
    //       routerLink: ['new-fleet/new-track'],
    //       icon: 'pi pi-fw pi-map-marker',
    //       title: this.getLangLabel('app.sidemenu.track'),
    //     }
    //     menuObj.items.push(nestedObj)
    //   }

    //   if(this.ability.can(Actions.READ, Subject.GEOFENCE)){
    //     let nestedObj = {
    //       label: this.getLangLabel('app.sidemenu.geo-fence'),
    //       routerLink: ['new-fleet/analytics'],
    //       icon: 'custom-icon fa fa-dot-circle',
    //       title: this.getLangLabel('app.sidemenu.geo-fence'),
    //     }
    //     menuObj.items.push(nestedObj)
    //   }

    //   this.model.push(menuObj)
    // }

    // ----------Inspection Bay----------
    if (this.isInspectionBayListRequired()) {
      const menuObj = {
        label: this.getLangLabel('app.sidemenu.inspection.inspection-bay'),
        icon: 'custom-icon inspection-icon icon-color custom-icon-img',
        title: this.getLangLabel('app.sidemenu.inspection.inspection-bay'),
        routerLink: ['/inspection'],
        items: []
      };
      if ((this.ability.can(Actions.LIVE, Subject.INSPECTION))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.active'),
          routerLink: ['/inspection/inspectionBay'],
          icon: 'custom-icon custom-icon-img car-icon-solid',
          title: this.getLangLabel('app.sidemenu.active'),
        };
        menuObj.items.push(nestedObj);
      }

      if ((this.ability.can(Actions.READ, Subject.INSPECTION))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.report'),
          routerLink: ['/inspection/report'],
          icon: 'custom-icon pi pi-file-excel',
          title: this.getLangLabel('app.sidemenu.report'),
        };
        menuObj.items.push(nestedObj);
      }

      if ((this.ability.can(Actions.ANALYTICS, Subject.INSPECTION))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.analytics.analytics'),
          routerLink: ['/inspection/analytics'],
          icon: 'custom-icon pi pi-fw pi-chart-line',
          title: this.getLangLabel('app.sidemenu.analytics.analytics'),
        };
        menuObj.items.push(nestedObj);
      }
      this.model.push(menuObj);
    }

    //---------------security-------------
    if (this.isSecurityListRequired()) {
      const menuObj = {
        label: this.getLangLabel('app.sidemenu.security.security'),
        icon: 'custom-icon icon-color custom-icon-img shield-alt-solid',
        items: [],
        routerLink: ['/security'],
        title: this.getLangLabel('app.sidemenu.security.security'),
      };

      if ((this.ability.can(Actions.READ, Subject.AUTO_REGISTER))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.faces'),
          routerLink: ['/security/autoRegisteredFace'],
          icon: 'custom-icon icon-color custom-icon-img security-faces-icon',
          title: this.getLangLabel('app.sidemenu.security.registered-faces'),
        };
        menuObj.items.push(nestedObj);
      }
      if ((this.ability.can(Actions.READ, Subject.FACE_MATCH))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.face-match'),
          routerLink: ['/security/faceMatch'],
          icon: 'custom-icon icon-color custom-icon-img security-face-match-icon',
          title: this.getLangLabel('app.sidemenu.security.face-match'),
        };
        menuObj.items.push(nestedObj);
      }
      if ((this.ability.can(Actions.READ, Subject.FACE_MATCH))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.face-detected'),
          routerLink: ['/security/faceDetected'],
          icon: 'custom-icon icon-color custom-icon-img security-face-detected-icon',
          title: this.getLangLabel('app.sidemenu.security.face-detected'),
        };
        menuObj.items.push(nestedObj);
      }
      if ((this.ability.can(Actions.LIVE, Subject.SECURITY))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.camera-wall'),
          routerLink: ['/security/cameraWall'],
          icon: 'custom-icon icon-color custom-icon-img security-camera-wall-icon',
          title: this.getLangLabel('app.sidemenu.security.camera-wall'),
        };
        menuObj.items.push(nestedObj);
      }
      if ((this.ability.can(Actions.ANALYTICS, Subject.SECURITY))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.analytics'),
          routerLink: ['/security/analytics/faceMatch'],
          icon: 'custom-icon pi pi-fw pi-chart-line',
          title: this.getLangLabel('app.sidemenu.security.analytics'),
        };
        menuObj.items.push(nestedObj);
      }
      if ((this.ability.can(Actions.WRITE, Subject.SECURITY))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.settings'),
          routerLink: ['/security/settings/notification'],
          icon: 'custom-icon pi pi-fw pi-cog',
          title: this.getLangLabel('app.sidemenu.security.settings'),
        };
        menuObj.items.push(nestedObj);
      }

      this.model.push(menuObj);

    }

    // ------------attendance---------
    if (this.isAttendanceListRequired()) {

      const menuObj = {
        label: this.getLangLabel('app.attendance.attendance'),
        icon: 'custom-icon custom-icon-img address-card-solid',
        items: [],
        routerLink: ['/attendance'],
        title: this.getLangLabel('app.attendance.attendance'),
      };

      if ((this.ability.can(Actions.READ, Subject.ATTENDANCE))) {

        const nestedObj = {
          label: this.getLangLabel('app.role.dashboard'),
          routerLink: ['/attendance/dashboard'],
          icon: 'custom-icon custom-icon-img dashboard-icon',
          title: this.getLangLabel('app.role.dashboard'),
        };

        menuObj.items.push(nestedObj);

      }

      if ((this.ability.can(Actions.READ, Subject.ATTENDANCE))) {
        const nestedObj = {
          label: this.getLangLabel('app.attendance.daily'),
          routerLink: ['/attendance/daily'],
          icon: 'custom-icon icon-color custom-icon-img icon-color custom-icon-img-img progress-icon',
          title: this.getLangLabel('app.attendance.daily'),
        };
        menuObj.items.push(nestedObj);
      }

      if (this.assetTypes.includes('person') && (this.ability.can(Actions.READ, Subject.ASSET))) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.employee'),
          routerLink: ['/attendance/person'],
          icon: 'custom-icon icon-color custom-icon-img attendance-employee-icon',
          title: this.getLangLabel('app.sidemenu.employee'),

        };
        menuObj.items.push(nestedObj);
      }

      if (this.ability.can(Actions.READ, Subject.ATTENDANCE)) {
        const nestedObj = {
          label: this.getLangLabel('app.attendance.visitors'),
          routerLink: ['/attendance/visitors'],
          icon: 'custom-icon icon-color custom-icon-img attendance-visitors-icon',
          title: this.getLangLabel('app.attendance.visitors'),
        };
        menuObj.items.push(nestedObj);
      }

      if (this.ability.can(Actions.READ, Subject.FACE_MATCH)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.face-detected'),
          routerLink: ['/attendance/faceDetected'],
          icon: 'custom-icon icon-color custom-icon-img security-face-detected-icon',
          title: this.getLangLabel('app.sidemenu.security.face-detected'),
        };
        menuObj.items.push(nestedObj);
      }
      if (this.ability.can(Actions.LIVE, Subject.ATTENDANCE)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.camera-wall'),
          routerLink: ['/attendance/cameraWall'],
          icon: 'custom-icon icon-color custom-icon-img security-camera-wall-icon',
          title: this.getLangLabel('app.sidemenu.security.camera-wall'),
        };
        menuObj.items.push(nestedObj);
      }

      if (this.ability.can(Actions.ANALYTICS, Subject.ATTENDANCE)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.analytics.analytics'),
          routerLink: ['/attendance/analytics/overview'],
          icon: 'custom-icon pi pi-fw pi-chart-line',
          title: this.getLangLabel('app.sidemenu.analytics.analytics'),
        };
        menuObj.items.push(nestedObj);
      }

      if (this.ability.can(Actions.WRITE, Subject.ATTENDANCE)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.security.settings'),
          routerLink: ['/attendance/settings/notification'],
          icon: 'custom-icon pi pi-fw pi-cog',
          title: this.getLangLabel('app.sidemenu.security.settings'),
        };
        menuObj.items.push(nestedObj);
      }

      this.model.push(menuObj);
    }

    // ------------key management-----------
    if (this.isKeyManagementListRequired()) {
      const menuObj = {
        label: this.getLangLabel('app.sidemenu.key-management.key-management'),
        icon: 'custom-icon icon-color custom-icon-img key-icon',
        items: [],
        routerLink: ['/digitalKey'],
        title: this.getLangLabel('app.sidemenu.key-management.key-management'),
      };

      if (this.ability.can(Actions.READ, Subject.KEY_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.key-management.dashboard'),
          routerLink: ['/digitalKey/dashboard'],
          icon: 'custom-icon custom-icon-img dashboard-icon',
          title: this.getLangLabel('app.sidemenu.key-management.dashboard'),
        };
        menuObj.items.push(nestedObj);
      }

      if (this.assetTypes.includes('car') && this.ability.can(Actions.READ, Subject.ASSET)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.fleet.vehicle'),
          routerLink: ['/digitalKey/car'],
          icon: 'custom-icon icon-color custom-icon-img car-icon-solid',
          title: this.getLangLabel('app.sidemenu.fleet.vehicle'),
        };
        menuObj.items.push(nestedObj);
      }
      if (this.assetTypes.includes('driver') && this.ability.can(Actions.READ, Subject.ASSET)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.fleet.driver'),
          routerLink: ['/digitalKey/driver'],
          icon: 'custom-icon icon-color custom-icon-img fleet-driver-icon',
          title: this.getLangLabel('app.sidemenu.fleet.driver'),
        };
        menuObj.items.push(nestedObj);
      }

      if (this.ability.can(Actions.READ, Subject.KEY_MANAGEMENT_QUEUE)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.key-management.queue'),
          routerLink: ['/digitalKey/queue'],
          icon: 'custom-icon icon-color custom-icon-img fleet-icon',
          title: this.getLangLabel('app.sidemenu.key-management.queue'),
          preventExact: true
        };
        menuObj.items.push(nestedObj);
      }

      if (this.ability.can(Actions.READ, Subject.KEY_MANAGEMENT_ASSIGNMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.key-management.assignment'),
          routerLink: ['/digitalKey/manageKey'],
          icon: 'custom-icon icon-color custom-icon-img fleet-icon',
          title: this.getLangLabel('app.sidemenu.key-management.assignment'),
          preventExact: true
        };
        menuObj.items.push(nestedObj);
      }
      if (this.ability.can(Actions.ANALYTICS, Subject.KEY_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.key-management.analytics'),
          routerLink: ['/digitalKey/analytics'],
          icon: 'custom-icon pi pi-fw pi-chart-line',
          title: this.getLangLabel('app.sidemenu.key-management.analytics'),
          preventExact: true
        };
        menuObj.items.push(nestedObj);
      }
      if (this.ability.can(Actions.SETTINGS, Subject.KEY_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.key-management.settings'),
          routerLink: ['/digitalKey/settings'],
          icon: 'custom-icon pi pi-fw pi-cog',
          title: this.getLangLabel('app.sidemenu.key-management.settings'),
          preventExact: true
        };
        menuObj.items.push(nestedObj);
      }

      this.model.push(menuObj);

    }


    //----------dms main menu------------
    if (
      this.isDeliveryManagementListRequired() ||
      this.isMmdManagementListRequired() ||
      this.isLmdManagementListRequired()
    ) {
      const mainMenuObj = {
        label: this.getLangLabel('app.sidemenu.dms.dms'),
        icon: 'custom-icon icon-color custom-icon-img dms-icon',
        title: this.getLangLabel('app.sidemenu.dms.dms-abb'),
        routerLink: ['/'],
        items: [],
      };
      // ---------------MMD--------------
      if (this.isMmdManagementListRequired()) {
        this.loadLPCities(this.MMD_LP_NAME);

        this.store.dispatch(loadMarkerIcons({
             moduleType: CONSTANT.DMS_MODULES.MMD.MODULE,
             assetType: CONSTANT.ASSET_TYPES.VEHICLE
        }));

        const menuObj = {
          label: this.getLangLabel('app.sidemenu.mmd.mmd'),
          icon: 'custom-icon icon-color custom-icon-img dms-icon',
          title: this.getLangLabel('app.sidemenu.mmd.mmd-abb'),
          routerLink: ['/MMD'],
          items: [],
        };

        if (this.ability.can(Actions.ANALYTICS, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.dashboard'),
            icon: 'custom-icon custom-icon-img dashboard-icon',
            title: this.getLangLabel('app.sidemenu.mmd.dashboard'),
            routerLink: ['MMD/dashboard'],
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.LIVE, Subject.MMD_MANAGEMENT)) {
          // this.store.dispatch(loadMarkerIcons({
          //   moduleType: CONSTANT.DMS_MODULES.MMD.MODULE,
          //   assetType: CONSTANT.ASSET_TYPES.VEHICLE
          // }));
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.track'),
            icon: 'custom-icon  pi pi-map-marker',
            title: this.getLangLabel('app.sidemenu.mmd.track'),
            routerLink: ['MMD/track'],
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.CONSIGNMENT, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.consignment'),
            icon: 'custom-icon icon-color custom-icon-img order-icon',
            title: this.getLangLabel('app.sidemenu.mmd.consignment'),
            routerLink: ['MMD/consignment'],
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.ORDER, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.order'),
            icon: 'custom-icon icon-color custom-icon-img order-icon',
            title: this.getLangLabel('app.sidemenu.mmd.order'),
            routerLink: ['MMD/order'],
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.QUEUE, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.queue'),
            icon: 'custom-icon icon-color custom-icon-img queue-icon',
            title: this.getLangLabel('app.sidemenu.mmd.queue'),
            routerLink: ['MMD/queue/vehicle'],
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.ASSIGN_TAB, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.assign'),
            icon: 'custom-icon icon-color custom-icon-img assign-icon',
            title: this.getLangLabel('app.sidemenu.mmd.assign'),
            routerLink: ['MMD/consignmentAssignment'],
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.READ, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.progress'),
            icon: 'custom-icon icon-color custom-icon-img progress-icon',
            title: this.getLangLabel('app.sidemenu.mmd.progress'),
            routerLink: ['MMD/consignmentProgress'],
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.READ, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.delivered'),
            icon: 'custom-icon icon-color custom-icon-img progress-icon',
            title: this.getLangLabel('app.sidemenu.mmd.delivered'),
            routerLink: ['MMD/consignmentDelivered'],
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.ANALYTICS, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.analytics'),
            icon: 'custom-icon pi pi-fw pi-chart-line',
            title: this.getLangLabel('app.sidemenu.mmd.analytics'),
            routerLink: ['MMD/analytics'],
            preventExact: true
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.REPORTS, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.dms.reports'),
            icon: 'custom-icon pi pi-file-excel',
            title: this.getLangLabel('app.dms.reports'),
            routerLink: ['MMD/reports'],
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.ANALYTICS, Subject.MMD_LOGISTICS_COST)) {
          const nestedObj = {
            label: this.getLangLabel('app.dms.logisticsCost'),
            icon: 'custom-icon pi pi-money-bill',
            title: this.getLangLabel('app.dms.logisticsCost'),
            routerLink: ['MMD/logisticsCost'],
            preventExact: true
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.READ, Subject.ROUTE_PLAN_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.dms.route-plan.label'),
            icon: 'custom-icon custom-icon-img route-plan-icon',
            title: this.getLangLabel('app.dms.route-plan.label'),
            routerLink: ['MMD/routePlan'],
            preventExact: true
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.CONSIGNMENT, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.mmd.deliveryPlan'),
            icon: 'custom-icon pi pi-calendar',
            title:  this.getLangLabel('app.sidemenu.mmd.deliveryPlan'),
            routerLink: ['MMD/deliveryPlan'],
            preventExact: true
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.SETTINGS, Subject.MMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.settings'),
            icon: 'custom-icon pi pi-fw pi-cog',
            title: this.getLangLabel('app.sidemenu.lmd.settings'),
            routerLink: ['MMD/settings'],
            preventExact: true
          };
          menuObj.items.push(nestedObj);
        }

        mainMenuObj.items.push(menuObj);
      }

      // ---------------LMD--------------
      if (this.isLmdManagementListRequired()) {
        this.loadLPCities(this.LMD_LP_NAME);

        this.store.dispatch(loadMarkerIcons({
           moduleType: CONSTANT.DMS_MODULES.LMD.MODULE,
           assetType: CONSTANT.ASSET_TYPES.DELIVERY_PERSON
        }));

        const menuObj = {
          label: this.getLangLabel('app.sidemenu.lmd.lmd'),
          icon: 'custom-icon icon-color custom-icon-img dms-icon',
          title: this.getLangLabel('app.sidemenu.lmd.lmd-abb'),
          routerLink: ['/LMD'],
          items: [],
        };

        if (this.ability.can(Actions.ANALYTICS, Subject.LMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.dashboard'),
            icon: 'custom-icon custom-icon-img dashboard-icon',
            title: this.getLangLabel('app.sidemenu.lmd.dashboard'),
            routerLink: ['LMD/dashboard'],
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.LIVE, Subject.LMD_MANAGEMENT)) {
          // this.store.dispatch(loadMarkerIcons({
          //   moduleType: CONSTANT.DMS_MODULES.LMD.MODULE,
          //   assetType: CONSTANT.ASSET_TYPES.DELIVERY_PERSON
          // }));
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.track'),
            icon: 'custom-icon  pi pi-map-marker',
            title: this.getLangLabel('app.sidemenu.lmd.track'),
            routerLink: ['LMD/track'],
          };
          menuObj.items.push(nestedObj);
        }

        if (
          this.assetTypes.includes('deliveryPerson') &&
          this.showAgentTabInDmsMenu()
        ) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.delivery-person'),
            icon: 'custom-icon icon-color custom-icon-img delivery-man',
            title: this.getLangLabel('app.sidemenu.lmd.delivery-person'),
            routerLink: ['LMD/deliveryPerson'],
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.ORDER, Subject.LMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.order'),
            icon: 'custom-icon icon-color custom-icon-img order-icon',
            title: this.getLangLabel('app.sidemenu.lmd.order'),
            routerLink: ['LMD/order'],
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.QUEUE, Subject.LMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.queue'),
            icon: 'custom-icon icon-color custom-icon-img queue-icon',
            title: this.getLangLabel('app.sidemenu.lmd.queue'),
            routerLink: ['LMD/orderQueue'],
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.ASSIGN_TAB, Subject.LMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.assign'),
            icon: 'custom-icon icon-color custom-icon-img assign-icon',
            title: this.getLangLabel('app.sidemenu.lmd.assign'),
            routerLink: ['LMD/orderAssignment'],
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.PROGRESS, Subject.LMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.progress'),
            icon: 'custom-icon icon-color custom-icon-img progress-icon',
            title: this.getLangLabel('app.sidemenu.lmd.progress'),
            routerLink: ['LMD/orderManagement'],
          };
          menuObj.items.push(nestedObj);
        }

        if ((this.ability.can(Actions.DELIVERED, Subject.LMD_MANAGEMENT))) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.delivered'),
            routerLink: ['LMD/orderDelivered'],
            icon: 'custom-icon icon-color custom-icon-img progress-icon',
            title: this.getLangLabel('app.sidemenu.lmd.delivered'),
          };
          menuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.ANALYTICS, Subject.LMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.analytics'),
            icon: 'custom-icon pi pi-fw pi-chart-line',
            title: this.getLangLabel('app.sidemenu.lmd.analytics'),
            routerLink: ['LMD/analytics'],
            preventExact: true
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.REPORTS, Subject.LMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.dms.reports'),
            icon: 'custom-icon pi pi-file-excel',
            title: this.getLangLabel('app.dms.reports'),
            routerLink: ['LMD/reports'],
          };
          menuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.SETTINGS, Subject.LMD_MANAGEMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.lmd.settings'),
            icon: 'custom-icon pi pi-fw pi-cog',
            title: this.getLangLabel('app.sidemenu.lmd.settings'),
            routerLink: ['LMD/settings'],
            preventExact: true
          };
          menuObj.items.push(nestedObj);
        }

        mainMenuObj.items.push(menuObj);
      }
      this.model.push(mainMenuObj);
    }

    // ---------------O2D--------------
    if (this.isO2dManagementListRequired()) {
      const o2dMenuObj = {
        label: this.getLangLabel('app.sidemenu.o2d.o2d'),
        icon: 'custom-icon icon-color custom-icon-img o2d-icon',
        title: this.getLangLabel('app.sidemenu.o2d.o2d'),
        routerLink: ['/O2D'],
        items: [],
      };

      if (this.ability.can(Actions.ANALYTICS, Subject.O2D_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.o2d.dashboard'),
          icon: 'custom-icon custom-icon-img dashboard-icon',
          title: this.getLangLabel('app.sidemenu.o2d.dashboard'),
          routerLink: ['O2D/dashboard'],
        };
        o2dMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.ORDER, Subject.O2D_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.o2d.order'),
          icon: 'custom-icon icon-color custom-icon-img o2d-order-icon',
          title: this.getLangLabel('app.sidemenu.o2d.order'),
          routerLink: ['O2D/order'],
        };
        o2dMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.PENDING, Subject.O2D_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.o2d.pending'),
          icon: 'custom-icon icon-color custom-icon-img o2d-pending-icon',
          title: this.getLangLabel('app.sidemenu.o2d.pending'),
          routerLink: ['O2D/pending'],
        };
        o2dMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.PROCESSING, Subject.O2D_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.o2d.processing'),
          icon: 'custom-icon icon-color custom-icon-img o2d-processing-icon',
          title: this.getLangLabel('app.sidemenu.o2d.processing'),
          routerLink: ['O2D/processing'],
        };
        o2dMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.DISPATCH, Subject.O2D_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.o2d.dispatch'),
          icon: 'custom-icon icon-color custom-icon-img o2d-dispatch-icon',
          title: this.getLangLabel('app.sidemenu.o2d.dispatch'),
          routerLink: ['O2D/readyToDelivery'],
        };
        o2dMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.PROGRESS, Subject.O2D_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.o2d.progress'),
          icon: 'custom-icon icon-color custom-icon-img o2d-progress-icon',
          title: this.getLangLabel('app.sidemenu.o2d.progress'),
          routerLink: ['O2D/progress'],
        };
        o2dMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.ANALYTICS, Subject.O2D_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.o2d.analytics'),
          icon: 'custom-icon pi pi-fw pi-chart-line',
          routerLink: ['O2D/analytics'],
          title: this.getLangLabel('app.sidemenu.o2d.analytics'),
          preventExact: true
        };
        o2dMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.ANALYTICS, Subject.O2D_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.dms.reports'),
          icon: 'custom-icon pi pi-fw pi-chart-line',
          routerLink: ['O2D/reports'],
          title: this.getLangLabel('app.dms.reports'),
          preventExact: true
        };
        o2dMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.SETTINGS, Subject.O2D_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.o2d.settings'),
          icon: 'custom-icon pi pi-fw pi-cog',
          routerLink: ['O2D/settings'],
          title: this.getLangLabel('app.sidemenu.o2d.settings '),
          preventExact: true
        };
        o2dMenuObj['items'].push(nestedObj);
      }

      this.model.push(o2dMenuObj);
    }

    if (this.isKioskListRequired()) {
      const kioskMenuObj = {
        label: this.getLangLabel('app.sidemenu.kiosk.kiosk'),
        icon: 'custom-icon icon-color custom-icon-img o2d-icon',
        title: this.getLangLabel('app.sidemenu.kiosk.kiosk'),
        routerLink: ['/KIOSK'],
        items: [],
      };

      if (this.ability.can(Actions.ANALYTICS, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.kiosk.dashboard'),
          icon: 'custom-icon custom-icon-img dashboard-icon',
          title: this.getLangLabel('app.sidemenu.kiosk.dashboard'),
          routerLink: ['KIOSK/dashboard'],
        };

        kioskMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.ORDER, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.kiosk.order'),
          icon: 'custom-icon icon-color custom-icon-img o2d-order-icon',
          title: this.getLangLabel('app.sidemenu.kiosk.order'),
          routerLink: ['KIOSK/order'],
        };
        kioskMenuObj['items'].push(nestedObj);
      }
      

      if (this.ability.can(Actions.ORDER, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('Today'),
          icon: 'custom-icon icon-color custom-icon-img o2d-order-icon',
          title: this.getLangLabel('Today'),
          routerLink: ['KIOSK/today'],
        };
        kioskMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.READ, Subject.GUEST_MANAGEMENT)) {
        const nestedObj = {
          label: 'Guest',
          icon: 'custom-icon  pi pi-users',
          title: 'Guest',
          routerLink: ['KIOSK/guest'],
          routerLinkActiveOptions: { "exact": true }
        };
        kioskMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.ORDER, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('Cds'),
          icon: 'custom-icon icon-color custom-icon-img o2d-order-icon',
          title: this.getLangLabel('Cds'),
          routerLink: ['KIOSK/cds'],
        };
        kioskMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.ORDER, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('Kds'),
          icon: 'custom-icon icon-color custom-icon-img o2d-order-icon',
          title: this.getLangLabel('Kds'),
          routerLink: ['KIOSK/kds'],
        };
        kioskMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.STORE, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.kiosk.store-management'),
          icon: 'custom-icon  pi pi-map-marker',
          title: this.getLangLabel('app.sidemenu.kiosk.store-management'),
          routerLink: ['KIOSK/store/product'],
          routerLinkActiveOptions: { "exact": true }
        };
        kioskMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.MENU, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: 'Menu',
          icon: 'custom-icon  pi pi-box',
          title: 'Menu',
          routerLink: ['KIOSK/menu'],
          routerLinkActiveOptions: { "exact": true }
        };
        kioskMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.READ, Subject.TABLE_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.kiosk.table-management'),
          icon: 'custom-icon  pi pi-money-bill',
          title: this.getLangLabel('app.sidemenu.kiosk.table-management'),
          routerLink: ['KIOSK/table'],
          routerLinkActiveOptions: { "exact": true }
        };
        kioskMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.READ, Subject.ASSET) && this.ability.can(Actions.READ, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.kiosk.global-category'),
          icon: 'custom-icon  pi pi-list',
          title: this.getLangLabel('app.sidemenu.kiosk.global-category'),
          routerLink: ['KIOSK/category'],
        };
        kioskMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.READ, Subject.ASSET) && this.ability.can(Actions.READ, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.kiosk.global-product'),
          icon: 'custom-icon icon-color custom-icon-img o2d-order-icon',
          title: this.getLangLabel('app.sidemenu.kiosk.global-product'),
          routerLink: ['KIOSK/product'],
        };
        kioskMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.ANALYTICS, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.kiosk.analytics'),
          icon: 'custom-icon pi pi-fw pi-chart-line',
          title: this.getLangLabel('app.sidemenu.kiosk.analytics'),
          routerLink: ['KIOSK/analytics'],
          preventExact: true
        };
        kioskMenuObj['items'].push(nestedObj);
      }
      if (this.ability.can(Actions.ANALYTICS, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.dms.reports'),
          icon: 'custom-icon pi pi-file-excel',
          title: this.getLangLabel('app.dms.reports'),
          routerLink: ['KIOSK/reports'],
        };
        kioskMenuObj.items.push(nestedObj);
      }

      if (this.ability.can(Actions.READ, Subject.K_AGENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.kiosk.kAgent'),
          icon: 'custom-icon pi pi-user',
          title: this.getLangLabel('app.sidemenu.kiosk.kAgent'),
          routerLink: ['KIOSK/kAgent'],
        };

        kioskMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.SETTINGS, Subject.KIOSK_MANAGEMENT)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.kiosk.settings'),
          icon: 'custom-icon pi pi-fw pi-cog',
          title: this.getLangLabel('app.sidemenu.kiosk.settings'),
          routerLink: ['KIOSK/settings'],
          preventExact: true
        };
        kioskMenuObj['items'].push(nestedObj);
      }

      this.model.push(kioskMenuObj);
    }

    if (this.isWarehouseManagmentListRequired() || this.isWarehouseMonitoringListRequired()) {
      const mainMenuObj = {
        label: this.getLangLabel('app.sidemenu.warehouse.warehouse'),
        icon: 'custom-icon icon-color custom-icon-img warehouse-icon',
        title: this.getLangLabel('app.sidemenu.warehouse.warehouse'),
        routerLink: ['/warehouse'],
        items: [],
      };

      if(this.isWarehouseMonitoringListRequired()){
        const warehouseMenuObj = {
          label: this.getLangLabel('app.sidemenu.warehouse.monitoring'),
          icon: 'custom-icon icon-color custom-icon-img monitoring-icon',
          title: this.getLangLabel('app.sidemenu.warehouse.monitoring'),
          routerLink: ['/warehouse/monitoring'],
          items: [],
        };

        if (this.ability.can(Actions.DASHBOARD, Subject.WAREHOUSE)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.dashboard'),
            icon: 'custom-icon custom-icon-img dashboard-icon',
            title: this.getLangLabel('app.sidemenu.warehouse.dashboard'),
            routerLink: ['warehouse/monitoring/dashboard'],
          };
          warehouseMenuObj['items'].push(nestedObj);
        }

        if (this.ability.can(Actions.ANALYTICS, Subject.WAREHOUSE)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.analytics'),
            icon: 'custom-icon pi pi-fw pi-chart-line',
            title: this.getLangLabel('app.sidemenu.warehouse.analytics'),
            routerLink: ['warehouse/monitoring/analytics'],
            preventExact: true
          };
          warehouseMenuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.REPORTS, Subject.WAREHOUSE)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.report'),
            icon: 'custom-icon pi pi-file-excel',
            title: this.getLangLabel('app.sidemenu.report'),
            routerLink: ['warehouse/monitoring/reports'],
            preventExact: true
          };
          warehouseMenuObj.items.push(nestedObj);
        }


        if (this.ability.can(Actions.SETTINGS, Subject.WAREHOUSE)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.settings'),
            icon: 'custom-icon pi pi-fw pi-cog',
            title: this.getLangLabel('app.sidemenu.warehouse.settings'),
            routerLink: ['warehouse/monitoring/settings'],
            preventExact: true
          };
          warehouseMenuObj.items.push(nestedObj);
        }
        mainMenuObj.items.push(warehouseMenuObj);
      }

      if(this.isWarehouseManagmentListRequired()) {
        const warehouseManangeMenuObj = {
          label: this.getLangLabel('app.sidemenu.warehouse.management'),
          icon: 'custom-icon icon-color custom-icon-img management-icon',
          title: this.getLangLabel('app.sidemenu.warehouse.management'),
          routerLink: ['/warehouse/management'],
          items: [],
        };

        if (this.ability.can(Actions.READ, Subject.WMS_REQUISITION)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.requisition'),
            icon: 'custom-icon icon-color custom-icon-img o2d-icon',
            title: this.getLangLabel('app.sidemenu.warehouse.requisition'),
            routerLink: ['warehouse/management/requisition'],
            preventExact: true
          };
          warehouseManangeMenuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.READ, Subject.WMS_FULLFILLMENT)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.fullfillment'),
            icon: 'custom-icon icon-color custom-icon-img o2d-icon',
            title: this.getLangLabel('app.sidemenu.warehouse.fullfillment'),
            routerLink: ['warehouse/management/fullfillment'],
            preventExact: true
          };
          warehouseManangeMenuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.READ, Subject.WMS_PROGRESS)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.progress'),
            icon: 'custom-icon icon-color custom-icon-img o2d-progress-icon',
            title: this.getLangLabel('app.sidemenu.warehouse.progress'),
            routerLink: ['warehouse/management/progress'],
          };
          warehouseManangeMenuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.READ, Subject.WMS_OUTBOUND)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.outbound'),
            icon: 'custom-icon icon-color custom-icon-img o2d-progress-icon',
            title: this.getLangLabel('app.sidemenu.warehouse.outbound'),
            routerLink: ['warehouse/management/outbound'],
          };
          warehouseManangeMenuObj.items.push(nestedObj);
        }

        if (this.ability.can(Actions.READ, Subject.WMS_INBOUND)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.supply'),
            icon: 'custom-icon  pi pi-box',
            title: this.getLangLabel('app.sidemenu.warehouse.supply'),
            routerLink: ['warehouse/management/supply'],
            preventExact: true
          };
          warehouseManangeMenuObj.items.push(nestedObj);
        }
        
        if (this.ability.can(Actions.READ, Subject.WMS_INVENTORY)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.inventory'),
            icon: 'custom-icon pi pi-map-marker',
            title: this.getLangLabel('app.sidemenu.warehouse.inventory'),
            routerLink: ['warehouse/management/inventory'],
            preventExact: true
          };
          warehouseManangeMenuObj.items.push(nestedObj);
        }
        if (this.ability.can(Actions.SETTINGS, Subject.WAREHOUSE)) {
          const nestedObj = {
            label: this.getLangLabel('app.sidemenu.warehouse.settings'),
            icon: 'custom-icon pi pi-fw pi-cog',
            title: this.getLangLabel('app.sidemenu.warehouse.settings'),
            routerLink: ['warehouse/management/settings'],
            preventExact: true
          };
          warehouseManangeMenuObj.items.push(nestedObj);
        }

        mainMenuObj.items.push(warehouseManangeMenuObj);
      }

      this.model.push(mainMenuObj);
    }

    if (this.isRoleAssetAuthListRequired()) {
      const roleAssetAuthMenuObj = {
        label: this.getLangLabel('app.sidemenu.authorization.authorization'),
        icon: 'custom-icon icon-color custom-icon-img authorization-icon',
        title: this.getLangLabel('app.sidemenu.authorization.authorization'),
        routerLink: ['/authorization'],
        items: [],
      };

      if (this.ability.can(Actions.READ, Subject.ROLE_ASSET_AUTH)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.authorization.user-asset-auth'),
          icon: 'custom-icon custom-icon-img authorization-icon',
          title: this.getLangLabel('app.sidemenu.authorization.user-asset-auth'),
          routerLink: ['authorization/roleAssetAuth'],
        };
        roleAssetAuthMenuObj['items'].push(nestedObj);
      }

      if (this.ability.can(Actions.READ, Subject.ROLE_ASSET_AUTH)) {
        const nestedObj = {
          label: this.getLangLabel('app.sidemenu.config.role-management'),
          icon: 'custom-icon pi pi-fw pi-user',
          title: this.getLangLabel('app.sidemenu.config.role-management'),
          routerLink: ['authorization/accountLevelRoleManagement'],
        };
        roleAssetAuthMenuObj['items'].push(nestedObj);
      }

      this.model.push(roleAssetAuthMenuObj);
    }

    //lower menu elt to show when horizontal mode add them in reverse

    if (this.ability.can(Actions.READ, Subject.SETTINGS)) {
      const menuObj = {
        label: this.getLangLabel('app.sidemenu.config.config'),
        icon: 'custom-icon pi pi-fw pi-cog',
        routerLink: ['config'],
        title: this.getLangLabel('app.sidemenu.config.config'),
        preventExact: true
      };
      this.lower.push(menuObj);
    }
    if (this.ability.can(Actions.READ, Subject.SERVICE)) {
      const menuObj = {
        label: this.getLangLabel('app.sidemenu.services.services'),
        icon: 'custom-icon icon-color custom-icon-img service-icon',
        titel: this.getLangLabel('app.sidemenu.services.services'),
        routerLink: ['/services']

      };
      this.lower.push(menuObj);
    }
    if (this.ability.can(Actions.READ, Subject.GROUP)) {
      const menuObj = {
        label: this.getLangLabel('app.sidemenu.groups.groups'),
        icon: 'custom-icon pi pi-fw pi-th-large',
        titel: this.getLangLabel('app.sidemenu.groups.groups'),
        routerLink: ['/groups'],
      };
      this.lower.push(menuObj);
    }


  }

  isO2dManagementListRequired() {
    return this.ability.can(Actions.READ, Subject.O2D_MANAGEMENT) ||
      this.ability.can(Actions.WRITE, Subject.O2D_MANAGEMENT) ||
      this.ability.can(Actions.ANALYTICS, Subject.O2D_MANAGEMENT) ||
      this.ability.can(Actions.SETTINGS, Subject.O2D_MANAGEMENT) ||
      this.ability.can(Actions.LIVE, Subject.O2D_MANAGEMENT);
  }

  isDeliveryManagementListRequired() {
    return this.ability.can(Actions.READ, Subject.DELIVERY_MANAGEMENT) ||
      this.ability.can(Actions.WRITE, Subject.DELIVERY_MANAGEMENT) ||
      this.ability.can(Actions.ANALYTICS, Subject.DELIVERY_MANAGEMENT) ||
      this.ability.can(Actions.LIVE, Subject.DELIVERY_MANAGEMENT);
  }

  isKioskListRequired() {
    return this.ability.can(Actions.READ, Subject.KIOSK_MANAGEMENT) ||
      this.ability.can(Actions.WRITE, Subject.KIOSK_MANAGEMENT) ||
      this.ability.can(Actions.ANALYTICS, Subject.KIOSK_MANAGEMENT);
  }

  isMmdManagementListRequired() {
    return this.ability.can(Actions.READ, Subject.MMD_MANAGEMENT) ||
      this.ability.can(Actions.WRITE, Subject.MMD_MANAGEMENT) ||
      this.ability.can(Actions.ANALYTICS, Subject.MMD_MANAGEMENT) ||
      this.ability.can(Actions.REPORTS, Subject.MMD_MANAGEMENT) ||
      this.ability.can(Actions.LIVE, Subject.MMD_MANAGEMENT);
  }

  isLmdManagementListRequired() {
    return this.ability.can(Actions.READ, Subject.LMD_MANAGEMENT) ||
      this.ability.can(Actions.WRITE, Subject.LMD_MANAGEMENT) ||
      this.ability.can(Actions.ANALYTICS, Subject.LMD_MANAGEMENT) ||
      this.ability.can(Actions.REPORTS, Subject.LMD_MANAGEMENT) ||
      this.ability.can(Actions.LIVE, Subject.LMD_MANAGEMENT);
  }

  showAgentTabInDmsMenu() {
    return this.ability.can(Actions.READ, Subject.ASSET) &&
      this.ability.can(Actions.READ, Subject.DELIVERY_MANAGEMENT) &&
      this.ability.can(Actions.WRITE, Subject.DELIVERY_MANAGEMENT);
  }

  isTrackerListRequired() {
    return (this.ability.can(Actions.LIVE, Subject.TRACKER)) || (this.ability.can(Actions.ANALYTICS, Subject.TRACKER)) || (this.ability.can(Actions.WRITE, Subject.TRACKER)) || (this.ability.can(Actions.READ, Subject.GEOFENCE));
  }

  isInspectionBayListRequired() {
    return (this.ability.can(Actions.READ, Subject.INSPECTION)) || (this.ability.can(Actions.LIVE, Subject.INSPECTION)) || (this.ability.can(Actions.ANALYTICS, Subject.INSPECTION));
  }

  isSecurityListRequired() {
    return (this.ability.can(Actions.READ, Subject.FACE_MATCH)) || (this.ability.can(Actions.READ, Subject.AUTO_REGISTER)) || (this.ability.can(Actions.ANALYTICS, Subject.SECURITY)) || (this.ability.can(Actions.LIVE, Subject.SECURITY));
  }

  isAttendanceListRequired() {
    return (this.ability.can(Actions.READ, Subject.FACE_MATCH)) || (this.ability.can(Actions.ANALYTICS, Subject.ATTENDANCE)) || (this.ability.can(Actions.LIVE, Subject.ATTENDANCE));
  }

  isKeyManagementListRequired() {
    return (this.ability.can(Actions.READ, Subject.KEY_MANAGEMENT)) || (this.ability.can(Actions.WRITE, Subject.KEY_MANAGEMENT)) || (this.ability.can(Actions.READ, Subject.KEY_MANAGEMENT_ASSIGNMENT))
      || (this.ability.can(Actions.READ, Subject.KEY_MANAGEMENT_QUEUE)) || (this.ability.can(Actions.ANALYTICS, Subject.KEY_MANAGEMENT));
  }

 /*  isWarehouseListRequired() {
    return this.ability.can(Actions.READ, Subject.WAREHOUSE) ||
      this.ability.can(Actions.WRITE, Subject.WAREHOUSE) ||
      this.ability.can(Actions.ANALYTICS, Subject.WAREHOUSE);
  } */

  isWarehouseMonitoringListRequired(){
    return this.ability.can(Actions.MONITORING, Subject.WAREHOUSE);
  }

  isWarehouseManagmentListRequired(){
    return this.ability.can(Actions.MANAGEMENT, Subject.WAREHOUSE);
  }


  isRoleAssetAuthListRequired() {
    return this.ability.can(Actions.READ, Subject.ROLE_ASSET_AUTH) ||
      this.ability.can(Actions.WRITE, Subject.ROLE_ASSET_AUTH);
  }


  getLangLabel(labelCode: string) {
    if (labelCode) {
      return this.lang.getTranslation(labelCode);
    }
  }

  loadLPCities(lpName) {
    this.store.select(assetLogisticProviderLabelSelector).subscribe(lps => {
      if (lps?.length > 0) {
        let index: number;
        if (lpName === this.LMD_LP_NAME) {
          index = lps.findIndex((type: ILogisticProvider) => type.uniqueId === this.LMD_LP_NAME);
        } else if (lpName === this.MMD_LP_NAME) {
          index = lps.findIndex((type: ILogisticProvider) => type.uniqueId === this.MMD_LP_NAME);
        }

        if (index > -1) {
          this.store.dispatch(getLogisticProviderLinkedCities({
            lpId: lps[index]['_id'],
            cityAssetType: CONSTANT.ASSET_TYPES.CITY,
            moduleUniqueId: lps[index]?.uniqueId,
            id: '',
            isCount: false,
            skip: 0,
            limit: 200
          }));
        }
      }
    });
  }

}



