import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges } from '@angular/core';
import L, {
  circleMarker,
  control,
  divIcon,
  icon,
  LatLng,
  latLng,
  Layer,
  layerGroup,
  Map,
  marker,
  polygon,
  polyline,
  tileLayer
} from 'leaflet';
import { MapTiles } from '../../settings/user-profile/user-profile-type';
import { MapsService } from '../../../services/maps.service';
import { cloneDeep, findIndex, forEach, map, orderBy } from 'lodash';
import { DateFormatterService } from '../../../services/dateformat';
import moment from 'moment';

import { LangUtilService } from '../../util/lang-util.service';
import { TrackingService } from '../../../services/tracking.service';
import { MenuItem, MessageService, SharedModule } from 'primeng/api';
import { CONSTANT } from '../../../config/constant';
import { GeoFenceObject, TrackerIcons } from '../../../state/Fleet/models/fleet.models';
import { CommonService } from '../../../services/common.service';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { Actions, Subject } from '../../../auth/rules';
import {
  getPopupButtonsAndTip,
  getPopUpHeader,
  showPopupData
} from '../../../app/delivery-managenment-system/services/common.service';
import { Ability } from '@casl/ability';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getUserMapSelector } from '../../../state/User/user.selector';
import { SliderModule } from 'primeng/slider';
import 'l.movemarker'
import { isMobileViewSelector } from '../../../state/Assets/assets.selector';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { LoaderComponent } from '../loader/loader.component';
import { GeoFenceFilterPipe } from '../../../pipes/geoFence.filter.pipe';
import { EventsFilterPipe } from '../../../pipes/events.filter.pipe';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';


const DELIVERY_STATUS_COMPLETED = CONSTANT.DELIVERY_STATUS.COMPLETED;


declare let $: any;


@Component({
  selector: 'app-consignment-event-geofence-map',
  standalone:true,
  templateUrl: './consignment-event-geofence-map.component.html',
  styleUrl: './consignment-event-geofence-map.component.scss',
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ConfirmPopupModule, 
    SliderModule,
    InputTextModule,
    InputNumberModule,
    DialogModule,
    OverlayPanelModule,
    ButtonModule,
    TooltipModule,
    SpeedDialModule,
    SplitButtonModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    SelectButtonModule,
    ToastModule,
    LoaderComponent,
    GeoFenceFilterPipe,
    EventsFilterPipe,
    ToggleButtonModule,
  ],
})
export class ConsignmentEventGeofenceMapComponent implements OnInit, OnChanges, OnDestroy {

  
  @Input() routesDetails: any = null;
  @Input() eventsToPlot: any = [];
  @Input() mapMarkers: any = [];
  @Input() liveDataFromSocket: any = {};
  @Input() rowData: any;
  @Input() agentDetails: any;
  @Input() isRightSidebarClosed: boolean;
  @Input() displayPills = true;
  @Input() pageName = '';
  @Input() selectedOrderDeliveryGroup: any;
  @Input() carGpsHistoricalData: any
  @Input() geoFences: GeoFenceObject[];
  @Input() trackIcons: TrackerIcons;
  @Input() reloadMapNumber: number;
  @Input() hideLeftActionButtons:boolean
  @Input() hideRightActionButtons:boolean
  @Input() currentNonComplianceEvent:IComplianceEvent
  @Input() rightActionButtons:Array<any>;


  @Output() activeRouteChanged: EventEmitter<any> = new EventEmitter();
  @Output() emittRightButtonsAction: EventEmitter<any> = new EventEmitter();
  @Output() routeLegendChanged: EventEmitter<any> = new EventEmitter();
  @Output() sendModalClosed: EventEmitter<any> = new EventEmitter();
  @Output() sendSideBarClosedEvent: EventEmitter<any> = new EventEmitter();
  @Output() sendLatLngValues: EventEmitter<ILocationPayload> = new EventEmitter();
  @Output() emittGeoFenceRadiusPayload: EventEmitter<any> = new EventEmitter();

  ACTIONS = Actions;
  SUBJECT = Subject;

  chartModalOpen = false;
  moduleName: string = null;
  eventsToPlotCopy: any = [];


  pathMovingIndex = signal<number>(0)
  speedMultiplicator = signal<number>(1)
  movingMarkerIntervalID = signal<number | NodeJS.Timeout>(0)
  movingMarker;
  stateOptions: any[] = [
    { label: '2x', value: 0.5 },
    { label: '4x', value: 1 },
    { label: '6x', value: 1.5 },
    { label: '8x', value: 2 }
  ]


  public map: any;
  markers: Layer[] = [];
  private url: string;
  private option: any;
  options = {
    layers: [],
    zoom: 5,
    zoomControl: false,
    center: latLng(null, null),
    key: 'marker1'
  };
  mapToView: any;
  layer: Layer;
  public mapDetails: MapTiles;
  public selectedMap: any;
  public selectedZoom: number;
  public selectedLatitude: number;
  public selectedLongitude: number;
  public center: any;
  public defaultMapDetails: any;
  public defaultPanelSize: Array<number> = [50, 50];
  route = polyline([]);
  routes = [];
  legendList: RouteLegend[] = [
    {
      'label': 'Actual Path',
      'color': '#00A3E3',
      'isActive': true,
      'type': 'actual'
    },
    {
      'label': 'Expected Path',
      'color': '#E34000',
      'isActive': false,
      'type': 'expected'
    }
  ];
  legendListCopy: RouteLegend[] = [];
  startMarkerLocation;
  // Marker for the parking lot at the base of Mt. Ranier trails


  startingLocation = marker([0, 0], {
    icon: icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: 'assets/leaflet-icons/start-marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  });

  endingLocation = marker([0, 0], {
    icon: icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: '../assets/leaflet-icons/end-marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  });

  agentMarkerLocation = marker([0, 0], {
    icon: icon({
      iconSize: [40, 50],
      iconAnchor: [13, 41],
      iconUrl: '../assets/leaflet-icons/marker-icon-active.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  });

  _liveInfoTab = {
    isFirstTimeAgentDataReceived: true,
    tabLabel: 'Live',
    isGraphDisplayReq: false
  };

  agentRoute = polyline([]);
  agentRouteArray = [];
  newDataForAgentPolylineCount: number;

  eventMarkersArray = [];
  actionMessage: string;

  allDeliveryMarkers = [];

  allDeliveryLocMarkers = {};

  userMapOptionsSub$: Subscription;

  routeArray = [];
  test = 'test var';

  enableLocationUpdate: boolean;
  enableRoutePoints = signal<boolean>(false);
  playActualPath = signal<boolean>(false);
  showPlayActualPanel = signal<boolean>(false);
  actualPathMarkers = [];
  showLoader = false;
  geomarkers: Layer[] = [];
  showGeofences = false;
  showEvents = false;
  eventsMarkers = {}
  geoFencesById = {}
  dataForGraph;
  items: MenuItem[] | undefined;
  isMobileView$ = this.store.pipe(select(isMobileViewSelector));

 


  constructor(public mapsService: MapsService, public dateFormatter: DateFormatterService, private lang: LangUtilService,
    public trackingService: TrackingService,
    private messageService: MessageService,
    private commonService: CommonService, private ability: Ability, private store: Store,
  ) {
    this.legendListCopy = cloneDeep(this.legendList)
  }


  ngOnInit(): void {
    this.provideLocationUpdateAccess()
    this.userMapOptionsSub$ =
      this.store.pipe(select(getUserMapSelector)).subscribe(userMapOptions => {
        this.mapDetails = userMapOptions
        this.getMapForSelectedUser();
      });
    this.items = [
      {
        icon: 'play'
      },
      {
        icon: 'geofenceAndEventsList'
      },
      {
        icon: 'pointsOnRouteToggleButon'
      },
    ];
    if (this.accessLocationUpdate) {
      this.items.push(
        {
          icon: 'locationUpdateToggleButton'
        }
      )
    }
  }

  eventCardClick(event) {
    this.focusedEvent = event
    if (!this.showEvents) {
      this.plotEvents(this.eventsToPlotCopy);
      this.showEvents = true
    }
    const [lat, lng] = event?.coordinates || []
    this.map.panTo({ lat, lng })
    setTimeout(() => {
      this.map.setZoomAround({ lat, lng }, 24);
      this.eventsMarkers[event?._id]?.openPopup()
    }, 500);
  }

  geofenceCardClick(geofence: GeoFenceObject) {
    this.focusedGeoFence = geofence
    this.showGeofences = true
    this.markers = [...(this.geomarkers || [])];
    const [lng, lat] = geofence?.geoLocation?.coordinates || []
    this.map.panTo({ lat, lng })
    setTimeout(() => {
      this.map.setZoomAround({ lat, lng }, 18);
    }, 500);
  }

  focusedGeoFence: GeoFenceObject
  focusedEvent
  searchGeoFence: string
  searchEvent: string


  showGeofencesList: boolean
  showEventsList: boolean
  showListSearchBar: boolean

  showEventsListData(): void {
    if (!this.showEventsList) {
      this.showEventsList = true
      this.showGeofencesList = false
      this.focusedEvent = null
    } else {
      this.showEventsList = false
    }
  }

  onPlayPathDropDownClick(op) {
    this.showPlayActualPanel.set(!this.showPlayActualPanel())
    op.toggle(event);
    this.showGeofencesList = false
    this.showEventsList = false
  }

  showGeofencesListData(): void {
    if (!this.showGeofencesList) {
      this.showGeofencesList = true;
      this.showEventsList = false;
      this.focusedGeoFence = null;
    } else {
      this.showGeofencesList = false
    }
  }



  ngOnChanges(changes: SimpleChanges) {

    try {
      for (const propName of Object.keys(changes)) {
        const change = changes[propName];

       
        if (propName === 'reloadMapNumber') {
          if (change.currentValue !== change.previousValue) {
            this.messageService.clear();
            this.onPanelResize();
            setTimeout(() => {
              this.messageService.clear();
              this.panToActualPath();
            }, 600);
          }
        }
      
        if (propName === 'routesDetails') {
            this.defaultPanelSize = [70, 30];
            this.removeLayer();
            this.getMapForSelectedUser();
            this.plotPolyLine();
            this.plotIcons(this.enableLocationUpdate);
           
        }
        if (propName === 'liveDataFromSocket') {
          if (change.currentValue !== change.previousValue) {
            if (this.liveDataFromSocket) {
              this.setRiderMarkerLocation(this.liveDataFromSocket);
            }
           
          }
        }
        if (propName === 'currentNonComplianceEvent') {
          if (change.currentValue !== change.previousValue) {
           this.currentNonComplianceEvent && this.triggerRowClick(this.currentNonComplianceEvent)
          }
        }
        if (propName === 'agentDetails') {
          if (change.currentValue !== change.previousValue) {
            this.legendList = cloneDeep(this.legendListCopy);
            this.resetLegendActiveVaues();
            this.resetAgentRouteVariables();
            if (this.agentDetails && this.pageName === 'progress' || this.pageName === 'mmd-consignment-group') {
              setTimeout(() => {
                this.setRiderLastLocation(this.agentDetails);
              }, 1000);
            }

          }
        }
        if (propName === 'rowData') {
          if (change.currentValue !== change.previousValue) {
            if (this.rowData) {
              this.legendList = cloneDeep(this.legendListCopy)
              this.moduleName = this.rowData?.moduleType;
              this.defaultPanelSize = [50, 50];
              this.buildGraph();
              this.onPanelResize();
              this.resetLegendActiveVaues();
            }
          }
        }
        if (propName === 'carGpsHistoricalData') {
          if (change.currentValue !== change.previousValue) {
            if (this.carGpsHistoricalData?.length) {
              this.buildGraph();
            }
          }
        }
        
        
        if (propName === 'isRightSidebarClosed') {
          if (change.currentValue !== change.previousValue) {
            if (!this.isRightSidebarClosed) {
              this.removeLayer();
              this.resetAgentRouteVariables();
              this.removeGeoFences();
              this.showGeofences = false;
            } else {
              this.enableLocationUpdate = false;
              this.showEvents = false;
              this.showGeofences = false;
              this.showEventsList = false
              this.showGeofencesList = false;
              this.removeActualPathMarkers();
              this.enableRoutePoints.set(false);
              this.removeGeoFences();

              this.playActualPath.set(false);
              this.speedMultiplicator.set(1);
              this.pathMovingIndex.set(0);
              this.movingMarker?.remove();
              clearInterval(this.movingMarkerIntervalID());
              this.routesDetails = null;
            }
          }
        }

        if (propName === 'geoFences') {

          if (change.currentValue && change.currentValue !== change.previousValue) {
            this.plotGeoFence(this.geoFences);
          
          }
        }

        if (propName === 'eventsToPlot') {
          if (change.currentValue && change.currentValue !== change.previousValue) {
            if (this.eventsToPlot && this.eventsToPlot.length > 0) {
              this.eventsToPlotCopy = [...this.eventsToPlot];
              this.showEvents && this.plotEvents(this.eventsToPlot);
              setTimeout(() => {
                this.showEvents && this.removeEvents();
              }, 0);
            } else {
              this.eventsToPlotCopy = [];
              this.removeplottedEvents();
              this.showEvents && this.removeEvents();
            }
          }
        }

      }
    } catch (e) {e}
  }

  /*   resetFilterMenuOptions() {
      forEach(this.filterMenuOptions, r =>{
        r['active'] = false;
      })
      this.filterMenuOptions[0]['active'] = true;
    } */


  plotGeoFence(data: GeoFenceObject[]) {
    if (this.map && data && data.length > 0) {
      this.geomarkers = []
      data.map(geofence => {
        const coordinates = map(geofence.location.coordinates, function (coordinate) {
          return latLng(coordinate[0], coordinate[1]);
        });
        const geoFencePlot = polygon(coordinates, { color: 'blue' });
        this.setTooltipContentGeoFence(geoFencePlot, geofence, coordinates[0]);
        this.geomarkers.push(geoFencePlot);
        geofence.assetId && geofence.radius &&
          this.accessLocationUpdate &&
          geoFencePlot.on('click', () => {
            this.geoFenceRadius = geofence?.radius || 0
            this.updateGeoFenceRadiusData = {
              assetId: geofence?.assetId,
              geoFenceRadius: this.geoFenceRadius,
              lat: geofence?.geoLocation?.coordinates?.[1] || 0,
              lng: geofence?.geoLocation?.coordinates?.[0] || 0,
              label: geofence?.label || ''
            }
            this.geoFenceFormDialog = true
          });
      });
    }

    if (this.showGeofences) {
      this.markers = [...(this.geomarkers || [])];
    }
  }

  public geoFenceFormDialog: boolean;
  public geoFenceRadius: number
  public accessLocationUpdate: boolean
  public updateGeoFenceRadiusData: ILocationPayload

  closePopups() {
    this.map.closePopup()
  }

  updateGeoFenceRadius() {
    this.geoFenceFormDialog = false
    this.updateGeoFenceRadiusData = {
      ...this.updateGeoFenceRadiusData,
      geoFenceRadius: this.geoFenceRadius,
    }
    this.emittGeoFenceRadiusPayload.emit(this.updateGeoFenceRadiusData)
  }

  provideLocationUpdateAccess() {
    if ((this.ability.can(Actions.UPDATE_LOCATION, Subject.MMD_MANAGEMENT))) {
      this.accessLocationUpdate = true;
    } else {
      this.accessLocationUpdate = false;
    }
  }


  arePopupsOpen() {
    const layers = this.map._layers;
    for (let layerId in layers) {
      const layer = layers[layerId];
      if (layer instanceof L.Marker && layer.getPopup() && layer.getPopup().isOpen()) {
        return true;
      }
    }
    return false;
  }

  setTooltipContentGeoFence(geoFencePlot: L.Polygon, data: GeoFenceObject, popupLocation) {
    geoFencePlot.bindPopup(
      getPopUpHeader('Geo-Fence: ', (data?.label || ''), `Geo-Fence Radius: ${data?.radius || 0}`) +
      showPopupData([]) +
      (this.enableLocationUpdate ? getPopupButtonsAndTip('Click on Geo-Fence to Edit Radius', false) : '')
    );

    geoFencePlot?.on('mouseover', () => {
      !this.arePopupsOpen() &&
        geoFencePlot.openPopup(popupLocation)
    })
    geoFencePlot?.on('mouseout', () => {
      geoFencePlot.closePopup()
    })
  }

  removeGeoFences() {
    this.showGeofences = !this.showGeofences;
    this.showGeofencesList = false
    this.showEventsList = false
    if (this.showGeofences) {
      this.markers = [...(this.geomarkers || [])];
    } else {
      this.markers = [];
    }
  }

  removeEvents() {
    this.showEvents = !this.showEvents;
    if (this.showEvents) {
      this.plotEvents(this.eventsToPlotCopy);
    } else {
      this.removeplottedEvents();
    }
  }

  onModalClose() {
    this.showGeofences = false;
    this.showEvents = false;
    this.sendModalClosed.emit(true);
    this.removeLayer();
    this.resetAgentRouteVariables();
  }



 

  // ---------------leaflet map----------------

  onMapReady(map: Map) {
    $('div.leaflet-control-attribution').hide();
    // $('.leaflet-control').hide();
    this.map = map
    const attribution = map.attributionControl;
    attribution.setPrefix('');
    control.zoom({
      position: 'bottomright'
    }).addTo(map);
    this.plotPolyLine()
    this.getMapForSelectedUser();
    this.plotGeoFence(this.geoFences)
    this.plotIcons(this.enableLocationUpdate);
  }

  toggleRoute(id: any) {

    forEach(this.routes, r => {
      if (r.id === id) {
        const rStatus = this.map.hasLayer(r.route);
        if (rStatus) {
          this.map.removeLayer(r.route);
        } else {
          this.map.addLayer(r.route);
        }

      }
    });
  }

  getMapForSelectedUser() {

    if (this.mapDetails) {
      if (this.mapDetails.zoom) {
        this.selectedZoom = this.mapDetails.zoom;
      } else {
        this.selectedZoom = 11;
      }
      if (this.mapDetails.centerLocation) {
        this.selectedLatitude = this.mapDetails.centerLocation.coordinates[0];
        this.selectedLongitude = this.mapDetails.centerLocation.coordinates[1];
      } else {
        this.selectedLatitude = 25.214017;
        this.selectedLongitude = 55.310220;
      }
      this.setMap(this.mapDetails);
      this.center = latLng(this.selectedLatitude, this.selectedLongitude);

      this.defaultMapDetails = {
        center: this.center,
        selectedZoom: this.selectedZoom,
      };

    }
  }

  setMap(mapSelected) {

    if (mapSelected && mapSelected.tile && this.map) {
      this.url = mapSelected.tile.url;
      this.option = mapSelected.tile.option;
      this.option = { ...this.option, attributionControl: false };
      this.mapToView = tileLayer(this.url, this.option);
      if (mapSelected.tile['google_style']) {
        (L as any).gridLayer.googleMutant({
          type: 'roadmap',
          styles: mapSelected.tile['google_style'],
          disableDefaultUI: true,
        }).addTo(this.map);

      } else {
        if (this.map) {
          this.mapToView?.addTo(this.map);
        }
      }
      this.map.invalidateSize(true);
    }
  }

  // plotting markers
  plotIcons(locationUpdateStatus) {
    this.allDeliveryMarkers?.forEach((m) => {
      m.remove();
    });
    if (this.mapMarkers && this.mapMarkers.length > 0) {
      forEach(this.mapMarkers, (m) => {
        this.plotDelLoactionMarkers(m, locationUpdateStatus);
      });
    }
  }

  toggleLocationUpdatePermission(status: boolean) {
    this.enableLocationUpdate = status;
    this.plotIcons(this.enableLocationUpdate);
    this.plotGeoFence(this.geoFences);
    if (!status) this.geoFenceFormDialog = status;
    if (status && this.geoFenceFormDialog) {
      this.geoFenceFormDialog = false
    }
  }

  plotDelLoactionMarkers(markerVal, draggable: boolean) {
    const { lat, lng, name: orderLocationId, shortHand } = markerVal
    const mOptions = markerVal?.mOptions || {
      color: "#373fa3",
      fillColor: "#fff",
      fillOpacity: 0.8,
      radius: 15,
      weight: 3,
    };
  
    if (lat && lng) {
      let startMarkerLocation = circleMarker([lat, lng], mOptions);
      const startLocation = { lat, lng };
      const icon = divIcon({
        html: `<div>
         <span class='leaflet-circle-marker-txt' style='
        color: ${mOptions.color};
        border:2px solid ${mOptions.color};
        width:${2 * mOptions.radius}px;
        height:${2 * mOptions.radius}px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        '>${shortHand} </span></div>`,
        className: "",
        iconSize: [40, 40],
      });
      let orderMarker = marker(startLocation, { icon, draggable });

      let popUpContent = markerVal.details + (draggable ? getPopupButtonsAndTip('Drag to Update Location', false) : '');

      orderMarker.bindPopup(popUpContent, { maxWidth: 1000, offset: [0, -8] }).openPopup();

      orderMarker.on("dragstart", () => {
        startMarkerLocation.remove();
        orderMarker.closePopup()
      });

      orderMarker.on("mouseover", () => {
        orderMarker.openPopup()
        this.addEventListenerForPopup()
      });

      orderMarker.on("dragend", (e) => {

        const { lat: latitude, lng: longitude } = e?.target?.getLatLng();
        popUpContent = markerVal.details + (draggable ? getPopupButtonsAndTip('Drag to Update Location') : '');
        orderMarker.bindPopup(popUpContent).openPopup();
        this.addEventListenerForPopup()


        startLocation.lat = latitude;
        startLocation.lng = longitude;

        startMarkerLocation = circleMarker([latitude, longitude], mOptions);
        this.startMarkerLocation = layerGroup([startMarkerLocation, orderMarker]);
        this.map &&  this.startMarkerLocation.addTo(this.map);

        this.allDeliveryMarkers.push(this.startMarkerLocation);

        this.foucusedLatLng = { lat: latitude, lng: longitude };
        this.focusedAssetId = markerVal.deliveryCenterId || markerVal.pickupLocationId
      });
      this.startMarkerLocation = layerGroup([startMarkerLocation, orderMarker]);
      this.map && this.startMarkerLocation.addTo(this.map);
      this.allDeliveryMarkers.push(this.startMarkerLocation);
    } else {
      this.showInfo(`Location for Order ID ${orderLocationId} is Empty`);
    }
  }

  foucusedLatLng
  focusedAssetId: string

  updateLegendList(route,isClear=false){
    this.legendList = (this.legendList || []).map((l) => {
      if(isClear){
        return { ...l, isActive: false }
      }
      else if (l?.label === route?.name) {
        return { ...l, isActive: true }
      }
      return l
    })
  }
 

  panToSelectedPath(data) {
    this.startingLocation.remove();
    this.endingLocation.remove();
    const routeData = data;
    const latLngs = routeData['latLngs'];
    const color = routeData['color'];
    const showThis = routeData['show'];
    const panToThis = routeData['isPanReq'];
    const name = routeData['name'];
    const distance = routeData['distance'];
    const isLatLngsValid = latLngs && latLngs.length > 0;
   
    if(!this.map)return;

    this.updateLegendList(routeData)

    if (isLatLngsValid) {
      if (showThis) {
        const routeArray = map(latLngs, function (data) {
          return new LatLng(data[0], data[1]);
        });
        if (name === 'Actual Path') {
          this.startingLocation.setLatLng(routeArray[0]).addTo(this.map);
          this.startingLocation.bindTooltip('<div><B>Start Point </B></div>');
          if (this.selectedOrderDeliveryGroup?.status === DELIVERY_STATUS_COMPLETED) {
            this.endingLocation.setLatLng(routeArray[(routeArray.length) - 1]).addTo(this.map);
            this.endingLocation.bindTooltip('<div><B>End Point </B></div>');
          }
        }

        const route = polyline(routeArray, { 'weight': 4, color, 'opacity': .9 }).addTo(this.map)
          .bindPopup(
            `<b>${name}<b>
            </br>
            <b>${(distance ? distance : '')}<b>
        `, { closeButton: true });
        if (data?.name === 'Actual Path' && this.enableRoutePoints()) {
          this.removeActualPathMarkers();
          this.dataForGraph?.forEach(obj => {
            const marker = L.circleMarker({ lat: obj?.coordinates?.[0], lng: obj?.coordinates?.[1] }, {
              radius: this.getDynamicRadius(),
              color: '#005d81',
              fillOpacity: 1,
              weight: 2
            }).addTo(this.map);
            marker.bindPopup(getPopUpHeader('Sensor stats', '', this.dateFormator(obj.updated, 'lll',)) + showPopupData([{ label: 'Temp in °C : ', value: obj?.sensorData['temperature']?.toFixed(2) }])
              , { maxWidth: 1000, offset: [0, -8] })
            this.actualPathMarkers.push(marker);
          });
        }

        this.routes.push({ id: name, route, show: true });
        setTimeout(() => {
          if (panToThis && route) {
            this.map.fitBounds(route.getLatLngs());
          }
        }, 500);
      }


    } else {
      this.showInfo(`Route for ${name} is Empty`);

      if (this.defaultMapDetails && this.defaultMapDetails.center) {
        this.map.setView(this.defaultMapDetails.center, this.defaultMapDetails.selectedZoom);
        this.map.setZoom(this.defaultMapDetails.selectedZoom);
      } else {
        this.setDefaultView();
      }
    }
  }

  panToActualPath() {
    const index = findIndex(this.routesDetails, function (o: any) {
      return o.name == 'Actual Path';
    });
    if (index > -1) {
      const routeData = this.routesDetails[index];
      if (this.routesDetails && this.routesDetails.length == 1) {
        routeData['show'] = true;
        this.panToSelectedPath(routeData);
      }
    }
  }

  setAgentTooltip(rowData) {
    const name = rowData?.assetDetails?.data?.name;
    const speed = parseFloat((rowData.speed || 0));
    const boxTemp = rowData?.sensorData?.temperature;
    const boxStatus = rowData?.sensorData?.coldChainBox;
    const humidity = rowData?.sensorData?.humidity;

    const popupData = []

    name && popupData.push({ label: '<img style="width: 15px; margin-right:3px" src="assets/asset-icons/licence-plate-25.png">', value: name })
    rowData?.speed && popupData.push({ label: '<i style="width: 15px; margin-right:5px" class="fas fa-tachometer-alt"></i>', value: (Math.round(speed).toString() + ' km/hr') })
    boxTemp && popupData.push({ label: '<i style="width: 15px; margin-right:5px" class="fas fa-thermometer-full"></i>', value: (boxTemp + ' °C') })
    boxStatus && popupData.push({ label: '<i style="width: 15px; margin-right:5px" class="fas fa-box"></i>', value: boxStatus })
    humidity && popupData.push({ label: '<img style="width: 12px; margin-right:5px" src="assets/asset-icons/humidity.png">', value: (humidity + ' %') })

    const subheader = this.dateFormatterToText(this.agentDetails?.locationLastUpdated)
    return getPopUpHeader('', this.agentDetails?.name || '', (subheader || '')) + showPopupData(popupData);

  }

  setDefaultView() {
    if (this.mapDetails) {
      if (this.mapDetails.zoom) {
        this.selectedZoom = this.mapDetails.zoom;
      } else {
        this.selectedZoom = 11;
      }
      if (this.mapDetails.centerLocation) {
        this.selectedLatitude = this.mapDetails.centerLocation.coordinates[0];
        this.selectedLongitude = this.mapDetails.centerLocation.coordinates[1];
      } else {
        this.selectedLatitude = 25.214017;
        this.selectedLongitude = 55.310220;
      }
      this.setMap(this.mapDetails);
      this.center = latLng(this.selectedLatitude, this.selectedLongitude);

      this.defaultMapDetails = {
        center: this.center,
        selectedZoom: this.selectedZoom,
      };
      if (this.defaultMapDetails && this.defaultMapDetails.center) {
        this.map.setView(this.defaultMapDetails.center, this.defaultMapDetails.selectedZoom);
        this.map.setZoom(this.defaultMapDetails.selectedZoom);
      }
    }

  }

  setRiderLastLocation(agentDetail) {
    if (agentDetail?.currentLocation) {
      const iconPath = (this.trackIcons?.active ? this.trackIcons.active : (this.trackIcons?.online ? this.trackIcons.online : '../assets/leaflet-icons/marker-icon-active.png'));
      this.agentMarkerLocation.setIcon(icon({
        iconSize: [35, 40],
        iconAnchor: [13, 38],
        iconUrl: iconPath,
        // shadowUrl: 'leaflet/marker-shadow.png',
        shadowAnchor: [13, 38]
      }));
      const lastCoordinates: any = [];
      lastCoordinates.push(agentDetail?.currentLocation?.latitude);
      lastCoordinates.push(agentDetail?.currentLocation?.longitude);
      this.map && this.agentMarkerLocation?.setLatLng(lastCoordinates)?.addTo(this.map);
      this.agentMarkerLocation?.bindPopup(this.setAgentLastLocTooltup(agentDetail), { offset: [5, -20], });
      this.agentMarkerLocation?.on('mouseover', () => {
        this.agentMarkerLocation.openPopup()
      })
      this.agentMarkerLocation?.on('mouseout', () => {
        this.agentMarkerLocation.closePopup()
      })
    }
  }


  setRiderMarkerLocation(rowData) {
    if (rowData?.location?.latitude && rowData?.location?.longitude) {
      const location = new LatLng(rowData?.location?.latitude, rowData?.location?.longitude);
      const lastUpdated = rowData.generatedTimeStamp;
      this.updateAgentPolyline(location, lastUpdated);
      this.agentMarkerLocation?.setLatLng(location).addTo(this.map);
      this.agentMarkerLocation?.bindPopup(this.setAgentTooltip(rowData));
      if (this._liveInfoTab.isFirstTimeAgentDataReceived) {
        this.map.panTo(location);
        setTimeout(() => {
          this.map.setZoomAround(location, 14);
        }, 500);
        this._liveInfoTab.isFirstTimeAgentDataReceived = false;
      }
    }
  }

  setAgentLastLocTooltup(agentDetail) {
    const subheader = this.dateFormatterToText(agentDetail.locationLastUpdated)
    return getPopUpHeader('Name : ', agentDetail.name || '', (subheader || '')) + showPopupData([]);
  }

  resetAgentRouteVariables() {

    this.agentMarkerLocation.remove();
    this.agentRoute.remove();
    this.agentRouteArray = [];
    this.newDataForAgentPolylineCount = 0;
  }

  buildGraph() {
    if (this.rowData) {
      this.getGraphData(this.rowData?.assetObj?.deliveryPerson?._id);
    }
  }

  camelCaseFormatter(name) {
    const text = name;
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  updateAgentPolyline(location: LatLng, lastDate) {
    const milliSeconds = new Date(lastDate).getTime();
    this.agentRouteArray.push({ location: location, milliSeconds: milliSeconds });
    if (this.agentRoute && this.agentRoute['_map']) {
      this.agentRoute.addLatLng(location);
    } else {
      this.agentRoute = polyline([location], { 'weight': 4, 'color': 'black', 'opacity': .9 });
      this.map.addLayer(this.agentRoute);
    }
  }

  dateFormator(date, format?) {
    let dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
    if (format) {
      if (format === 'date') {
        dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
      } else if (format === 'time') {
        dateFormate = moment(date).format('h:mm:ss a');
      } else if (format === 'lll') {
        dateFormate = moment(date).format('LLL');
      }
    }
    return dateFormate;
  }


  getGraphData(deviceId) {
    if (deviceId) {
      const data = orderBy(this.carGpsHistoricalData, ['updated'], ['asc']);
      this.dataForGraph = data;
    }
  }
  sendDataToPlotMarker(event) {
    const inputDateTime = event?.name;
    const foundObject = this.dataForGraph?.find((obj) => {
      const tempDate = this.dateFormator(obj.updated, 'lll');
      return tempDate.toString() === inputDateTime;
    });
    if (foundObject && foundObject?.coordinates?.length > 0) {
      let marker = L.marker({ lat: foundObject?.coordinates[0], lng: foundObject?.coordinates[1] })
      const icon = divIcon({
        html: `<div></div>`,
        className: '',
        iconSize: [40, 40]
      });
      marker.setIcon(icon);
      const tempDataArray = []
      const temperature = foundObject?.sensorData?.temperature;
      const humidity = foundObject?.sensorData?.humidity;
      temperature && tempDataArray.push({ label: 'Temp in °C : ', value: foundObject?.sensorData['temperature'].toFixed(2) })
      humidity && tempDataArray.push({ label: 'Humidity in % : ', value: foundObject?.sensorData['humidity'].toFixed(2) })
      marker.bindPopup(
        getPopUpHeader('Sensor stats', '', this.dateFormator(foundObject?.updated, 'lll')) + showPopupData([{ label: 'Temp in °C : ', value: foundObject?.sensorData['temperature']?.toFixed(2) }])
        , { maxWidth: 1000, offset: [0, -8] });
      setTimeout(() => {
        marker?.openPopup()
      }, 500);

      marker.addTo(this.map);
      this.map?.panTo({ lat: foundObject?.coordinates[0], lng: foundObject?.coordinates[1] });
      setTimeout(() => {
        this.map?.setZoom(17);
      }, 1000);
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Lat Lng not found' });
    }
  }


  getDateConvertor(date) {
    let cDate = this.dateFormatter.convertDateToSelectedTimezone(date, 'DD/MM/YY');
    return cDate
  }

  toggleMapDialogVisibility() {
    this.movingMarkerIntervalID() && clearInterval(this.movingMarkerIntervalID())
    this.movingMarker?.stop();
  }



  retainMovingMarker() {
    const { lat, lng } = this.movingMarker?.getMarker()._latlng || {}
    lat && lng && this.movingMarker?.remove();
    this.movingMarker = (lat && lng) ? this.createMovingMarker([lat, lng], [lat, lng]) : this.movingMarker;
    setTimeout(() => {
      this.playActualPath() && this.startPathPlaying();
    }, 0)
  }

  legendClicked(l) {
    l.isActive = !l.isActive;
    this.routeLegendChanged.emit(this.legendList);
  }

  // prime ng panel resize event
  onPanelResize() {
    setTimeout(() => {
      this.map.invalidateSize(true);
    }, 100);
  }

  showInfo(msg) {
    this.messageService?.clear();
    this.messageService.add({ severity: 'info', summary: 'Info', detail: msg });
  }

  reloadMap() {
    if (this.mapDetails) {
      this.setMap(this.mapDetails);
    }
    setTimeout(()=>{
        this.map.invalidateSize(true);
    }, 100);
  }

  dateFormatterToText(date) {
    this.lang.updateMomentLanguage(moment);
    return moment(date).calendar();
  }

  plotEventsOnMap(e) {
    this.lang.updateMomentLanguage(moment);
    const generatedTimeStamp = moment(e?.generatedTimeStamp).calendar();
    const obj = { name: e?.name, status: e?.status };
    if (e.coordinates && e.coordinates.length > 0) {
      const newMarker = marker(e.coordinates,
        {
          icon: icon({
            iconSize: [15, 15],
            iconUrl: this.commonService.getIconsForEvents(obj)
          }),
          autoPan: true,
        });

      newMarker?.setLatLng(e?.coordinates)?.addTo(this?.map)?.bindPopup(
        getPopUpHeader('', e.description || '', (generatedTimeStamp || '')) + showPopupData([])
        , { maxWidth: 1000 });

      newMarker?.on('mouseover', () => {
        newMarker.openPopup()
      })
      newMarker?.on('mouseout', () => {
        newMarker.closePopup()
      })
      this.eventsMarkers = { ...(this.eventsMarkers || {}), [e._id]: newMarker }

    }
  }

  resetLegendActiveVaues() {
    const isAgent = this.agentDetails ? true : false
    if (isAgent) {
      forEach(this.legendList, (leg) => {
        if (leg.type === 'actual') {
          leg.isActive = true;
        } else {
          leg.isActive = false;
        }
      });
    } else {
      this.legendList.splice(0, 1);
      this.legendList[0].isActive = true;
    }

  }

  plotEvents(events) {
    forEach(events, (eventObj) => {
      this.bindEventIconForTableData(eventObj);
      this.plotEventsOnMap(eventObj);
    });
  }

  bindEventIconForTableData(data) {
    data['eventIcon'] = this.commonService.getIconsForEvents({ name: data.name, status: data.status });
  }

  removeplottedEvents() {
    for (let id in (this.eventsMarkers || {})) {
      this.eventsMarkers[id]?.remove()
    }
    this.eventsMarkers = {};
  }

  private removeLayer() {
    forEach(this.routes, r => {
      r?.route?.remove();
    });
    this.allDeliveryMarkers.forEach(markerObj => {
      markerObj.remove();
    });
    this.allDeliveryMarkers = [];
    this.allDeliveryLocMarkers = {};

    this.routes = [];
  }

  private plotPolyLine() {

    const isRoutesAvailable = this.routesDetails && this.routesDetails.length > 0;

    if (isRoutesAvailable) {
      forEach(this.routesDetails, routeData => {
        if (routeData && routeData.show) {
          this.panToSelectedPath(routeData);
        }
      });
    } else {
      if (this.defaultMapDetails && this.defaultMapDetails.center) {
        this.map?.setView(this.defaultMapDetails.center, this.defaultMapDetails.selectedZoom);
        this.map?.setZoom(this.defaultMapDetails.selectedZoom);
      } else {
        this.setDefaultView();
      }
      this.updateLegendList(null, true)
    }

  }

  addEventListenerForPopup() {
    document.querySelector(".reject-popup-btn")?.addEventListener("click", () => {
      this.popupAction('reject')
    });
    document.querySelector(".accept-popup-btn")?.addEventListener("click", () => {
      this.popupAction('accept')
    });
  }

  popupAction(action) {
    if (action === 'accept') {
      this.sendLatLngValues.emit({ lat: this.foucusedLatLng?.lat, lng: this.foucusedLatLng?.lng, assetId: this.focusedAssetId });
    }
    else if (action === 'reject') { this.plotIcons(true); }
  }

  removeActualPathMarkers() {
    this.actualPathMarkers.forEach(marker => this.map.removeLayer(marker));
    this.actualPathMarkers.length = 0;
  }

  togglePointsOnRoute(event) {
    if (!event) {
      this.removeActualPathMarkers();
    }
    this.plotPolyLine();
  }

  togglePathPlay(status: boolean) {
    if (status) {
      this.startPathPlaying()
    } else {
      clearInterval(this.movingMarkerIntervalID());
      const { lat, lng } = this.movingMarker?.getMarker()._latlng || {}
      this.movingMarker?.remove();
      this.movingMarker = (lat && lng) ? this.createMovingMarker([lat, lng], [lat, lng]) : this.movingMarker;
      this.map && this.movingMarker?.addTo(this.map)
    }
    this.playActualPath.set(status);
  }

  restartPlay() {
    this.pathMovingIndex.set(0);
    clearInterval(this.movingMarkerIntervalID())
    this.startPathPlaying();
    this.playActualPath.set(true)
  }

  createMovingMarker(startLatLng: number[], endLatLng: number[]) {
    return L['moveMarker'](
      [
        startLatLng,
        endLatLng
      ],
      {
        animate: false,
        hidePolylines: true,
        removeFirstLines: true,
      },
      {
        animate: true,
        hideMarker: false,
        speed: 400 * this.speedMultiplicator(),
        followMarker: true,
        icon: icon({
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          iconUrl: 'assets/leaflet-icons/marker-icon.png',
          shadowUrl: 'leaflet/marker-shadow.png'
        })
      },
      {}
    )
  }

  startPathPlaying() {
    if (!this.dataForGraph?.length) return;

    const startLatLng = this.dataForGraph?.[this.pathMovingIndex() + 0]?.coordinates;
    const endLatLng = this.dataForGraph?.[this.pathMovingIndex() + 1]?.coordinates;
    this.movingMarker?.remove()
    this.movingMarker = this.createMovingMarker(startLatLng, endLatLng);
    this.map && this.movingMarker?.addTo(this.map)

    this.movingMarkerIntervalID.set(
      setInterval(() => {
        const currentMarker = this.movingMarker?.getMarker()
        if (!currentMarker) return
        const { lat, lng } = currentMarker._latlng || {}
        if (endLatLng[0] === lat && endLatLng[1] === lng) {
          this.movingMarkerIntervalID() && clearInterval(this.movingMarkerIntervalID());
          if (this.pathMovingIndex() !== (this.dataForGraph.length - 2)) {
            this.pathMovingIndex.set(this.pathMovingIndex() + 1);
            this.startPathPlaying()
          } else {
            this.pathMovingIndex.set(0);
          }
        }
      }, 50))
  };


  getDynamicRadius() {
    const currentZoom = this.map.getZoom();
    if (currentZoom >= 15) {
      return 4;
    } else if (currentZoom >= 10) {
      return 3;
    } else {
      return 1;
    }
  }


  ngOnDestroy() {
    this.userMapOptionsSub$?.unsubscribe();
    this.removeActualPathMarkers();
  }


 

  triggerRowClick(complianceEvent: IComplianceEvent) {
    const timeValue = this.dateFormator(complianceEvent?.generatedTimeStamp, 'lll');
    const temperatureValue = complianceEvent?.sensorData.temperature



    let marker = L.marker({ lat: complianceEvent?.coordinates[0], lng: complianceEvent?.coordinates[1] })
    const icon = divIcon({
      html: `<div></div>`,
      className: '',
      iconSize: [40, 40]
    });
    marker.setIcon(icon);
    marker.bindPopup(
      getPopUpHeader(
        'Sensor stats', '',
        timeValue) +
      showPopupData([
        { label: 'Range : ', value: complianceEvent.subType },
        { label: 'Temp in °C : ', value: temperatureValue?.toFixed(2) },
      ])
      , { maxWidth: 1000, offset: [0, -8] });
    this.map && marker.addTo(this.map);
    marker.openPopup()

    this.map?.panTo({ lat: complianceEvent?.coordinates[0], lng: complianceEvent?.coordinates[1] });
    setTimeout(() => {
      this.map?.setZoom(17);
    }, 1000);
  }








  findEarliestTimestamp(obj: Record<string, string>): { key: string; timestamp: string } {
    let earliestKey: string | null = null;
    let earliestTimestamp: Date | null = null;

    for (const [key, value] of Object.entries(obj)) {
      const currentTimestamp = new Date(value);

      // Check if the date is valid
      if (isNaN(currentTimestamp.getTime())) {
        console.error(`Invalid timestamp: '${value}' for key '${key}'`);
        continue;
      }

      if (earliestTimestamp === null || currentTimestamp < earliestTimestamp) {
        earliestTimestamp = currentTimestamp;
        earliestKey = key;
      }
    }

    if (earliestTimestamp && earliestKey) {
      return { key: earliestKey, timestamp: earliestTimestamp.toISOString() };
    }

    return null; // Return null if no valid timestamps found
  }

}


export interface RouteLegend {
  'label': string,
  'color': string,
  'isActive': boolean,
  'type': string
}

export interface ILocationPayload {
  lat: number;
  lng: number;
  assetId: string;
  geoFenceRadius?: number;
  label?: String;
}

export interface IComplianceEvent {
  subType: string;
  _id: string;
  coordinates: Array<number>;
  generatedTimeStamp: string;
  sensorData: {
    temperature: number
  }
}