import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDaterangepickerMd} from '../date-range-picker';
import {NgxEchartsModule} from 'ngx-echarts';
// import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {AlertPopupComponent} from './alert-popup/alert-popup.component';
import {RightSidebarComponent} from './right-sidebar/right-sidebar.component';
// import {NgxPaginationModule} from 'ngx-pagination';
import {LayoutModule} from '@angular/cdk/layout';
// import {GaugeModule} from 'angular-gauge';
import {AbilityModule} from '@casl/angular';
import {RightSiderToAddComponent} from './right-sider-to-add/right-sider-to-add.component';
import {AssetEditFromComponent} from './add-asset-list/asset-edit-from/asset-edit-from.component';
import {GroupsForAddingComponent} from './groups-for-adding/groups-for-adding.component';
import {AssetFormComponent} from './add-asset-list/asset-form/asset-form.component';
import {DropdownOnhoverComponent} from './dropdown-onhover/dropdown-onhover.component';
import {NotificationEventComponent} from './notification-event/notification-event.component';
import {
  NotificationEventCreateFormComponent
} from './notification-event-create-form/notification-event-create-form.component';
import {EventFormComponent} from './event-form/event-form.component';
import {IotzenPillsComponent} from './iotzen-pills/iotzen-pills.component';
import {EChartsComponent} from './e-charts/e-charts.component';
import {LoaderComponent} from '../standalone-components/loader/loader.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {VideoFeedComponent} from './video-feed/video-feed.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DetailsCardComponent} from './details-card/details-card.component';

import {ThingCardComponent} from './thing-card/thing-card.component';
import {ThingTableComponent} from './thing-table/thing-table.component';
import {GroupBadgeComponent} from './group-badge/group-badge.component';
import {AssetLinkedCountComponent} from './asset-linked-count/asset-linked-count.component';
import {GroupCardComponent} from './group-card/group-card.component';
import {MapEventsActionsComponent} from './map-events-actions/map-events-actions.component';
import {AssetsForLinkingComponent} from './assets-for-linking/assets-for-linking.component';
import {SearchForAssetComponent} from './search-for-asset/search-for-asset.component';
import {LeafletModule} from '@bluehalo/ngx-leaflet';
import {RouterModule} from '@angular/router';
import {ThermometerGaugeComponent} from './thermometer-gauge/thermometer-gauge.component';
import {CarMeterGaugeComponent} from './car-meter-gauge/car-meter-gauge.component';
import {ServicesForLinkingComponent} from './services-for-linking/services-for-linking.component';
import {SearchForServiceComponent} from './search-for-service/search-for-service.component';
import {ServerDetailsCardComponent} from './server-details-card/server-details-card.component';
import {SysStatsGraphComponent} from './sys-stats-graph/sys-stats-graph.component';
import {ServerLiveStatsComponent} from './server-live-stats/server-live-stats.component';
import {CarStatusGraphComponent} from './car-status-graph/car-status-graph.component';
import {CarEventTableComponent} from './car-event-table/car-event-table.component';
import {AddAssetListComponent} from './add-asset-list/add-asset-list.component';
import {GroupsForLinkingComponent} from './groups-for-linking/groups-for-linking.component';
import {AddAssetToGroupComponent} from './add-asset-to-group/add-asset-to-group.component';
import {TimezoneFilterPipe} from '../../pipes/timezone.filter.pipe';
import {OrderByPipe} from '../../pipes/orderBy.filter.pipe';
import {CustomRightStaticTooltipComponent} from './custom-right-static-tooltip/custom-right-static-tooltip.component';
import {SearchBoxComponent} from './search-box/search-box.component';
import {Ability, PureAbility} from '@casl/ability';
import {MaterialModule} from './material.module';
import {NotificationComponent} from './notification/notification.component';
import {VisitorFormComponent} from './visitor-form/visitor-form.component';
import {LineWithCountChartCardComponent} from './line-with-count-chart-card/line-with-count-chart-card.component';
import {StaticDataDynamicFormComponent} from './static-data-dynamic-form/static-data-dynamic-form.component';
import {
  StaticDataDynamicFormModalComponent
} from './static-data-dynamic-form-modal/static-data-dynamic-form-modal.component';
import {MonthPickerComponent} from './month-picker/month-picker.component';
import {EchartDoughnutChartComponent} from './echart-doughnut-chart/echart-doughnut-chart.component';
import {FilterPipe} from '../../pipes/filter.pipe';
import {EchartJsPieChartComponent} from './echart-js-pie-chart/echart-js-pie-chart.component';
import {CalendarDateFormatter, CalendarModule, CalendarMomentDateFormatter, DateAdapter} from 'angular-calendar';
import {momentAdapterFactory} from '../app.module';
// import { QuicklinkModule } from 'ngx-quicklink';
import {CameraWallComponent} from './camera-wall/camera-wall.component';
import {FaceDetectedComponent} from './face-detected/face-detected.component';
import {FaceDetectedCardComponent} from './face-detected/face-detected-card/face-detected-card.component';
import {FaceMatchSidebarComponent} from '../security/face-match-sidebar/face-match-sidebar.component';
import {FaceMatchCardComponent} from './face-match-card/face-match-card.component';
import {TrackableCarsListComponent} from './trackable-cars-list/trackable-cars-list.component';
import {TimelineComponent} from './timeline/timeline.component';

import {TrackerCarFilterPipe} from '../../pipes/tracker.car.filter.pipe';
import {GeoFenceFilterPipe} from '../../pipes/geoFence.filter.pipe';
import {NamingConventionFilterPipe} from '../../pipes/namingConvention.pipe';

import {ChartFilterMenuComponent} from './chart-filter-menu/chart-filter-menu.component';
import {RoleAssetAuthFormComponent} from './role-asset-auth-form/role-asset-auth-form.component';

import {PrimengTableComponent} from './primeng-table/primeng-table.component';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {CalendarModule as primengCalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DropdownModule} from 'primeng/dropdown';
import {ColorPickerModule} from 'primeng/colorpicker';

import {PaginatorModule} from 'primeng/paginator';
import {SplitterModule} from 'primeng/splitter';
import {MenuModule} from 'primeng/menu';
import {CascadeSelectModule} from 'primeng/cascadeselect';

/*------------------new added----------------------*/
import {RippleModule} from 'primeng/ripple';
import {DialogModule} from 'primeng/dialog';
import {TimelineModule} from 'primeng/timeline';
import {CardModule} from 'primeng/card';
import {SplitButtonModule} from 'primeng/splitbutton';
import {FileUploadModule as FileUploadModulePrimeNG} from 'primeng/fileupload';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TooltipModule} from 'primeng/tooltip';
import {ChipModule} from 'primeng/chip';
import {TagModule} from 'primeng/tag';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {AccordionModule} from 'primeng/accordion';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ConfirmationService, MessageService} from 'primeng/api';
import {GalleriaModule} from 'primeng/galleria';
import {InputTextareaModule} from 'primeng/inputtextarea';

import {
  PrimeTableRowExpansionComponent
} from './prime-table-row-expansion/prime-table-row-expansion/prime-table-row-expansion.component';
import {HorizontalCustomTimelineComponent} from './horizontal-custom-timeline/horizontal-custom-timeline.component';
import {PrimeMapJourneyModal} from './prime-map-journey-modal/prime-map-journey-modal';
import {PrimeMsgBannerComponent} from './prime-msg-banner/prime-msg-banner.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ReportsSettingsComponent} from './reports-settings/reports-settings.component';
import {SkeletonModule} from 'primeng/skeleton';
import {ChipsModule} from 'primeng/chips';
import * as Sentry from '@sentry/angular';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {NewLeafletMapComponent} from './new-leaflet-map/new-leaflet-map.component';
import {RightPanelComponent} from './right-panel/right-panel.component';
import {BatteryIconComponent} from '../standalone-components/battery-icon/battery-icon.component';
import {FirstUpperCaseFilter} from '../../pipes/uppercase.first.latter.pipe';
import {LocationFilterPipe} from '../../pipes/location.filter.pipe';

import {MapMarkerComponent} from '../standalone-components/map-marker/map-marker.component';
import {CustomTranslateHttpLoader} from '../../services/custom.translate.http.loader';
import {ConfigService} from '../../services/config.service';
import {SidebarModule} from 'primeng/sidebar';
import {TabMenuModule} from 'primeng/tabmenu';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster';
import {EditIconComponent} from '../standalone-components/edit-icon/edit-icon.component';
import {SpeedDialModule} from 'primeng/speeddial';
import {DividerModule} from 'primeng/divider';
import {StepsModule} from 'primeng/steps';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {EventsFilterPipe} from '../../pipes/events.filter.pipe';
import {BadgeModule} from "primeng/badge";
import {provideClientHydration, withNoHttpTransferCache} from "@angular/platform-browser";
import {TreeSelectModule} from 'primeng/treeselect';
import {CameraModuleComponent} from '../standalone-components/camera-module/camera-module.component';
import {CapitalizeFirstPipe} from '../../pipes/capitalize-first.pipe';
import {CircularLoaderComponent} from '../standalone-components/circular-loader/circular-loader.component';
import { MultipleAssetsCreationComponent } from './multiple-assets-creation/multiple-assets-creation.component';
import { AuditDetailsConfigComponent } from '../standalone-components/audit-details-config/audit-details-config.component';
import { DaysViewInTableComponent } from '../standalone-components/days-view-in-table/days-view-in-table.component';
import { ConsignmentEventGeofenceMapComponent } from '../standalone-components/consignment-event-geofence-map/consignment-event-geofence-map.component';
import { NonComplianceEventsTableComponent } from '../standalone-components/non-compliance-events-table/non-compliance-events-table.component';
import { NonComplianceEventsDialogComponent } from '../standalone-components/non-compliance-events-dialog/non-compliance-events-dialog.component';
import { RelativeDataViewInTableComponent } from '../standalone-components/relative-data-view-in-table/relative-data-view-in-table.component';
import { HeaderSubheaderViewInTableComponent } from '../standalone-components/header-subheader-view-in-table/header-subheader-view-in-table.component';
import { statusToColorPipe } from '../../pipes/statusToColor.pipe';
import { MultipleValuesInTableComponent } from '../standalone-components/multiple-values-in-table/multiple-values-in-table.component';
import { CopyLinkModalComponent } from '../standalone-components/copy-link-modal/copy-link-modal.component';


@NgModule({
  declarations: [
    AlertPopupComponent,
    RightSidebarComponent,
    RightSiderToAddComponent,
    AssetEditFromComponent,
    GroupsForAddingComponent,
    AssetFormComponent,
    DropdownOnhoverComponent,
    NotificationEventComponent,
    NotificationEventCreateFormComponent,
    EventFormComponent,
    IotzenPillsComponent,
    EChartsComponent,
    VideoFeedComponent,
    MapEventsActionsComponent,
    AssetsForLinkingComponent,
    DetailsCardComponent, // Used in Notifications & Settings

    // things
    ThingCardComponent,
    ThingTableComponent,
    AssetLinkedCountComponent,
    SearchForAssetComponent,
    ThermometerGaugeComponent,
    CarMeterGaugeComponent,
    PrimengTableComponent,
    PrimeTableRowExpansionComponent,
    PrimeMsgBannerComponent,
    ReportsSettingsComponent,

    // Services
    ServerDetailsCardComponent,
    AddAssetListComponent,

    // right-sidebar
    SysStatsGraphComponent,
    ServicesForLinkingComponent,
    SearchForServiceComponent,
    ServerLiveStatsComponent,
    CarStatusGraphComponent,
    CarEventTableComponent,

    // Groups Related
    GroupsForLinkingComponent,
    GroupCardComponent,
    AddAssetToGroupComponent,
    GroupBadgeComponent,

    // Fleet
    TimezoneFilterPipe,
    OrderByPipe,
    TrackerCarFilterPipe,
    CustomRightStaticTooltipComponent,
    SearchBoxComponent,
    RightPanelComponent,
    NewLeafletMapComponent,

    // Settings
    DetailsCardComponent,
    NotificationComponent,

    VisitorFormComponent,
    LineWithCountChartCardComponent,
    MonthPickerComponent,
    StaticDataDynamicFormComponent,
    StaticDataDynamicFormModalComponent,

    EchartDoughnutChartComponent,
    FilterPipe,

    EchartJsPieChartComponent,
    CameraWallComponent,
    TrackableCarsListComponent,
    FaceDetectedComponent,
    FaceDetectedCardComponent,
    FaceMatchSidebarComponent,
    FaceMatchCardComponent,
    TimelineComponent,
    ChartFilterMenuComponent,
    PrimeMapJourneyModal,
    FirstUpperCaseFilter,
    LocationFilterPipe,
    RoleAssetAuthFormComponent,
    CapitalizeFirstPipe,
    MultipleAssetsCreationComponent
  ],
  imports: [
    Sentry.TraceModule,
    CommonModule,
    NgxDaterangepickerMd.forRoot(),
    LayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    AbilityModule,
    // NgxEchartsModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    FormsModule,
    DragDropModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    RouterModule,

    // primeng modules
    SidebarModule,
    TabMenuModule,
    TableModule,
    ButtonModule,
    SelectButtonModule,
    MultiSelectModule,
    MenuModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    SplitterModule,
    SpeedDialModule,
    DividerModule,
    CascadeSelectModule,

    // standalone components
    BatteryIconComponent,
    MapMarkerComponent,
    CameraModuleComponent,
    DaysViewInTableComponent,
    MultipleValuesInTableComponent,
    ConsignmentEventGeofenceMapComponent,
    NonComplianceEventsTableComponent,
    NonComplianceEventsDialogComponent,
    RelativeDataViewInTableComponent,
    HeaderSubheaderViewInTableComponent,
    CopyLinkModalComponent,

    // QuicklinkModule,
    primengCalendarModule,
    ProgressSpinnerModule,


    RippleModule,
    DialogModule,
    TimelineModule,
    CardModule,
    PaginatorModule,
    SplitButtonModule,
    FileUploadModulePrimeNG,
    OverlayPanelModule,
    InputTextModule,
    InputSwitchModule,
    TooltipModule,
    ChipModule,
    TagModule,
    TabViewModule,
    ToastModule,
    SelectButtonModule,
    AccordionModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    GalleriaModule,
    SkeletonModule,
    ChipsModule,
    AvatarModule,
    AvatarGroupModule,
    InputTextareaModule,
    MenuModule,
    ToggleButtonModule,
    TreeSelectModule,
    BadgeModule,
    ColorPickerModule,
    AuditDetailsConfigComponent,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    CalendarModule.forRoot(
      {provide: DateAdapter, useFactory: momentAdapterFactory},
      {
        dateFormatter: {provide: CalendarDateFormatter, useClass: CalendarMomentDateFormatter},
      }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, ConfigService]
      }
    }),
    EditIconComponent,
    BadgeModule,
    NgOptimizedImage,
    CircularLoaderComponent,
    LoaderComponent,
    NamingConventionFilterPipe,
    HorizontalCustomTimelineComponent,
    GeoFenceFilterPipe,
    EventsFilterPipe,
    statusToColorPipe
  ],
  exports: [
    FirstUpperCaseFilter,
    LocationFilterPipe,
    FormsModule,
    CommonModule,
    LayoutModule,
    ReactiveFormsModule,
    MaterialModule,
    AbilityModule,
    NgxEchartsModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    NgxDaterangepickerMd,
    TranslateModule,
    DragDropModule,
    // OrderFormComponent,
    LeafletModule,
    LeafletMarkerClusterModule,
    RouterModule,
    CalendarModule,
    primengCalendarModule,
    ProgressSpinnerModule,
    // QuicklinkModule,

    EChartsComponent,
    AlertPopupComponent,
    RightSidebarComponent,
    RightSiderToAddComponent,
    AssetEditFromComponent,
    GroupsForAddingComponent,
    AssetFormComponent,
    DropdownOnhoverComponent,
    NotificationEventComponent,
    NotificationEventCreateFormComponent,
    EventFormComponent,
    IotzenPillsComponent,
    VideoFeedComponent,
    MapEventsActionsComponent,
    AssetsForLinkingComponent,
    SearchForAssetComponent,
    ChartFilterMenuComponent,
    DetailsCardComponent,
    NewLeafletMapComponent,
    RightPanelComponent,
    RoleAssetAuthFormComponent,

    // things
    ThingCardComponent,
    ThingTableComponent,
    AssetLinkedCountComponent,
    ThermometerGaugeComponent,
    CarMeterGaugeComponent,
    PrimengTableComponent,
    PrimeTableRowExpansionComponent,
    PrimeMapJourneyModal,
    PrimeMsgBannerComponent,
    ReportsSettingsComponent,


    // Services
    ServerDetailsCardComponent,
    AddAssetListComponent,

    SysStatsGraphComponent,
    ServicesForLinkingComponent,
    SearchForServiceComponent,
    ServerLiveStatsComponent,
    CarStatusGraphComponent,
    CarEventTableComponent,

    // Groups
    GroupsForLinkingComponent,
    GroupCardComponent,
    AddAssetToGroupComponent,
    GroupBadgeComponent,
    TimezoneFilterPipe,
    OrderByPipe,
    TrackerCarFilterPipe,
    GeoFenceFilterPipe,
    EventsFilterPipe,
    CustomRightStaticTooltipComponent,
    SearchBoxComponent,

    // Settings
    DetailsCardComponent,
    NotificationComponent,

    VisitorFormComponent,
    LineWithCountChartCardComponent,
    MonthPickerComponent,
    StaticDataDynamicFormComponent,
    StaticDataDynamicFormModalComponent,

    EchartDoughnutChartComponent,
    FilterPipe,

    EchartJsPieChartComponent,
    CameraWallComponent,
    TrackableCarsListComponent,
    FaceDetectedComponent,
    FaceDetectedCardComponent,
    FaceMatchSidebarComponent,
    FaceMatchCardComponent,
    TimelineComponent,
    // primeng modules
    SidebarModule,
    TabMenuModule,
    TableModule,
    MenuModule,
    ButtonModule,
    SelectButtonModule,
    MultiSelectModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    RippleModule,
    DialogModule,
    TimelineModule,
    CardModule,
    PaginatorModule,
    SplitButtonModule,
    FileUploadModulePrimeNG,
    OverlayPanelModule,
    InputTextModule,
    InputSwitchModule,
    TooltipModule,
    ChipModule,
    TagModule,
    TabViewModule,
    ToastModule,
    SelectButtonModule,
    AccordionModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    GalleriaModule,
    SkeletonModule,
    ChipsModule,
    AvatarModule,
    AvatarGroupModule,
    SpeedDialModule,
    DividerModule,
    CascadeSelectModule,
    SplitterModule,
    StepsModule,
    ColorPickerModule,
    CapitalizeFirstPipe,
    MultipleAssetsCreationComponent,
    
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [ConfirmationService, MessageService, MapMarkerComponent]
})
export class SharedModule {
  static forChild() {
    return {
      ngModule: SharedModule,
      providers: [
        provideClientHydration(withNoHttpTransferCache()),
        {provide: Ability, useValue: new Ability()},
        {provide: PureAbility, useExisting: Ability}
      ]
    };
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient, config: ConfigService) {
  return new CustomTranslateHttpLoader(http, config);
}
