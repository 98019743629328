import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, forkJoin, map, mergeMap, of, switchMap, withLatestFrom } from "rxjs";
import { dashboardAction } from ".";
import { dashboardService } from "../dashboard.service";
import { Assigned, DMSLogisticsCostAnalytics, ICountStatus, IDmsLogisticsCostCardResponse, ILocationList, IMmdDeliveredAnalyticsData, IMmdDeliveredGroupAnalyticsData, IO2DOverviewAnalytics, IQueueInTrend } from "../interface";
import { LangUtilService } from "../../../app/util/lang-util.service";
import {  boxStatusGraph, dateFormator, findIndividualNameFormArr, orderSource, plotGraph, plotSpecsGraph } from "./dashboard.helper.service";
import { keys, orderBy } from "lodash";
import _ from "lodash";
import { EChartSeries } from "../../../app/shared/e-charts/e-charts-data-type";
import { ISensorData } from "../../../state/Fleet/interface";
import { resetGraphTypePillValueRequired } from "./dashboard.action";
import { AppState } from "../../../state/app.state";
import { Store } from "@ngrx/store";
import { AssetsService } from "../../../state/Assets/assets.service";
import { vehiclesSelector } from "../../../state/Assets/assets.selector";
import { mmdDeliveredAnalytics } from "./dashboard.selector";
import { CONSTANT } from "../../../config/constant";
import { TrackingService } from "../../../services/tracking.service";

const GRAPH_KEYS = CONSTANT.GRAPH_KEYS;

@Injectable()

export class DashboardEffect {

    constructor( 
      private actions$ : Actions,
      private dataService : dashboardService,
      private lang : LangUtilService,
      private store : Store<AppState>,
      private assetsService:AssetsService,
      private trackingService: TrackingService
    ){}
    
    getO2dOrderStatsDataRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getO2dOrderStatsDataRequired),
        mergeMap((action) => {
            if (action.providedOrderStatus !== 'total') {
              return this.dataService.effectGetAllAssignmentsCountForOrderStatsByGivenStatus(action.obj).pipe(
                switchMap((res: ICountStatus) => {
                  return of(dashboardAction.getO2dOrderStatsDataSuccess({ res, providedOrderStatus: action.providedOrderStatus }));
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
            }
             else {
              return this.dataService.effectGetTotalOrdersCount(action.obj).pipe(
                switchMap((res: ICountStatus) => {
                  return of(dashboardAction.getO2dOrderStatsDataSuccess({ res, providedOrderStatus: 'total' }));
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
            }
          // }
        })
      )
    );
    getOrderByTimeSlotRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getOrderByTimeSlotRequired),
        switchMap(({obj,title,graphSettings}) => {
            return this.dataService.effectGetCountsByOrderSource(obj).pipe(
              switchMap((res) => {
                const successActionData = {graphKey: obj?.graphKey,graphSettings,res,title,pillValue:null}
                switch (obj?.graphKey) {
                  case 'mmd':
                    return of(dashboardAction.getMmdDashboardByTimeSlotSuccess({ res, title, action: obj }));
                  case GRAPH_KEYS.LMD.ORDERS_BY_TIME_SLOTS_DASHBOARD:
                    return of(dashboardAction.getLmdDashboardByTimeSlotSuccess(successActionData));
                  case GRAPH_KEYS.MMD.CONSIGNMENTS_BY_TIME_SLOTS_ANALYTICS:
                    successActionData.pillValue = obj.pillValue
                    return of(dashboardAction.getCountByOrderSourceMmdAnalyticsSuccess(successActionData));
                  case GRAPH_KEYS.LMD.COUNTS_BY_ORDER_SOURCE_ANALYTICS:
                    successActionData.pillValue = obj.pillValue
                    return of(dashboardAction.getCountByOrderSourceLmdAnalyticsSuccess(successActionData));
                  case GRAPH_KEYS.O2D.TIME_SLOTS_DASHBOARD:
                    return of(dashboardAction.getOrderByTimeSlotSuccess(successActionData));
                  default:
                    return;
                }
                
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    getMmdOrderTotalOrderCount$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getMmdOrderTotalOrderCount),
        switchMap((action) => {
            return this.dataService.effectGetTotalOrderCounts(action.obj,'dashboard').pipe(
              switchMap((res:any) => {
                return of(dashboardAction.getMmdOrderStatsDataSuccess({ res: res , providedOrderStatus:action.providedOrderStatus}));
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    getOrderByTotalOrderGraphRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getOrderByTotalOrderGraphRequired),
        switchMap((action) => {
            return this.dataService.effectGetTotalOrderCountsForGraph(action.obj).pipe(
              switchMap((res) => {
                return of(dashboardAction.getMmdOrderTotalOrdeGraphCountSuccess({graphKey: action.obj?.graphKey,graphSettings:action.graphSettings, res: res , title:action.title,action:action?.obj}));
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );

    getOrderByAssignStatusRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getOrderByAssignStatusRequired),
        switchMap((action) => {
            return this.dataService.effectGetOrdersByAssignedStatus(action.obj).pipe(
              switchMap((res) => {
                return of(dashboardAction.getOrderByAssignStatusSuccess({graphKey: action.obj?.graphKey,graphSettings:action.graphSettings, res: res , title:action.title}));
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    getOrderByDeliveryStatusRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getOrderByDeliveryStatusRequired),
        switchMap(({obj,title,graphSettings}) => {
            return this.dataService.effectGetOrdersByDeliveryStatus(obj).pipe(
              switchMap((res) => {
                const successActionData = {graphKey: obj?.graphKey,graphSettings,res,title,pillValue:null}
                switch (obj?.graphKey) {
                  case 'mmd':
                    return of(dashboardAction.getMmdDashboardByDeliveryStatusSuccess({ res, title, action: obj }));
                  case GRAPH_KEYS.LMD.ORDERS_BY_DELIVERY_STATUS_DASHBOARD:
                    return of(dashboardAction.getLmdDashboardByDeliveryStatusSuccess(successActionData));
                  case GRAPH_KEYS.MMD.CONSIGNMENTS_BY_DELIVERY_STATUS_ANALYTICS:
                    successActionData.pillValue = obj.pillValue 
                    return of(dashboardAction.getMmdAnalyticsByDeliveryStatusSuccess(successActionData));
                  case GRAPH_KEYS.LMD.ORDERS_BY_DELIVERY_STATUS_ANALYTICS:
                    successActionData.pillValue = obj.pillValue 
                    return of(dashboardAction.getLmdAnalyticsByDeliveryStatussSuccess(successActionData));
                  case GRAPH_KEYS.O2D.DELIVERY_STATUS_DASHBOARD:
                    return of(dashboardAction.getOrderByDeliveryStatusSuccess(successActionData));
                
                  default:
                    return;
                }
                
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    getOrderByQueueRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getOrderByQueueRequired),
        switchMap((action) => {
            return this.dataService.effectQueueSizeTrend(action.obj).pipe(
              switchMap((res : IQueueInTrend) => {
                if(action.obj?.graphKey === GRAPH_KEYS.LMD.ORDER_IN_QUEUE_DASHBOARD){
                  return of(dashboardAction.getLmdOrderByQueueSuccess({deliveryPersonGraphSettings:action.deliveryPersonGraphSettings,orderGraphSettings:action.orderGraphSettings, res: res,title:action.title }));
                }else if(action.obj?.graphKey ===  GRAPH_KEYS.LMD.ORDER_IN_QUEUE_ANALYTICS){
                  return of(dashboardAction.getLmdAnalyticsByQueueSuccess({deliveryPersonGraphSettings:action.deliveryPersonGraphSettings,orderGraphSettings:action.orderGraphSettings, res: res,title:action.title, pillValue:action?.obj?.pillValue }));
                }else{
                  return of(dashboardAction.getOrderByQueueSuccess({ res: res,title:action.title }));
                }
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
          }
        )
      )
    );
    getCountInQueueRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getCountInQueueRequired),
        switchMap((action) => {
            return this.dataService.effectGetAssetsCountInQueue(action.obj).pipe(
              switchMap((res : ICountStatus) => {
              if(action?.obj?.graphKey === GRAPH_KEYS.LMD.ORDER_IN_QUEUE_DASHBOARD){
                return of(dashboardAction.getLmdCountInQueueSuccess({ res: res }));
              }else{
                return of(dashboardAction.getCountInQueueSuccess({ res: res }));
              }
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    getDeliveryInCountRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getDeliveryInCountRequired),
        switchMap((action) => {
            return this.dataService.effectGetAssetsCountInQueue(action.obj).pipe(
              switchMap((res : ICountStatus) => {
                if(action?.obj?.graphKey === GRAPH_KEYS.LMD.DELIVERY_PERSONS_IN_QUEUE_DASHBOARD){
                return of(dashboardAction.getLmdDeliveryInCountSuccess({graphSettings:action.graphSettings, res: res,title: action.title }));
                }
                return of(dashboardAction.getDeliveryInCountSuccess({ res: res,title: action.title }));
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    getRiderWisePerformanceRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getRiderWisePerformanceRequired),
        switchMap((action) => {
            return this.dataService.effectGetAgentDeliveryAttempts(action.obj).pipe(
              switchMap((res) => {
                if(action.obj?.graphKey === GRAPH_KEYS.LMD.RIDER_WISE_PERFORMANCE_DASHBOARD){
                return of(dashboardAction.getLmdRiderWisePerformanceSuccess({graphKey:action.obj?.graphKey,graphSettings:action.graphSettings,  res: res,title:action.title }));
                } else if(action.obj?.graphKey === GRAPH_KEYS.LMD.RIDER_WISE_PERFORMANCE_ANALYTICS){
                  return of(dashboardAction.getLmdRAnalyticsRiderWisePerformanceSuccess({graphKey:action.obj?.graphKey,graphSettings:action.graphSettings, res: res,title:action.title, pillValue : action?.obj?.pillValue }));
                }else{
                  return of(dashboardAction.getRiderWisePerformanceSuccess({ res: res,title:action.title }));
                }
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    getO2dTatOfOrderRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getO2dTatOfOrderRequired),
        switchMap(({obj,title,graphSettings}) => {
            return this.dataService.effectGetTatData(obj).pipe(
              switchMap((res) => {
                const successActionData = {graphKey: obj?.graphKey,graphSettings,res,title,pillValue:obj?.pillValue } 
                switch (obj?.graphKey) {
                  case GRAPH_KEYS.LMD.TAT_OF_ORDER_DASHBOARD:
                    delete successActionData.pillValue
                    return of(dashboardAction.getLmdTatOfOrderSuccess(successActionData));  
                  case GRAPH_KEYS.LMD.TAT_OF_ORDER_ANALYTICS:
                    return of(dashboardAction.getLmdAnalyticsTatOfOrderSuccess(successActionData));
                  case GRAPH_KEYS.O2D.TAT_OF_ORDER_ANALYTICS:
                    return of(dashboardAction.getO2dOrderAnalyticsByTatOfOrderSuccess(successActionData));
                  case GRAPH_KEYS.O2D.TAT_OF_ORDER_DASHBOARD:
                    delete successActionData.pillValue
                    return of(dashboardAction.getO2dTatOfOrderSuccess(successActionData));
                  default:
                    return
                }
                
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    getDeliveryTemperatureRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getDeliveryTemperatureRequired),
        switchMap((action) => {
            return this.dataService.effectGetOrderTemperatureDetails(action.obj).pipe(
              switchMap((res) => {
                  return of(dashboardAction.getDeliveryTemperatureSuccess({graphSettings:action.graphSettings, res: res, title:action.title  }));
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    // getMmdOrderStatsDataRequired$ = createEffect(()=>
    // this.actions$.pipe(
    //     ofType(dashboardAction.getMmdOrderStatsDataRequired),
    //     mergeMap((action) => {
    //         if (action.providedOrderStatus !== 'total') {
    //           return this.dataService.effectGetAllAssignmentsCountForOrderStatsByGivenStatus(action.obj).pipe(
    //             switchMap((res: ICountStatus) => {
    //               return of(dashboardAction.getMmdOrderStatsDataSuccess({ res, providedOrderStatus: action.providedOrderStatus }));
    //             }),
    //             catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
    //           );
    //         }
    //          else {
    //           return this.dataService.effectGetTotalOrdersCount(action.obj).pipe(
    //             switchMap((res: ICountStatus) => {
    //               return of(dashboardAction.getMmdOrderStatsDataSuccess({ res, providedOrderStatus: 'total' }));
    //             }),
    //             catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
    //           );
    //         }
    //     })
    //   )
    // );
    getMmdDeliveryTemperatureRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getMmdDeliveryTemperatureRequired),
        switchMap(({obj,title,graphSettings}) => {
            return this.dataService.effectGetMmdOrderTemperatureDetails(obj).pipe(
              switchMap((res) => {
                if(obj?.graphKey === GRAPH_KEYS.LMD.DELIVERY_TEMPERATURE_DASHBOARD){
                  return of(dashboardAction.getLmdByTemperatureSuccess({graphKey:obj?.graphKey,graphSettings, res,title }));
                }else if(obj.graphKey ===  GRAPH_KEYS.MMD.DELIVERY_TEMPERATURE_ANALYTICS){
                  return of(dashboardAction.getMmdAnalyticsDeliveryTemperatureRequired({graphKey:obj?.graphKey,graphSettings, res,title }));
                }else if(obj.graphKey ===  GRAPH_KEYS.LMD.DELIVERY_TEMPERATURE_ANALYTICS){
                  return of(dashboardAction.getLmdRAnalyticsDeliveryTemperatureSuccess({graphKey:obj?.graphKey, res , title,pillValue : obj?.pillValue}));
                }else{
                  return [(dashboardAction.getMmdByTemperatureSuccess({ res: res,title:title })),(dashboardAction.changeShowLoaderValue({ show : false }))];
                }
              }),
              catchError((error) => {
                return [(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })),(dashboardAction.changeShowLoaderValue({ show : false }))]
              })
            );
        })
      )
    );
    // lmd dashboard state effects
    getLmdOrderStatsDataRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getLmdOrderStatsDataRequired),
        mergeMap((action) => {
            if (action.providedOrderStatus !== 'total') {
              return this.dataService.effectGetAllAssignmentsCountForOrderStatsByGivenStatus(action.obj).pipe(
                switchMap((res: ICountStatus) => {
                  return of(dashboardAction.getLmdOrderStatsDataSuccess({ res, providedOrderStatus: action.providedOrderStatus }));
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
            }
             else {
              return this.dataService.effectGetTotalOrdersCount(action.obj).pipe(
                switchMap((res: ICountStatus) => {
                  return of(dashboardAction.getLmdOrderStatsDataSuccess({ res, providedOrderStatus: 'total' }));
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
            }
        })
      )
    );
    getLmdDashboardByAgentAssignedStatus$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getLmdDashboardByAgentAssignedStatus),
        switchMap(({obj,graphSettings,title,idToRemove}) => {
            return this.dataService.effectGetAgentsByAssignedStatus(obj).pipe(
              switchMap((res:Record<string, Record<string, Assigned[]>>) => {
                if(obj?.graphKey === GRAPH_KEYS.LMD.ORDERS_BY_ASSIGNED_STATUS_ANALYTICS){
                  return of(dashboardAction.getCountByAgentAssignmentLmdAnalyticsSuccess({graphKey:obj?.graphKey,graphSettings, res: res , title,pillValue : obj?.pillValue,idToRemove}));
                }else if(obj?.graphKey === GRAPH_KEYS.LMD.ORDERS_BY_ASSIGNED_STATUS_DASHBOARD){
                  return of(dashboardAction.getLmdDashboardByAgentAssignedStatusSuccess({graphKey:obj?.graphKey,graphSettings, res: res , title}));
                }
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    getLmdTatOfOrderInPercentageRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getLmdTatOfOrderInPercentageRequired),
        switchMap((action) => {
            return this.dataService.effectGetTatData(action.obj).pipe(
              switchMap((res) => {
                  return of(dashboardAction.getLmdTatOfOrderInPercentageSuccess({graphSettings:action.graphSettings,  res: res, title:action.title  }));
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );
    //warehouse Dashboard 

    getAllDashboardCititesRequired$ = createEffect(() =>
  this.actions$.pipe(
    ofType(dashboardAction.getAllDashboardCititesRequired),
    switchMap((action) =>
      this.dataService.effectGetCountByTypes(action.zone).pipe(
        switchMap((res) => {
          if (res && res['count']) {
            return this.dataService.effectGetAllByType(action.zone, 0, res.count).pipe(
              switchMap((ress) => of(dashboardAction.getAllDashboardCitiesSuccess({ res: ress }))),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
          } else {
            // Handle the case when res or res['count'] is falsy
            return [];
          }
        }),
        catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
      )
    )
  ));
    getAllZoneForWarehouseRequired$ = createEffect(()=>
    this.actions$.pipe(
      ofType(dashboardAction.getAllZoneForWarehouseRequired),
      switchMap((action) => {
        return this.dataService.effectGetCountByTypes(action.zone).pipe(
          switchMap((res) => {
            if (res && res['count']) {
              return this.dataService.effectGetAllByType(action.zone, 0, res.count).pipe(
                switchMap((ress) => {
                  return of(dashboardAction.getWarehouseDashboardZoneListDataRequired({res : ress , obj : action.obj}))
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
            } else {
              // Handle the case when res or res['count'] is falsy
              return of(dashboardAction.getAllZoneForWarehouseSuccess({ res: [], ObjGrapg:[] }));
            }
          }),
          catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
        );
      })
    ));
    getWarehouseDashboardZoneListDataRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getWarehouseDashboardZoneListDataRequired),
        switchMap((action) => {
           let orderedRess = orderBy(action.res, ['name'], ['asc']);
                  let zoneEChartGraph = {}
                  let ObjGrapg;
                  // let orderSourceJson = {}
                  let order = {...orderSource, counts : {...orderSource.counts,chartData : {...orderSource.counts.chartData , series : [...orderSource.counts.chartData.series],xAxis : [...orderSource.counts.chartData.xAxis], legendToDisable : orderSource.counts.chartData.legendToDisable}},downloadPills: {...orderSource.downloadPills},graphTypePills : {...orderSource.graphTypePills},pills:{...orderSource.pills},countArray : [...orderSource.countArray]};
                     let orderSourceJson  = {...order}
                  const observables = orderedRess.map((ee) => {
                    zoneEChartGraph[ee['_id']] = {...orderSourceJson , counts : {...orderSourceJson.counts},downloadPills: {...orderSourceJson.downloadPills},graphTypePills : {...orderSourceJson.graphTypePills},pills:{...orderSourceJson.pills},countArray : [...orderSourceJson.countArray]};
                    return this.dataService.effectGetCarSensorData(action.obj, ee['_id']).pipe(
                      map((zone) => {
                        ObjGrapg = plotGraph(zone,ee,zoneEChartGraph);
                        return ObjGrapg;
                      })
                    );
                  });
                  return forkJoin(observables).pipe(
                    switchMap(() =>{
                      orderSourceJson = {...order};
                       return[(dashboardAction.getAllZoneForWarehouseSuccess({ res: orderedRess, ObjGrapg: ObjGrapg }))]
                  }
                    ));
        })
      ));
    getWarehouseDashboardAllMonitoredCityRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getWarehouseDashboardAllMonitoredCityRequired),
        switchMap((action) => {
           const modifiedLocationList = [];
           let locationList = [] ;
           let filterFields = {
            selectedCity: null,
            selectedLocation: null,
          };
            return this.dataService.effectGetAllMonitoredLocationForCity(action.cityId).pipe(
              switchMap((locations : ILocationList[]) => {
                if(locations && locations.length > 0) {
                  locations.forEach((value)=>{ 
                    modifiedLocationList.push(
                      value["assetDetails"]
                    );
                  });
                  locationList = [...modifiedLocationList];
                 filterFields.selectedLocation = locationList[0];
                    return[(dashboardAction.getwarehoueseDashboardLocationrequired({cityId : locationList[0]['_id'], zone: action.zone,obj:action.obj})),dashboardAction.getWarehouseDashboardAllMonitoredCitySuccess({ locationList : locationList })]
                } else {
                   return of(dashboardAction.getAllZoneForWarehouseSuccess({ res: [], ObjGrapg:[] }));
                }
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );

    getwarehoueseDashboardLocationrequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getwarehoueseDashboardLocationrequired),
        switchMap((action) => {
          return this.dataService.effectGetAssetLinkedByAssetType(action.cityId,action.zone ).pipe(
            switchMap((zones)=>{
           let modifiedZoneList = [];
             if(zones && zones.length > 0) {
               zones.forEach((value)=> { 
                 modifiedZoneList.push(value["assetDetails"]);
               });
               let listData = {...(orderBy(modifiedZoneList, ['name'], ['asc']))};
               return of(dashboardAction.getWarehouseDashboardZoneListDataRequired({res : listData , obj : action.obj}))
                // this.openChartModal = new Array(this.zoneList.length).fill(false)
             } else {
               return of(dashboardAction.getAllZoneForWarehouseSuccess({ res: [], ObjGrapg:[] }));
             }
            })
         )
        })
      )
    );
    //warehouse analytics
    getWarehouseAnalyticsGraphData$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getWarehouseAnalyticsGraphData),
        mergeMap((action) => {
          let observable;
          const newArr = []
           for(let i=0;i < action?.obj?.carsSelected.length;i++){
                   observable = this.dataService.effectGetCarSensorData(action?.obj,action?.obj?.carsSelected?.[i]?.['_id']).pipe(
                    map((data : []) => {
                      const carLiveData = {
                        temperature: {
                          data: [], 
                          label: [],
                          colour: 'red',
                          name: this.lang.getTranslation('app.core.custom-modal.car-live-data.temp-in-degree')
            
                        },
                        humidity: {
                            data: [],
                            label: [],
                            colour: 'red',
                            name: this.lang.getTranslation('app.core.custom-modal.car-live-data.humidity-in-percentage')
                          },
                    }
                    if (data && data.length > 0) {
                      let carGpsData : ISensorData[];
                      carGpsData = data;
                      for (let j = 0 ; j <= carGpsData.length - 1; j++) {
                        const value = carGpsData[j];
                        if (value?.temperature !== null && value?.updated) {
                          carLiveData.temperature.data.push( Math.round(value.temperature));
                          carLiveData.temperature.label.push(dateFormator(value.updated, 'date'));
                        }
                        if (value?.humidity !== null  && value?.updated) {
                          carLiveData.humidity.data.push(Math.round(value.humidity));
                          carLiveData.humidity.label.push(dateFormator(value.updated, 'date'));
                        }
          
                        if (j === carGpsData.length - 1) {
                          const allStats = keys(boxStatusGraph);
                          const chartsFinalData = {};
                          allStats.forEach((statsType)=> { 
                            const historicalData = carLiveData[statsType];
                            const chartSeries: EChartSeries = {
                              type: 'line',
                              stack: false,
                              smooth: false,
                              label: {normal: {show: false, position: 'top'}},
                              areaStyle: {opacity: 0.005},
                              itemStyle: {
                                colour: null
                              },
                              name: action?.obj?.carsSelected?.[i]?.['name'],
                              data: []
                            };
                            if (historicalData) {
                              chartSeries.name = action?.obj?.carsSelected?.[i]?.['name'];
                              chartSeries.data = historicalData.data;
                              chartSeries.itemStyle.colour = historicalData.name;
                            }
          
                            const chartSpecsData = {
                              title: boxStatusGraph[statsType].title,
                              type: 'line',
                              series: chartSeries,
                              legend:action?.obj?.carsSelected?.[i]?.['name'],
                              xAxis: historicalData.label
          
                            };
                            chartsFinalData[statsType] = chartSpecsData;
                          });
                        return chartsFinalData;
                        }
                      }
                  }else {
                    const allStats = keys(boxStatusGraph);
                    const chartsFinalData = {};
                    allStats.forEach((statsType)=>{ 
                      const historicalData = carLiveData[statsType];
                      const chartSeries: EChartSeries = {
                        type: 'line',
                        stack: false,
                        smooth: false,
                        label: {normal: {show: false, position: 'top'}},
                        areaStyle: {opacity: 0.005},
                        itemStyle: {
                          colour: null
                        },
                        name:action?.obj?.carsSelected?.[i]?.['name'],
                        data: []
                      };
                      if (historicalData) {
                        chartSeries.name = action?.obj?.carsSelected?.[i]?.['name'];
                        chartSeries.data = historicalData.data;
                        chartSeries.itemStyle.colour = historicalData.name;
                      }
                      const chartSpecsData = {
                        title: boxStatusGraph[statsType].title,
                        type: 'line',
                        series: chartSeries,
                        legend: action?.obj?.carsSelected?.[i]?.['name'],
                        xAxis: historicalData.label
        
                      };
                      chartsFinalData[statsType] = chartSpecsData;
                    });
                    return (chartsFinalData);
                  }
                    }),
                    catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
                  );
                  newArr.push(observable);
                }
            return forkJoin(newArr).pipe(
              switchMap((responses) => {
                const chartsFinalData = {};
                for (let i = 0; i < responses.length; i++) {
                  const carName = action?.obj?.carsSelected?.[i]?.['name'];
                  chartsFinalData[carName] = responses[i];
                }
                const chartData = plotSpecsGraph(responses);
                return [dashboardAction.getWarehouseAnalyticsGraphDataSuccess({ res: chartData })];
              })
            );
                 })
      )
    );
    
    //kiosk - dashboard effects
    getKioskTotalCountCardDataRequired$ = createEffect(()=>
    this.actions$.pipe(
        ofType(dashboardAction.getKioskTotalCountCardDataRequired),
        mergeMap((action) => {
            if (action.providedOrderStatus === 'totalAmount') {
              return this.dataService.effectGetSokTotalAmountByStatus(action.obj).pipe(
                switchMap((res) => {
                  return of(dashboardAction.getKioskTotalCountCardDataSuccess({ res, providedOrderStatus: action.providedOrderStatus }));
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
            }
             else {
              return this.dataService.effectGetSokOrderCountByStatus(action.obj).pipe(
                switchMap((res) => {
                  return of(dashboardAction.getKioskTotalCountCardDataSuccess({ res, providedOrderStatus: action.providedOrderStatus }));
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
            }
        })
      ));
      getKioskByTotalOrderRequired$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getKioskByTotalOrderRequired),
          mergeMap(({obj,title,graphSettings}) => {
              return this.dataService.effectGetSokOrdersByStatusForGraph(obj).pipe(
                mergeMap((res) => {

                  const actions:any[] = [dashboardAction.changeShowLoaderValue({ show : false })]
                  const successActionData = {graphKey: obj?.graphKey,graphSettings,res,title,pillValue:obj?.pillValue }

                  switch (obj?.graphKey) {
                    case GRAPH_KEYS.KIOSK.TOTAL_ORDERS_ANALYTICS:
                      actions.push(dashboardAction.getKioskAnalyticsTotalOrdersSuccess(successActionData));
                      break;    
                    case GRAPH_KEYS.KIOSK.PAID_ORDERS_ANALYTICS:
                      actions.push(dashboardAction.getKioskAnalyticsPaidOrderSuccess(successActionData));
                      break;
                    case GRAPH_KEYS.KIOSK.DISCARDED_ORDERS_ANALYTICS:
                      actions.push(dashboardAction.getKioskAnalyticsDiscardedOrderSuccess(successActionData));
                      break;
                    case GRAPH_KEYS.KIOSK.REFUNDED_ORDERS_ANALYTICS:
                      actions.push(dashboardAction.getKioskAnalyticsRefundedOrderSuccess(successActionData));
                      break;
                    case GRAPH_KEYS.KIOSK.TOTAL_ORDERS_DASHBOARD:
                      delete successActionData.pillValue
                      actions.push(dashboardAction.getKioskByTotalOrderSuccess(successActionData));
                      break;
                     default:
                      break;
                  }
                  

                  return actions
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
          })
        )
      );
      getKioskByPaidOrderRequired$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getKioskByPaidOrderRequired),
          switchMap((action) => {
              return this.dataService.effectGetSokOrdersByStatusForGraph(action.obj).pipe(
                mergeMap((res) => {
                    return [
                      dashboardAction.getKioskByPaidOrderSuccess({ res, title:action.title,graphKey:action?.obj?.graphKey,graphSettings:action.graphSettings }),
                      dashboardAction.changeShowLoaderValue({ show : false })
                    ]
                    
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
          })
        )
      );
      getKioskByDiscardedOrderRequired$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getKioskByDiscardedOrderRequired),
          switchMap((action) => {
              return this.dataService.effectGetSokOrdersByStatusForGraph(action.obj).pipe(
                mergeMap((res) => {
                    return [
                      dashboardAction.getKioskByDiscardedOrderSuccess({ res,graphKey:action?.obj?.graphKey,graphSettings:action.graphSettings , title:action.title }),
                      dashboardAction.changeShowLoaderValue({ show : false })
                    ];
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
          })
        )
      );
      getKioskByRefundedOrderRequired$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getKioskByRefundedOrderRequired),
          switchMap((action) => {
              return this.dataService.effectGetSokOrdersByStatusForGraph(action.obj).pipe(
                switchMap((res) => {
                    return [
                      dashboardAction.getKioskByRefundedOrderSuccess({ graphKey:action?.obj?.graphKey,graphSettings:action.graphSettings , res, title:action.title }),
                      dashboardAction.changeShowLoaderValue({ show : false })
                    ];
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
          })
        )
      );
      getKioskByTotalSalesRequired$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getKioskByTotalSalesRequired),
          switchMap((action) => {
              return this.dataService.effectGetSokTotalAmountByStatusForGraph(action.obj).pipe(
                switchMap((res) => {
                  if(action?.obj?.graphKey === GRAPH_KEYS.KIOSK.TOTAL_SALES_ANALYTICS){
                    return of(dashboardAction.getKioskAnalyticsByTotalSalesSuccess({graphKey:action?.obj?.graphKey,graphSettings:action.graphSettings,res, title:action.title,fieldName : action.fieldName,pillValue : action?.obj?.pillValue }));
                  }else if(action?.obj?.graphKey === GRAPH_KEYS.KIOSK.TOTAL_SALES_DASHBOARD){
                    return of(dashboardAction.getKioskByTotalSalesSuccess({graphKey:action?.obj?.graphKey,graphSettings:action.graphSettings, res, title:action.title,fieldName : action.fieldName }));
                  }
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
          })
        )
      );
      getKioskByTopProductsRequired$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getKioskByTopProductsRequired),
          switchMap((action) => {
              return this.dataService.getSokTopProducts(action.obj).pipe(
                switchMap((res) => {
                   if(action?.obj?.graphKey === GRAPH_KEYS.KIOSK.TOP_PRODUCTS_ANALYTICS){
                    return of(dashboardAction.getKioskAnalyticsByTopProductSuccess({ graphKey:action?.obj?.graphKey,graphSettings:action.graphSettings, res, title:action.title,pillValue :action?.obj?.pillValue }));
                  } else if(action?.obj?.graphKey === GRAPH_KEYS.KIOSK.TOP_PRODUCTS_DASHBOARD) {
                    return of(dashboardAction.getKioskByTopProductsSuccess({graphKey:action?.obj?.graphKey,graphSettings:action.graphSettings, res, title:action.title}));
                  }
                }),
                catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
              );
          })
        )
      );

      getKioskSalesByDineTypes$ = createEffect(()=>
        this.actions$.pipe(
            ofType(dashboardAction.getKioskSalesByDineTypes),
            switchMap((action) => {
                return this.dataService.effectGetKioskSalesByDineTypes(action.obj).pipe(
                  switchMap((res) => {
                      return of(dashboardAction.getKioskSalesByDineTypesSuccess({ res, title:action.title,pillValue :action?.obj?.pillValue,graphSettings:action.graphSettings,graphKey:action.obj.graphKey }));
                  }),
                  catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
                );
            })
          )
        );

      getLmdAnalyticsByAgentRequired$ = createEffect(()=>
       this.actions$.pipe(
        ofType(dashboardAction.getLmdAnalyticsByAgentRequired),
        mergeMap(({graphSettings, title, pillValue,obj}) => {
            return this.dataService.effectGetAnalyticsDataByGrpId(obj).pipe(
              switchMap((res) => {
                const successActionData = {graphKey: obj?.graphKey,graphSettings,res,title,pillValue}
                switch (obj?.graphKey) {
                  case GRAPH_KEYS.LMD.ORDER_BY_AGENT_ANALYTICS:
                    return [dashboardAction.getLmdRAnalyticsOrderByAgentSuccess(successActionData)];
                  case GRAPH_KEYS.O2D.DELIVERY_BY_LOGISTIC_PROVIDER_ANALYTICS:
                    return [dashboardAction.getO2dOrderAnalyticsByLogicticProviderSuccess(successActionData)];
                  case GRAPH_KEYS.O2D.ORDERS_BY_CITY_ANALYTICS:
                    return [dashboardAction.getO2dOrderAnalyticsByCitySuccess(successActionData)];
                  case GRAPH_KEYS.O2D.ORDERS_BY_TYPE_ANALYTICS:
                    return [dashboardAction.getO2dOrderAnalyticsByOrderTypeSuccess(successActionData)];
                  case GRAPH_KEYS.O2D.ORDERS_BY_LOCATION_ANALYTICS:
                    return [dashboardAction.getO2dOrderAnalyticsByLocationSuccess(successActionData)];
                  case GRAPH_KEYS.O2D.ORDER_VALUE_BY_LOGISTIC_PROVIDER_ANALYTICS:
                    return [dashboardAction.getO2dOrderAnalyticsByOrderValueByLPSuccess(successActionData)];
                  case GRAPH_KEYS.O2D.ORDER_VALUE_BY_CITY_ANALYTICS:
                    return [dashboardAction.getO2dOrderAnalyticsByOrderValueByCitySuccess(successActionData)];
                }
              }),
              catchError((error) => of(dashboardAction.O2dOrderStatsDataFailureMethod({ error: error })))
            );
        })
      )
    );

      getRoutePlansTempData$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getRoutePlansTempData),
          switchMap((action) => {
              return this.dataService.effectRoutePlansAndTempData(action.obj).pipe(
                switchMap((res) => {
                  return [dashboardAction.getRoutePlansTempDataSuccess({graphSettings:action.graphSettings, res: res,titles: action.titles }),dashboardAction.changeShowLoaderValue({ show : false })];
                }),
                catchError((error) => of(dashboardAction.getRoutePlansTempDataFailure({ error: error })))
              );
          })
        )
      );
      getRoutePlansAssignedStatusCountData$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getRoutePlansAssignedStatusCountData),
          switchMap((action) => {
              return this.assetsService.effectGetAllRoutePlan('mmd',true,null,null,null,action.obj.orderPickUpCityId,action.obj.orderPickUpStoreId).pipe(
                switchMap((res) => {
                  return [dashboardAction.getRoutePlansAssignedStatusData({graphSettings:action.graphSettings, obj: action.obj,title: action.title,limit:res?.count || 0 })];
                }),
                catchError((error) => of(dashboardAction.getRoutePlansAssignedStatusDataFailure({ error: error })))
              );
          })
        )
      );

      getRoutePlansAssignedStatusData$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getRoutePlansAssignedStatusData),
          switchMap((action) => {
              return this.assetsService.effectGetAllRoutePlan('mmd',false,null,action?.limit,null,action.obj.orderPickUpCityId,action.obj.orderPickUpStoreId).pipe(
                switchMap((res) => {
                  return [dashboardAction.getRoutePlansAssignedStatusDataSuccess({graphSettings:action.graphSettings, res: res,title: action.title }),dashboardAction.changeShowLoaderValue({ show : false })];
                }),
                catchError((error) => of(dashboardAction.getRoutePlansAssignedStatusDataFailure({ error: error })))
              );
          })
        )
      );

resetGraphTypePillValueRequired$ = createEffect(() =>
  this.actions$.pipe(
    ofType(resetGraphTypePillValueRequired),
    switchMap((action) =>
      of(action).pipe(
        withLatestFrom(this.store.select(action.selector)),
        switchMap(([action, stateData]) => {
           const obj = {...stateData[action.title]}
           const object2 = {
          ...obj,
          counts: {
            ...obj?.counts,
            chartData: {
              ...obj?.counts?.chartData,
              legend : obj?.counts?.chartData?.legend?.map(item =>
                item === 'pending' ? 'progress' : item
              ),
              series: obj?.counts?.chartData?.series?.map((seriesItem) => ({
                ...seriesItem,
                type: action?.pillValue,
                stack:action?.isStacked,
              })),
              xAxis : obj?.counts?.chartData?.xAxis?.map(item =>
                item === 'pending' ? 'progress' : item
              ),
            type : action?.pillValue,
            },
          },
          graphType : action?.pillValue,
          isStacked:action?.isStacked,
          graphTypePills:{
            ...obj.graphTypePills,
            arrayOfGraphTypePills : obj.graphTypePills.arrayOfGraphTypePills.map(graph=>{
              graph = {
                ...graph,
                isCurrentType:graph.id===action.pillValue
              }
               if(graph.id===action?.pillValue){
                graph['isStacked'] = action?.isStacked
               }
               return graph
            })
          }
           };

           if(action?.pillValue === 'pie' && object2?.counts?.chartData){
             let newPieData: any = [];
             if (object2?.counts?.chartData.series && object2?.counts?.chartData.series.length > 0) {
              (object2?.counts?.chartData.series).forEach((value)=> {
                let dataArray = [];
                if(value?.data?.length && value?.data[0]?.value){
                 value?.data?.forEach((d)=> {
                      dataArray.push(d?.value)
                 });
                 let dataObj = {
                  value: null, name: null
                };
                let sum = _.sum(dataArray); 
                dataObj.value = sum;
                dataObj.name = value.name;
                newPieData.push({...dataObj});
                } else if (value?.data) {
                  let dataObj = {
                    value: null, name: null
                  };
                  let sum = _.sum(value.data); 
                  dataObj.value = sum;
                  dataObj.name = value.name;
                  newPieData.push({...dataObj});
                }
            });
            let pieDataObj = {
              data: [...newPieData]
            }
            object2.counts.chartData['pieChartData'] = pieDataObj;
            object2.counts.graphTypeChangedRandomNumber = Math.random();
          }
           }
          return [dashboardAction.resetGraphTypePillValueSuccess({ title: action?.title, obj: object2,moduleName : action?.moduleName })]
        })
      )
    )
  )
);
   getMmdDeliveredGroupAnalytics$ = createEffect(()=>
      this.actions$.pipe(
          ofType(dashboardAction.getMmdDeliveredGroupAnalytics),
          switchMap((action) => {
              return this.dataService.effectGetMmdDeliveredGroupAnalytics(action.obj).pipe(
                withLatestFrom(this.store.select(vehiclesSelector), this.store.select(mmdDeliveredAnalytics)),
                switchMap((observablesData) => {  // returns both effects and selector data as array of values
                  const vehicles = observablesData?.[1];
                  let data: IMmdDeliveredGroupAnalyticsData[] = observablesData?.[0][0].data;
                  if(data.length>0 && data[0]?.groupedBy === "routePlanIds"){
                    data = data.map((d: IMmdDeliveredGroupAnalyticsData) => {
                      d = { ...d, label: d?.routePlanNames.toString() }
                      return d;
                    })
                  } else {
                    data = data.map((d: IMmdDeliveredGroupAnalyticsData) => {
                      const label = findIndividualNameFormArr(vehicles, d._id, 'name')
                      if(label){
                        d = {...d, label: label}
                      }
                      return d;
                    })
                  }
                  
                  const dAnalyticsData: IMmdDeliveredAnalyticsData = {
                    tableData: data,
                    pagination: observablesData?.[0][0].pagination,
                    deliveryCount: observablesData?.[0]?.[0]?.deliveryCount ? observablesData?.[0]?.[0]?.deliveryCount : observablesData?.[2]?.deliveryCount,
                    tripCount: observablesData?.[0]?.[0]?.tripCount ? observablesData?.[0]?.[0]?.tripCount : observablesData?.[2]?.tripCount,
                  }
                  return [(dashboardAction.getMmdDeliveredGroupAnalyticsSucces({ res: dAnalyticsData })), (dashboardAction.changeShowLoaderValue({ show : false }))];
                })
              );
          })
        )
      );

        getO2dOverviewAnalytics$ = createEffect(()=>
          this.actions$.pipe(
              ofType(dashboardAction.getO2dOverviewAnalytics),
              switchMap((action) => {
                  return this.dataService.effectGetO2dOverviewAnalytics(action.obj).pipe(
                    switchMap((res: IO2DOverviewAnalytics) => {
                      return [dashboardAction.getO2dOverviewAnalyticsSucces({ res: res }),
                        (dashboardAction.getO2dOverviewAnalyticsDataWithSkipLimit({skip:0, limit:20, sortOrder: action.sortOrder, sortField: action.sortField, searchedText: ''})),
                        (dashboardAction.changeShowLoaderValue({ show : false }))];
                    }),
                  );
              })
            )
          );

          getTemperatureAndHumidityGraph$ = createEffect(()=>
            this.actions$.pipe(
                ofType(dashboardAction.getTemperatureAndHumidityGraph),
                switchMap((action) => {
                    return this.trackingService.getMultipleSensorData(action.startTime, action.endTime, action.selectedVIds,action.series).pipe(
                      switchMap((res) => {
                          return [
                            dashboardAction.getTemperatureAndHumidityGraphSuccess({res,dateDisplayFormate:action.dateDisplayFormate,selectedVehicles:action.selectedVehicles,selectedVIds:action.selectedVIds})
                          ];
                      })
                    );
                })
              )
            );


            getDMSVehicleDistanceTravelledGraph$ = createEffect(()=>
              this.actions$.pipe(
                  ofType(dashboardAction.getDMSVehicleDistanceTravelledGraph),
                  switchMap((action) => {
                      return this.trackingService.getAllCarsTotalDistanceTravelledById(action.startTime, action.endTime, action.carsSelected).pipe(
                        switchMap((distanceChartData) => {
                            return [
                              dashboardAction.getDMSVehicleDistanceTravelledGraphSuccess({distanceChartData}),
                              dashboardAction.getDMSDistanceTravelledByTimeAndSeriesType({
                                series:action.series,
                                carsSelected:action.carsSelected,
                                startTime:action.startTime, endTime:action.endTime,
                                title:action.title,graphType:action.graphType
                              })
                            ];
                        })
                      );
                  })
                )
              );

              getDMSDistanceTravelledByTimeAndSeriesType$ = createEffect(()=>
                this.actions$.pipe(
                    ofType(dashboardAction.getDMSDistanceTravelledByTimeAndSeriesType),
                    switchMap((action) => {
                        return this.trackingService.getAllCarDistanceMovedData(action.startTime, action.endTime, action.carsSelected,action.series).pipe(
                          switchMap((distanceChartData) => {
                              return [
                                dashboardAction.getDMSDistanceTravelledByTimeAndSeriesTypeSuccess({distanceChartData,title:action.title,series:action.series,graphType:action.graphType})
                              ];
                          })
                        );
                    })
                  )
                );

                getLMDTemperatureAndHumidityGraph$ = createEffect(()=>
                  this.actions$.pipe(
                      ofType(dashboardAction.getLMDTemperatureAndHumidityGraph),
                      switchMap((action) => {
                          return this.trackingService.getAllCarsSensorData(action.startTime, action.endTime, action.carsSelected,action.series).pipe(
                            switchMap((res) => {
                                return [
                                  dashboardAction.getLMDTemperatureAndHumidityGraphSuccess({ res,
                                    dateDisplayFormate: action.dateDisplayFormate,
                                  })
                                ];
                            })
                          );
                      })
                    )
                  );

                  getDMSLogisticsCostAnalytics$ = createEffect(()=>
                    this.actions$.pipe(
                        ofType(dashboardAction.getDMSLogisticsCostAnalytics),
                        switchMap((action) => {
                            return this.dataService.effectGetDMSLogisticsCostAnalytics(action.obj).pipe(
                              switchMap((res: DMSLogisticsCostAnalytics) => {
                                return [dashboardAction.getDMSLogisticsCostAnalyticsSucces({ res: res }),
                                  (dashboardAction.changeShowLoaderValue({ show : false }))];
                              }),
                            );
                        })
                      )
                    );  

                    getDMSLogisticsCostCardResponse$ = createEffect(()=>
                      this.actions$.pipe(
                        ofType(dashboardAction.getDMSLogisticsCostCardResponse),
                        switchMap((action) => {
                          return this.dataService.effectGetDMSLogisticsCostCardAnalytics(action.obj).pipe(
                            switchMap((res: IDmsLogisticsCostCardResponse) => {
                              return [dashboardAction.getDMSLogisticsCostCardResponseSuccess({ res: res }),
                                (dashboardAction.changeShowLoaderValue({ show : false }))];
                            }),
                          );
                        })
                      )
                    );  
 }



 