.map-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: inherit;
}

.cursor-crosshair {
  cursor: crosshair !important;
}
.leaflet-tooltip-own{
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    width: 300px;
    height: 200px;
    p{
        margin: 0 !important;
    }
    .tooltip-content{
        padding: 16px;
        width: 100%;
        padding-top: 5px;
        .tooltip-header{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            height: 65px;
            width: 100%;
            border-bottom: 1px solid rgb(208, 208, 208);
            div{
              width: 70%;
            }
            h6{
                font-weight: 600;
                margin: 2px 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            p{
                color: rgb(121, 121, 121);
            }
            .status{
                width: 30%;
                padding: 5px 15px;
                font-size: 0.8rem;
                border-radius: 5px;
                font-weight: 600;
            }
            .online{
                background-color: rgb(0, 203, 0);
                color: rgb(255, 255, 255);
            }
            .offline{
                background-color: rgb(204, 0, 0);
                color: white;
            }
        }
        .tooltip-body{
            height: 90px;
            padding: 15px 0;
            display: flex;
            flex-wrap: wrap;
            .sensor-pill{
                padding: 5px 10px;
                font-weight: 500;
                color: var(--primary-color);
                background-color: rgb(246, 246, 246);
                border-radius: 5px;
                width: max-content;
                height: max-content;
                margin-right: 5px;
                cursor: pointer;
            }
        }
    }
}
.recenter-btn {
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: var(--surface-0);
  z-index: 450;
  box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: max-content;

  .toolbar-icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #ffffff;
  }

  .active-setting {
    background-color: var(--primary-color);
    color: #ffffff;
  }
}

.leaflet-tooltip-own {
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  background-color: var(--surface-50);
  width: 310px;
  height: 200px;

  p {
    margin: 0 !important;
  }

  .tooltip-content {
    padding: 10px;
    width: 100%;

// .legend-container{
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     z-index: 401;
//     background-color: #fff;
//     height: fit-content;
//     width: fit-content;

//     ul {
//       margin-bottom: 0;
//       padding: 5px;
    .tooltip-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      height: 65px;
      width: 100%;
      border-bottom: 1px solid rgb(208, 208, 208);

      div {
        width: 70%;
      }

      h6 {
        font-weight: 600;
        margin: 2px 0;
        text-overflow: ellipsis;
        color: var(--text-color);
        white-space: nowrap;
        overflow: hidden;
      }

      p {
        color: var(--text-color-secondary);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .status {
        width: 30%;
        padding: 5px 15px;
        font-size: 0.8rem;
        border-radius: 5px;
        font-weight: 600;
      }

      .online {
        background-color: rgb(0, 203, 0);
        color: rgb(255, 255, 255);
      }

      .offline {
        background-color: rgb(204, 0, 0);
        color: white;
      }
    }

    .tooltip-body {
      height: 90px;
      padding: 15px 0;
      display: flex;
      flex-wrap: wrap;

      .sensor-pill {
        padding: 5px 10px;
        font-weight: 500;
        color: var(--primary-color);
        background-color: rgb(246, 246, 246);
        border-radius: 5px;
        width: max-content;
        height: max-content;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
}

.tooltip-content.delivery-centers {
  padding: 10px;
  width: 100%;

  .tooltip-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 65px;
    width: 100%;
    border-bottom: 1px solid rgb(208, 208, 208);

    div {
      width: 70%;
    }

    h6 {
      font-weight: 600;
      margin: 2px 0;
      max-width: 125px;
      text-overflow: ellipsis;
      color: var(--text-color);
      white-space: nowrap;
      overflow: hidden;
    }

    p {
      color: var(--text-color-secondary);
      margin: 0;
    }

    .status {
      min-width: 32%;
      font-size: 0.78rem;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      border-radius: 5px;
      font-weight: 600;
      color: white;
    }
  }

  .tooltip-body {
    height: 90px;
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;

    .sensor-pill {
      padding: 5px 10px;
      font-weight: 500;
      color: var(--primary-color);
      background-color: rgb(246, 246, 246);
      border-radius: 5px;
      width: max-content;
      height: max-content;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}

.legend-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background-color: #fff;
  height: fit-content;
  width: fit-content;

  ul {
    margin-bottom: 0;
    padding: 5px;
    display: flex;
    flex-direction: column;
  }

  .legend-list-container {
    display: inline-block;
    margin-right: 7px;

    .legend {
      display: inline-block;
      width: 25px;
      height: 4px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }
}

.leaflet-tooltip-geofence {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leaflet-fade-anim .leaflet-popup{
    height: fit-content;
    // .leaflet-popup-content{
    //     margin: 13px 0px 0px 0px !important;
    //     width: 100% !important;
    // }
}