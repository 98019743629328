import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { UserService } from "./user.service";
import { UserActions } from ".";

@Injectable()
export class UserEffects {

  constructor(private actions$: Actions, private userService: UserService) { }

  registerFirebaseToken$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.registerFirebaseToken),
    switchMap((_) => from(this.userService.effectUpdateFirebaseToken().pipe(
      map((data) => {
        document.cookie = "firebaseDocId=" + (data['_id'] || null);
        return UserActions.success({ message: 'ok' })
      }),
      catchError((error) => of(UserActions.failure({ error: error })))
    ))
    )
  ));

  deleteFirebaseToken$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.deleteFirebaseToken),
    switchMap((_) => from(this.userService.effectDeleteFirebaseToken().pipe(
      map((_) => UserActions.success({ message: 'ok' })),
      catchError((error) => of(UserActions.failure({ error: error })))
    ))
    )
  ));

  getUserMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserMap),
      switchMap(() => {
        return from(this.userService.effectMapForSelectedUser()).pipe(
          map((res) => UserActions.getUserMapSuccess({ userMap: res })),
          catchError((error) => of(UserActions.failure({ error: error })))
        );
      })
    )
  );

  getAddressDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getAddressDetails),
      switchMap((action) => {
        return this.userService
          .reverseGeoCoding({ lat: action.lat, lng: action.lng })
          .pipe(
            map((res) => {
              const results = res.results;
              const address_components = results[0].address_components;
              const addressDetails = {
                state: "",
                pincode: null,
                city: "",
                address:results[0].formatted_address || '',
                fieldKey:action.fieldKey,
                avoidAddressUpdate:action.avoidAddressUpdate
              };

              for (let component of address_components) {
                if (component.types.includes("locality")) {
                  addressDetails.city = component.long_name;
                } else if (
                  component.types.includes("administrative_area_level_1")
                ) {
                  addressDetails.state = component.long_name;
                } else if (component.types.includes("postal_code")) {
                  addressDetails.pincode = Number(component.long_name);
                }
              }

              return UserActions.getAddressDetailsSuccess({ addressDetails });
            }),
            catchError((error) => of(UserActions.failure({ error: error })))
          );
      })
    )
  );
}
