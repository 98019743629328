import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { BreadcrumbService } from '../breadcrumb/app.breadcrumb.service';
import { SearchService } from '../../../services/search.service';
import {Store} from '@ngrx/store';
import { KioskSelectors } from '../../../state/Kiosk';
import { UserProfile } from '../../../app/settings/user-profile/user-profile-type';
import { NotificationService } from '../../../state/Notification/notification.service';
import { MessageService } from 'primeng/api';
import {DateFormatterService} from '../../../services/dateformat';
import { currentActiveId, selectIsSidebarFullScreen } from '../../../state/Sidebar/sidebar.selector';
import { SplitScreenService } from '../../../services/split-screen.service';
import { marked } from 'marked';
import { HelpConfigService } from '../../../app/settings/services/help-config.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
  styleUrls: ['./app.breadcrumb.component.scss'],
})
export class AppBreadcrumbComponent implements OnInit, OnChanges{
  @Input() resetSearchTextToHeader: any;
  public showSearchBox$ = this.searchService.isSearchBarNeeded;
  name$ = this.store.select(KioskSelectors.getSelectedBranchName);
  items$ = this.breadcrumbService.items;
  uniqueId$ = this.breadcrumbService.uniqueId

  constructor(private breadcrumbService: BreadcrumbService, private searchService: SearchService,
    private store: Store,
    private splitScreenService: SplitScreenService,
    private messageService: MessageService, public dateFormatter: DateFormatterService, private helpConfigService: HelpConfigService,
    private notificationService: NotificationService,) {
  }
  sidebarId = 'BREADCRUMB_SIDEBAR';
  currentActiveSidebarId$;
  sidebarIsFullScreen$ = this.store.select(selectIsSidebarFullScreen);
  isSidebarClosed: boolean;
  isCurrentIdActive = false;
  visible: boolean = false;
  showLoader: boolean = false;
  submitDisable: boolean = false;
  supportTypes: any[] | undefined;
  @Input() userDetails: UserProfile;
  @Input() triggerSupportPopup: number;

   support :{
    type: any | undefined;
    content: string | undefined;
    name: string;
    email: string;
    phone: string;
    subject: string;
    url: string;
   } = {
    type : null,
    content: null,
    name: null,
    email: null,
    phone: null,
    subject:null,
    url:null,
   }


  localPageName: string
  markdownContent = `#`;
  isDisplayHelpDeskIcon:boolean

  ngOnInit() {
    this.uniqueId$.subscribe((res: { [x: string]: string; }) => {
      console.log(res)
      this.getHelpDeskConfiguration(res?.['moduleType'], res?.['id'])
    })
    this.currentActiveSidebarId$ = this.store.select(currentActiveId).subscribe(res => {
      if (res === this.sidebarId)
        this.isCurrentIdActive = true;
      else
        this.isCurrentIdActive = false;
    });


    this.supportTypes = [
      {
          oname: 'Feedback',
          code: 'feedback',
      },
      {
          name: 'BUG',
          code: 'bug',
          options: [
              {
                  oname: 'High',
                  code: 'highBug',
              },
              {
                  oname: 'Medium',
                  code: 'mediumBug',
              },
              {
                  oname: 'Low',
                  code: 'lowBug',
              }
          ]
      },
    ];
    this.support.type = this.supportTypes[0];
  }

  getHelpDeskConfiguration(moduleType: string, page: string) {
    this.helpConfigService.getHelpConfigOnModuleLevelWithPageName(moduleType, page).subscribe((res: any[]) => {
      if (res.length > 0) {
        this.markdownContent = res[0]['content'];
        this.isDisplayHelpDeskIcon = true
      } else {
        this.markdownContent = `# ${page}`;
        this.isDisplayHelpDeskIcon = false
      }

    })
  }

  showHelpPanel() {

  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if(propName==='userDetails'){
        if (change.currentValue !== change.previousValue) {
            this.setUSerDetails();
        }
      }
      if(propName==='triggerSupportPopup'){
        if (change.currentValue !== change.previousValue) {
          this.showDialog();
        }
      }
    }
  }

  getHtmlContent() {
    return marked(this.markdownContent);
  }

  openSidebar() {

    this.splitScreenService.addOrRemoveSplitScreen('addSplitScreen', '#BREADCRUMB_SIDEBAR');

  }
  closeBtnClicked() {
    if (!this.isSidebarClosed) {
      this.isSidebarClosed = true;
    }
    this.splitScreenService.closeCreateModal();
  }
  
    showDialog() {
        this.visible = true;
        this.resetSupport();
    }

    submitFeedback(){
        this.showLoader = true;
        this.submitDisable = true;
        const support  = {...this.support, type: this.support.type.code, phone: this.support?.phone?.toString()}
        this.notificationService.customerSupport(support).subscribe(res =>{
            this.visible = false;
            this.showLoader = false;
            this.submitDisable = false;
            this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Succes', detail: res?.['msg'] });
        },(err) =>{
            this.showLoader = false;
            this.submitDisable = false;
            this.messageService.add({ key: 'global-notification', severity: 'error', summary: 'Error', detail: err?.error?.msg })
        })
    }

    setSubject(){
        this.support.subject = this.support?.type?.oname + ' : ' + this.dateFormatter.convertDateToSelectedTimezone(new Date(), 'DD/MM/YY, h:mm a');
    }

    setUSerDetails(){
      this.support.name = this.userDetails?.firstName + ' ' + this.userDetails?.lastName;
        this.support.email = this.userDetails?.email;
        this.support.phone = this.userDetails?.mobileNo ? this.userDetails?.mobileNo : null;
    }

    resetSupport(){
        let domain = ''
        domain = window?.location?.href;
      this.support.type = this.supportTypes?.[0];
        this.support.url = domain;
        this.support.content = null;
        this.support.subject = null;
        this.setUSerDetails();
        this.setSubject();
    }
}